// Copyright 2021-2025 - Hewlett Packard Enterprise Company
/* eslint-disable react-refresh/only-export-components */
import axios from 'axios';
import {
  isSelected,
  getIdFromUri,
  getAllPages,
  parseIloGeneration,
} from '../utils/apiHelpers';
import { convertVolumeSizeGBToGiB } from '../utils/serverSettingHelpers';
import {
  FIRMWARE_UPDATE_TEMPLATE_URI,
  OV_FIRMWARE_UPDATE_TEMPLATE_URI,
  FIRMWARE_BATCH_UPDATE_TEMPLATE_URI,
  APPLIANCE_BATCH_UPDATE_TEMPLATE_URI,
  SCHEDULE_JOB_URL,
  GEN_10,
  GEN_11,
  GEN_12,
  SERVERS_CATEGORY_FILTER,
  SERVER_INVENTORY_TEMPLATE_URI,
  UPDATE_ILO_FIRMWARE_SERVER_TEMPLATE_URI,
  JOB_STATE,
  SETTING_CATEGORY_FIRMWARE,
  SERVER_ILO_FW_UPDATE,
  SERVER_INVENTORY_TEMPLATE_JSON,
  NETWORK_CONNECTIVITY_TEMPLATE_URI,
  OV_SERVER_TEMPLATE_UPDATE_URI,
  EXTERNAL_STORAGE_COMPLIANCE_TEMPLATE_URI,
  SERVER_VOLUME_DETAILS_TEMPALTE_URI,
  REFRESH_APPLIANCE_SETTINGS_TEMPLATE_URI,
  APPLY_OV_APPLIANCE_SETTINGS_TEMPLATE_URI,
  DIRECT_CONNECT,
  COPY_OV_SERVER_TEMPLATE_URI,
  OV_APPLIANCE_TYPE,
  SETTING_CATEGORY_STORAGE,
  TARGET_DEVICE_SECURE_GATEWAY,
  TARGET_DEVICE_ILO,
  SECURE_GATEWAY,
  SERVER_FW_UPDATE,
  DEFAULT_PROJECTION_DAYS,
} from '../constants';
import { OPENID_CONFIG_URL } from '../constants/authConst';

const COM_API_ROOT_URL = window.indigo.REACT_APP_COM_API_ROOT_URL;

const DOORWAY_URL = `${COM_API_ROOT_URL}/ui-doorway`;
const COBALT_URL = `${COM_API_ROOT_URL}/api`;
const COMPUTE_OPS_URL = `${COM_API_ROOT_URL}/compute-ops`;
const COMPUTE_OPS_MGMT_URL = `${COM_API_ROOT_URL}/compute-ops-mgmt`;
const CHAT_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/chat`;
const CHAT_FEEDBACK_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/chat/feedback`;

const ILOS_URL = `${COBALT_URL}/compute/v1/servers`;
const JOBS_URL = `${COBALT_URL}/compute/v1/jobs`;
const BIOS_SETTINGS_URL = `${COBALT_URL}/compute/v1/device-settings`;
const FIRMWARE_BUNDLES_URL = `${COBALT_URL}/compute/v1/firmware-bundles`;
const FIRMWARE_BUNDLES_URL_V1 = `${COMPUTE_OPS_MGMT_URL}/v1/firmware-bundles`;
const FW_PREFLIGHT_URL = `${COBALT_URL}/compute/v1/analyze-firmware-update`;
const OPS_MGMT_OS_PREFLIGHT_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta2/servers`;

const COMPUTE_OPS_MGMT_SCHEDULE_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta2/schedules`;
const COMPUTE_OPS_MGMT_JOBS_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta2/jobs`;
const COMPUTE_OPS_MGMT_REPORTS_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta2/reports`;

const OPS_MGMT_SAVED_FILTERS_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/filters`;
const OPS_MGMT_EMAIL_NOTIFICATION_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/user-preferences`;
const OPS_MGMT_EMAIL_NOTIFICATION_URL_V1 = `${COMPUTE_OPS_MGMT_URL}/v1/user-preferences`;
const OPS_MGMT_SERVER_NOTIFICATIONS_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/server-notifications`;
const METRICS_DATA_URL_V1 = `${COMPUTE_OPS_MGMT_URL}/v1/metrics-configurations`;
const OPS_MGMT_METRICS_DATA_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/metrics-configurations`;
const SERVERS_POST_PATCH_DEL_URL = `${COMPUTE_OPS_URL}/v1beta2/servers`;
const OPS_MGMT_SERVERS_POST_PATCH_DEL_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta2/servers`;
const OPS_MGMT_SERVERS_V1_POST_PATCH_DEL_URL = `${COMPUTE_OPS_MGMT_URL}/v1/servers`;
const OPS_MGMT_GROUPS_V1B2_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta2/groups`;
const OPS_MGMT_GROUPS_V1_URL = `${COM_API_ROOT_URL}/compute-ops-mgmt/v1/groups`;
const UTILIZATION_OVER_TIME = `${COMPUTE_OPS_MGMT_URL}/v1beta1/utilization-over-time`;
// eslint-disable-next-line max-len
const UTILIZATION_AVERAGE_OVER_TIME = `${COMPUTE_OPS_MGMT_URL}/v1beta1/utilization-averages`;
const ENERGY_OVER_TIME = `${COMPUTE_OPS_MGMT_URL}/v1beta1/energy-over-time`;
const ENERGY_BY_ENTITY_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/energy-by-entity`;
const UTILIZATION_BY_ENTITY_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/utilization-by-entity`;
const APPROVAL_POLICY_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/approval-policies`;
const APPROVAL_REQUESTS_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/approval-requests`;

// Doorway API endpoints
const SERVERS_DATA_URL = `${DOORWAY_URL}/compute/v2/servers/data`;
const FIRMWARE_UPGRADES_URL = `${DOORWAY_URL}/compute/v2/firmware-upgrades`;
const METRICS_DATA_RANGE = `${DOORWAY_URL}/compute/v1/metrics-data-range`;
const SERVERS_URL = `${DOORWAY_URL}/compute/v2/servers`;
const SERVERS_COUNTS_URL = `${DOORWAY_URL}/compute/v2/servers/counts`;
const SERVERS_OVERVIEW_URL = `${DOORWAY_URL}/compute/v1/servers-overview`;

const CUSTOMERS_URL = `${DOORWAY_URL}/compute/v1/accounts`;
const UI_DOORWAY_ACTIVITIES_URL = `${DOORWAY_URL}/compute/v1/activities`;
const SBAC_CHECK_URL = `${DOORWAY_URL}/compute/v1/user-accessible-resources`;
const USER_PERMISSIONS_URL = `${DOORWAY_URL}/compute/v1/user-permissions`;
const SERVER_INSIGHTS_URL = `${DOORWAY_URL}/compute/v1/insights`;
const APPROVER_LIST_URL = `${DOORWAY_URL}/compute/v1/approver-list`;
const ENERGY_DATA_EXPORT_URL = `${DOORWAY_URL}/compute/v1/energy-data-export`;
const UTILIZATION_DATA_EXPORT_URL = `${DOORWAY_URL}/compute/v1/utilization-data-export`;

// OV API endpoints
const OPS_MGMT_OV_APPLIANCES_URI =
  '/compute-ops-mgmt/v1beta1/oneview-appliances';
const OPS_MGMT_OV_APPLIANCES_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/oneview-appliances`;
const OPS_MGMT_OV_RESOURCES_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/oneview-resources`;
const OPS_MGMT_OV_SERVER_HW_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/oneview-server-hardware`;
const OPS_MGMT_OV_SERVER_PROFILE_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/oneview-server-profiles`;
// eslint-disable-next-line max-len
const OPS_MGMT_APPLIANCE_SETTINGS_TEMPLATE_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/oneview-settings-templates`;
const APPLIANCE_SETTINGS_COUNTS_URL = `${DOORWAY_URL}/compute/v1/oneview-settings-templates/counts`;
const OV_APPLIANCE_SETTINGS_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/oneview-settings`;
const OPS_MGMT_OV_SERVER_TEMPLATES_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/oneview-server-templates`;
const APPLIANCE_FIRMWARE_BUNDLES_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/appliance-firmware-bundles`;
const APPLIANCE_FIRMWARE_BUNDLES_URL_V1 = `${COMPUTE_OPS_MGMT_URL}/v1/appliance-firmware-bundles`;
const APPLIANCE_FW_BUNDLE_UIDOORWAY_URL = `${DOORWAY_URL}/compute/v1/appliance-firmware-bundles`;
const APPLIANCES_UIDOORWAY_URL = `${DOORWAY_URL}/compute/v2/appliances`;
const APPLIANCES_COUNTS_UIDOORWAY_URL = `${APPLIANCES_UIDOORWAY_URL}/counts`;

const OPS_MGMT_OV_HARDWARE_COUNTS_URL = `${OPS_MGMT_OV_RESOURCES_URL}/counts`;
const OPS_MGMT_OV_SERVER_HW_COUNTS_URL = `${OPS_MGMT_OV_SERVER_HW_URL}/counts`;
const OPS_MGMT_OV_PROFILE_COUNTS_URL = `${OPS_MGMT_OV_RESOURCES_URL}/counts`;
const OPS_MGMT_OV_SERVER_PROFILE_COUNTS_URL = `${OPS_MGMT_OV_SERVER_PROFILE_URL}/counts`;

const NUM_ITEMS_IN_ACTIVITY_CARD = 5;
const MAX_DOORWAY_COUNT = 800;
const MAX_COBALT_COUNT = 100;
const MAX_IRIS_COUNT = 100;
const MAX_IRIS_INVENTORY_COUNT = 50;
const MAX_APPLIANCE_FW_BUNDLE_COUNT = 100;
const MAX_FILTER_GET_ALL_SERVERS = 10;
const MAX_FILTER_GET_ALL_APPLIANCES = 100;
const MAX_FILTER_LENGTH_GET_ALL_SERVERS = 1995;
const MAX_GET_SERVERS_ANALYTICS_COUNT = 100;

const GROUPS_URL_V2 = `${DOORWAY_URL}/compute/v2/groups`;
const GROUP_COUNTS_URL = `${DOORWAY_URL}/compute/v2/groups/counts`;
const OPS_MGMT_GROUP_POST_PATCH_DEL_V1_URL = `${COMPUTE_OPS_MGMT_URL}/v1/groups`;

const SERVER_SETTINGS_URL = `${DOORWAY_URL}/compute/v2/server-settings`;
const OPS_MGMT_SRVR_SETTINGS_POST_PATCH_DEL_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/server-settings`;
const OPS_MGMT_SETTINGS_POST_PATCH_DEL_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/settings`;
const OPS_MGMT_SETTINGS_V1_POST_PATCH_DEL_URL = `${COMPUTE_OPS_MGMT_URL}/v1/settings`;

const JOBS_V1B3_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta3/jobs`;
const OPS_MGMT_JOBS_V1B3_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta3/jobs`;

export const OPS_MGMT_SETTING_URI_PREFIX = '/compute-ops-mgmt/v1beta1/settings';
export const OPS_MGMT_SETTING_V1_URI_PREFIX = '/compute-ops-mgmt/v1/settings';
export const OPS_MGMT_GROUPS_V1_URI_PREFIX = '/compute-ops-mgmt/v1/groups';
export const OPS_MGMT_APPROVAL_POLICIES_V1_URI_PREFIX =
  '/compute-ops-mgmt/v1beta1/approval-policies';

const TAGS_URL = `${DOORWAY_URL}/compute/v2/tags`;

const PREFERENCES_URL = `${DOORWAY_URL}/compute/v1/user-preferences`;
const MSP_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/accounts`;

const SDI_URL = `${COBALT_URL}/compute/v1/supportinfo`;
const SDI_CONFIG_URL = `${SDI_URL}/servers`;
const SDI_LOCATIONS_URL = `${SDI_URL}/locations`;

const SERVICE_NOW_NAME = 'ServiceNow';
export const SERVICE_NOW_TYPE = 'SERVICE_NOW';
const ARUBA_CENTRAL_NAME = 'Aruba Central';
export const ARUBA_CENTRAL_TYPE = 'ARUBA_CENTRAL';
const DSCC_NAME = 'Data Services';
export const DSCC_TYPE = 'DSCC';
const OAUTH = 'OAUTH';
export const VMWARE_VCENTER_TYPE = 'VMWARE_VCENTER';

const OPS_MGMT_EXTERNAL_SERVICE_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/external-services`;
const SERVER_ACTIVATION_TOKEN_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/activation-tokens`;
// eslint-disable-next-line max-len
const SECURE_GATEWAY_ACTIVATION_KEY_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/appliance-activation-tokens`;
const ACTIVATION_KEYS_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/activation-keys`;

const AHS_FILES_URL = `${COMPUTE_OPS_MGMT_URL}/v1beta1/ahs-files`;

const SUBSCRIPTION_URL = `${DOORWAY_URL}/compute/v1/subscriptions`;
const GATEWAY_APPLIANCE_URL = '/compute-ops-mgmt/v1beta1/appliances/gateway';

function getPaginatedIlos(start, count, sort) {
  const params = {};

  start && (params.start = start);
  count && (params.count = count);
  sort && (params.sort = sort);

  return axios.get(ILOS_URL, {
    params,
  });
}

export async function getILOs() {
  const servers = [];
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getPaginatedIlos(
    0,
    MAX_COBALT_COUNT,
    null, // sort
  ).catch(e => {
    throw e;
  });
  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_COBALT_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getPaginatedIlos(
          i * MAX_COBALT_COUNT,
          MAX_COBALT_COUNT,
          null, // sort
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.push(firstFetch);
  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(server => {
        servers.push(server);
      });
    }
  });

  // mock the response data back in to an axios-like response similar to a single call
  const response = {
    // ensure the fields that will be modified are present
    data: {
      count: 0,
      total: 0,
      items: [],
    },
    ...firstFetch,
  };

  response.data.items = servers;
  response.data.count = servers.length;
  response.data.total = total;

  return response;
}

export function getParamsForServers(
  offset,
  count,
  sort,
  searchString,
  filterQueries,
  tagQueries,
) {
  const params = {};
  const filters = filterQueries || [];
  const tags = tagQueries || [];

  offset && (params.offset = offset);
  count && (params.limit = count);
  sort && (params.sort = sort);
  searchString &&
    (params.search = `${searchString
      .trim()
      .replaceAll('\\', '\\\\')
      .replaceAll("'", "\\'")}`);

  if (filters && filters.length > 0) {
    if (filters instanceof Array) {
      params.filter = `(${filters.join(') and (')})`;
    } else {
      params.filter = filters;
    }
  }

  if (tags && tags.length > 0) {
    if (tags instanceof Array) {
      params['filter-tags'] = `(${tags.join(') or (')})`;
    } else {
      params['filter-tags'] = tags;
    }
  }
  return params;
}

// !!! PLEASE READ !!!
// Before you add new queries to this or other functions
// STRONGLY consider including whatever you are querying
// in the existing filterQueries parameter. Any new filterable
// fields added to the UI need to be included in cleanslate
// (saved filters), or else the list of servers returned
// to the UI will not match the list of servers returned
// by a user's saved filter, which is bad. For more
// information on how to add a new field to cleanslate, see
// https://github.com/compute-cloud/cleanslate/blob/main/docs/maintenance.md
export function getServers(
  offset,
  count,
  sort,
  searchString,
  filterQueries,
  tagQueries,
) {
  const params = getParamsForServers(
    offset,
    count,
    sort,
    searchString,
    filterQueries,
    tagQueries,
  );

  return axios.get(SERVERS_URL, {
    params,
  });
}

export function getServersOverview(
  offset,
  count,
  sort,
  searchString,
  filterQueries,
  tagQueries,
) {
  const params = getParamsForServers(
    offset,
    count,
    sort,
    searchString,
    filterQueries,
    tagQueries,
  );

  return axios.get(SERVERS_OVERVIEW_URL, {
    params,
  });
}

export async function getAllServersOverviewByFilter(
  filterQueries = null,
  searchString = null,
  tagQueries = null,
) {
  const servers = [];
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getServersOverview(
    0,
    MAX_DOORWAY_COUNT,
    null, // sort
    searchString ? searchString.trim() : null,
    filterQueries,
    tagQueries,
  ).catch(e => {
    throw e;
  });
  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getServersOverview(
          i * MAX_DOORWAY_COUNT,
          MAX_DOORWAY_COUNT,
          null, // sort
          searchString ? searchString.trim() : null,
          filterQueries,
          tagQueries,
        ),
      );
    }
    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.push(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(server => {
        servers.push(server);
      });
    }
  });

  return servers;
}

export function getGroupIloSettingsCompliances(id) {
  return new Promise(resolve => {
    if (id) {
      axios
        .get(`${OPS_MGMT_GROUPS_V1B2_URL}/${id}/ilo-settings-compliance`)
        .then(res => {
          if (res?.status === 200) {
            resolve(res?.data);
          } else {
            resolve({});
          }
        })
        .catch(error => resolve({}));
    } else {
      resolve({});
    }
  });
}

export function getServersData(
  format,
  sort,
  searchString,
  filterQueries,
  tagQueries,
) {
  const params = {};
  const filters = filterQueries || [];
  const tags = tagQueries || [];

  format && (params.format = format);
  sort && (params.sort = sort);
  searchString &&
    (params.search = `${searchString
      .trim()
      .replaceAll('\\', '\\\\')
      .replaceAll("'", "\\'")}`);

  if (filters && filters.length > 0) {
    if (filters instanceof Array) {
      params.filter = `(${filters.join(') and (')})`;
    } else {
      params.filter = filters;
    }
  }

  if (tags && tags.length > 0) {
    if (tags instanceof Array) {
      params['filter-tags'] = `(${tags.join(') or (')})`;
    } else {
      params['filter-tags'] = tags;
    }
  }

  return axios.get(SERVERS_DATA_URL, {
    params,
  });
}

// !!! See comment above getServers before adding new queries !!!
export async function getAllSelectedServers(
  selected,
  unselected,
  allSelected,
  searchString,
  filterQueries,
  tagQueries,
) {
  const servers = [];
  let fetches = [];
  let total = 0;
  let count = 0;

  // If selected servers are 0 < 10 disregard any other filter and filter only
  // selected servers.
  if (
    allSelected !== true &&
    selected &&
    selected.length > 0 &&
    selected.length < MAX_FILTER_GET_ALL_SERVERS
  ) {
    const selectedQueries = [];
    selected.forEach(id => {
      selectedQueries.push(`id eq '${id}'`);
    });
    const queriesToAdd = `(${selectedQueries.join(') or (')})`;
    // Check length of query to make sure filter=<length> is not greater than 2000.
    // getServers() wraps 'and(<filterQueries>)', adding 5 characters: 2000-5 = 1995
    // In the event our query is too long we wont modify filterQueries and
    // default to grabbing all servers
    queriesToAdd.length <= MAX_FILTER_LENGTH_GET_ALL_SERVERS &&
      filterQueries &&
      filterQueries.splice(0, filterQueries.length, queriesToAdd);
  }

  const firstFetch = await getServers(
    0,
    MAX_DOORWAY_COUNT,
    null, // sort
    searchString ? searchString.trim() : null,
    filterQueries,
    tagQueries,
  ).catch(e => {
    throw e;
  });
  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getServers(
          i * MAX_DOORWAY_COUNT,
          MAX_DOORWAY_COUNT,
          null, // sort
          searchString ? searchString.trim() : null,
          filterQueries,
          tagQueries,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.push(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(server => {
        if (isSelected(server.id, selected, unselected, allSelected)) {
          servers.push(server);
        }
      });
    }
  });

  return servers;
}

export async function getAllServersByFilter(
  filterQueries = null,
  searchString = null,
  tagQueries = null,
) {
  const servers = [];
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getServers(
    0,
    MAX_DOORWAY_COUNT,
    null, // sort
    searchString ? searchString.trim() : null,
    filterQueries,
    tagQueries,
  ).catch(e => {
    throw e;
  });
  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getServers(
          i * MAX_DOORWAY_COUNT,
          MAX_DOORWAY_COUNT,
          null, // sort
          searchString ? searchString.trim() : null,
          filterQueries,
          tagQueries,
        ),
      );
    }
    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.push(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(server => {
        servers.push(server);
      });
    }
  });

  return servers;
}

export function getServerCounts(fields) {
  return axios.get(SERVERS_COUNTS_URL, { params: { fields } });
}

export function getServer(id, tenantAcid) {
  return axios.get(`${SERVERS_URL}/${id}`, {
    forceTenantAcid: tenantAcid,
  });
}

export function getServerInventory(id, stableServerAPIFlag) {
  if (stableServerAPIFlag) {
    return axios.get(
      `${OPS_MGMT_SERVERS_V1_POST_PATCH_DEL_URL}/${id}/inventory`,
    );
  }
  return axios.get(`${OPS_MGMT_SERVERS_POST_PATCH_DEL_URL}/${id}/inventory`);
}

export function setServerNotificationStatus(
  serverId,
  criticalValue,
  healthValue,
  criticalNonServiceValue,
  warningValue,
  stableServerAPIFlag,
) {
  if (stableServerAPIFlag) {
    return axios.put(
      `${OPS_MGMT_SERVERS_V1_POST_PATCH_DEL_URL}/${serverId}/notifications`,
      {
        criticalNotification: criticalValue,
        healthNotification: healthValue,
        criticalNonServiceNotification: criticalNonServiceValue,
        warningNotification: warningValue,
      },
    );
  }
  return axios.put(
    `${OPS_MGMT_SERVERS_POST_PATCH_DEL_URL}/${serverId}/notifications`,
    {
      criticalNotification: criticalValue,
      healthNotification: healthValue,
      criticalNonServiceNotification: criticalNonServiceValue,
      warningNotification: warningValue,
    },
  );
}

export function setBulkServerNotificationStatus(serverIds, formValues) {
  const serverNotificationUrl = OPS_MGMT_SERVER_NOTIFICATIONS_URL;

  const requestData = {
    criticalNotification: formValues.criticalHWNotificationEnabled,
    healthNotification: formValues.healthHWNotificationEnabled,
    criticalNonServiceNotification:
      formValues.criticalNonServiceNotificationEnabled,
    warningNotification: formValues.warningNotificationEnabled,
  };

  return axios.post(`${serverNotificationUrl}/bulk-update`, {
    serverIds,
    requestData,
  });
}

export function getEmailNotificationStatus(stableApiPreferencesFlag) {
  if (stableApiPreferencesFlag) {
    return axios.get(`${OPS_MGMT_EMAIL_NOTIFICATION_URL_V1}`);
  }
  return axios.get(`${OPS_MGMT_EMAIL_NOTIFICATION_URL}`);
}

export async function setEmailNotificationStatus(
  criticalValue,
  healthValue,
  criticalNonServiceValue,
  warningValue,
  stableApiPreferencesFlag,
) {
  let emailNotificationUrl = OPS_MGMT_EMAIL_NOTIFICATION_URL;

  if (stableApiPreferencesFlag) {
    emailNotificationUrl = OPS_MGMT_EMAIL_NOTIFICATION_URL_V1;
  }

  return axios.get(`${emailNotificationUrl}`).then(r => {
    if (r.data.count === 0) {
      return axios.post(`${emailNotificationUrl}`, {
        criticalNotification: criticalValue,
        healthNotification: healthValue,
        criticalNonServiceNotification: criticalNonServiceValue,
        warningNotification: warningValue,
      });
    }
    const item = r.data.items[0];
    return axios.put(`${emailNotificationUrl}/${item.id}`, {
      criticalNotification: criticalValue,
      healthNotification: healthValue,
      criticalNonServiceNotification: criticalNonServiceValue,
      warningNotification: warningValue,
    });
  });
}

export function getServerSetting(id, tenantAcid, multiVolumeSupportFlag) {
  return axios
    .get(`${SERVER_SETTINGS_URL}/${id}`, {
      forceTenantAcid: tenantAcid,
    })
    .then(response => {
      if (
        multiVolumeSupportFlag &&
        response?.data &&
        response.status >= 200 &&
        response.status <= 299 &&
        response.data.category === SETTING_CATEGORY_STORAGE &&
        response.data.settings?.DEFAULT?.raidType
      ) {
        const tempStorageObject = {
          id: null,
          raidType: response.data.settings.DEFAULT.raidType,
          capacityInGiB:
            response.data.settings.DEFAULT.volumeSizeInGB === -1
              ? null
              : convertVolumeSizeGBToGiB(
                  response.data.settings.DEFAULT.volumeSizeInGB,
                ),
          driveCount: null,
          spareDriveCount: null,
          driveTechnology: null,
          ioPerfModeEnabled: null,
          readCachePolicy: null,
          writeCachePolicy: null,
        };

        response.data.settings.DEFAULT.volumes = [];
        response.data.settings.DEFAULT.volumes.push(tempStorageObject);
      }
      return response;
    });
}

export function fetchSpecServerSetting(
  serverSettings,
  specServerSettingName,
  tenantAcid,
) {
  serverSettings = serverSettings.filter(
    setting =>
      setting.id && setting?.category?.toUpperCase() === specServerSettingName,
  );
  const settingRequests = serverSettings.map(serverSetting =>
    getServerSetting(serverSetting.id, tenantAcid).then(settingResp => {
      Object.assign(serverSetting, settingResp.data);
    }),
  );
  return settingRequests;
}

// Get a page of groups
export async function getGroups(
  start,
  count,
  sort,
  searchString,
  filterQueries,
  tagQueries,
) {
  const params = {};
  const filters = filterQueries || [];
  const tags = tagQueries || [];
  start && (params.offset = start);
  count && (params.limit = count);
  sort && (params.sort = sort);
  searchString &&
    (params.search = `${searchString
      .trim()
      .replaceAll('\\', '\\\\')
      .replaceAll("'", "\\'")}`);

  if (filters && filters.length > 0) {
    params.filter = `(${filters.join(') and (')})`;
  }

  if (tags && tags.length > 0) {
    params.tags = `${tags.join(';')}`;
  }
  const groupsResp = await axios.get(GROUPS_URL_V2, {
    params,
  });

  const groupList = groupsResp.data.items;
  const firmware = SETTING_CATEGORY_FIRMWARE.toUpperCase();
  const settingRequests = [];
  for (let index = 0; index < groupList.length; index++) {
    const serverSettings = groupList[index].serverSettings_ || [];
    settingRequests.push(fetchSpecServerSetting(serverSettings, firmware));
  }

  await Promise.all(
    settingRequests.map(async settingRequest =>
      Promise.all(settingRequest).then(_ => groupsResp),
    ),
  );
  return groupsResp;
}

export async function getAllGroups(sort, filterQueries) {
  return getAllPages(
    offset =>
      getGroups(
        offset,
        MAX_DOORWAY_COUNT,
        sort,
        null, // search
        filterQueries,
        null, // tags
      ),
    MAX_DOORWAY_COUNT,
  );
}

export async function getGroup(id, tenantAcid) {
  const groupResp = await axios.get(`${GROUPS_URL_V2}/${id}`, {
    forceTenantAcid: tenantAcid,
  });

  // also fetch any firmware server settings, so that we have firmwareBundleName
  const serverSettings = groupResp.data.serverSettings_ || [];
  const firmware = SETTING_CATEGORY_FIRMWARE.toUpperCase();
  const settingRequests = fetchSpecServerSetting(
    serverSettings,
    firmware,
    tenantAcid,
  );
  return Promise.all(settingRequests).then(_ => groupResp);
}

export async function getAllILOComplianceServersInGroup(groupId, filter) {
  const response = await fetch(
    `/api/groups/${groupId}/ilo-compliance-servers?filter=${JSON.stringify(filter)}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to fetch ILO compliance servers');
  }

  const data = await response.json();
  return data;
}

export function deleteGroup(id) {
  const url = OPS_MGMT_GROUP_POST_PATCH_DEL_V1_URL;

  return axios.delete(`${url}/${id}`, {
    params: { force: true },
  });
}

export function addDeviceToGroup(groupId, payload) {
  // default url
  const url = OPS_MGMT_GROUP_POST_PATCH_DEL_V1_URL;

  return axios.post(`${url}/${groupId}/devices`, payload);
}

export function removeDevicesFromGroup(groupId, deviceIds, resetSubsystems) {
  const params = {};
  if (resetSubsystems) {
    if (Array.isArray(resetSubsystems) && resetSubsystems.length > 0) {
      params['reset-subsystems'] = resetSubsystems.join(',');
    } else if (typeof resetSubsystems === 'string' && resetSubsystems !== '') {
      params['reset-subsystems'] = resetSubsystems;
    }
  }

  const url = OPS_MGMT_GROUP_POST_PATCH_DEL_V1_URL;
  return axios.post(
    `${url}/${groupId}/devices/unassign`,
    {
      devices: deviceIds.map(id => ({ deviceId: id })),
    },
    { params: { ...params } },
  );
}

export async function getAllServersInGroup(groupId, sort, filterQueries = []) {
  const servers = [];
  let fetches = [];
  let total = 0;
  let count = 0;
  const localFilterQueries = [`group/id eq '${groupId}'`, ...filterQueries];

  const firstFetch = await getServers(
    0,
    MAX_DOORWAY_COUNT,
    sort,
    null, // search
    localFilterQueries,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getServers(
          i * MAX_DOORWAY_COUNT,
          MAX_DOORWAY_COUNT,
          sort,
          null, // search
          localFilterQueries,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.push(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(server => {
        servers.push(server);
      });
    }
  });

  return servers;
}

export async function performPreFlightChecksV2(
  serverIdWithSppId,
  swDriversSelected,
  downgradeSelected,
  powerOffNeeded,
) {
  const payload = {
    mode: 'withJob',
    serverSppIds: serverIdWithSppId,
    installSoftwareAndDrivers: swDriversSelected,
    downgradeFirmwareBundle: downgradeSelected,
    powerOff: powerOffNeeded,
  };
  return axios.post(FW_PREFLIGHT_URL, payload);
}

export async function performOsInstallPreflightChecks(
  serverId,
  stableServerAPIFlag,
) {
  let url = `${OPS_MGMT_OS_PREFLIGHT_URL}/${serverId}/analyze-os-install`;
  if (stableServerAPIFlag) {
    url = `${OPS_MGMT_SERVERS_V1_POST_PATCH_DEL_URL}/${serverId}/analyze-os-install`;
  }
  return axios.post(url, {});
}

// Get a page of groups using a server setting
export function getGroupsUsingSetting(
  settingId,
  offset,
  limit,
  stableSettingFlagV1,
) {
  let url = stableSettingFlagV1
    ? OPS_MGMT_SETTING_V1_URI_PREFIX
    : OPS_MGMT_SETTING_URI_PREFIX;
  const settingUri = `${url}/${settingId}`;
  const params = {};
  params.filter = `'${settingUri}' in settingsUris`;
  offset && (params.offset = offset);
  limit && (params.limit = limit);

  url = OPS_MGMT_GROUP_POST_PATCH_DEL_V1_URL;
  return axios.get(`${url}`, {
    params,
  });
}

export async function getAllGroupsUsingSetting(settingId, stableSettingFlagV1) {
  const groups = [];
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getGroupsUsingSetting(
    settingId,
    0,
    MAX_DOORWAY_COUNT,
    stableSettingFlagV1,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getGroupsUsingSetting(
          settingId,
          i * MAX_DOORWAY_COUNT,
          MAX_DOORWAY_COUNT,
          stableSettingFlagV1,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.splice(0, 0, firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(group => {
        groups.push(group);
      });
    }
  });

  return groups;
}

export function createGroup(payload) {
  const url = OPS_MGMT_GROUP_POST_PATCH_DEL_V1_URL;

  return axios.post(url, payload);
}

export function updateGroup(id, payload) {
  const url = OPS_MGMT_GROUP_POST_PATCH_DEL_V1_URL;

  return axios.patch(`${url}/${id}`, payload, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });
}

export function getGroupCounts() {
  return axios.get(`${GROUP_COUNTS_URL}`);
}

export function getGroupActivities(groupId) {
  const NUM_ITEMS_IN_GROUP_ACTIVITY_CARD = 10;
  const params = {};
  params.groups = NUM_ITEMS_IN_GROUP_ACTIVITY_CARD;
  params.filter = `(groupId eq '${groupId}')`;

  return axios.get(UI_DOORWAY_ACTIVITIES_URL, {
    params,
  });
}

export function getServerSettingsActivities(id) {
  const NUM_ITEMS_IN_SETTING_ACTIVITY_CARD = 10;
  const params = {};
  params.groups = NUM_ITEMS_IN_SETTING_ACTIVITY_CARD;
  params.filter = `(serverSettingsId eq '${id}')`;

  return axios.get(UI_DOORWAY_ACTIVITIES_URL, {
    params,
  });
}

export function getPoliciesActivities(id) {
  const NUM_ITEMS_IN_POLICIES_ACTIVITY_CARD = 10;
  const params = {};
  params.groups = NUM_ITEMS_IN_POLICIES_ACTIVITY_CARD;
  params.filter = `(policyId eq '${id}')`;

  return axios.get(UI_DOORWAY_ACTIVITIES_URL, {
    params,
  });
}

export function getActivityTotalCount() {
  const params = {
    offset: 0,
    limit: 1,
  };

  return axios.get(UI_DOORWAY_ACTIVITIES_URL, {
    params,
  });
}

// Get a page of tags
export function getTags(next, limit, sort, filterQueries) {
  const params = { next: null };
  const filters = filterQueries || [];

  next && (params.next = next);
  limit && (params.limit = limit);
  sort && (params.sort = sort);

  if (filters && filters.length > 0) {
    params.filter = `(${filters.join(') and (')})`;
  }
  return axios.get(TAGS_URL, { params });
}

export async function getAllTags(sort, filterQueries) {
  const tags = [];
  let first = true;
  let next = null;
  let fetch = null;

  while (first || next) {
    // Must wait for each response to get the
    // next parameter for the next request.
    // eslint-disable-next-line no-await-in-loop
    fetch = await getTags(next, MAX_DOORWAY_COUNT, sort, filterQueries).catch(
      e => {
        throw e;
      },
    );
    next = fetch?.data?.next ? fetch.data.next : null;
    if (fetch?.data?.items) {
      fetch.data.items.forEach(tag => {
        tags.push(tag);
      });
    }
    first = false;
  }
  return tags;
}

// Get a page of server settings through v2 api
export function getServerSettings(offset, limit, sort, filterQueries) {
  const params = {};
  const filters = filterQueries || [];
  offset && (params.offset = offset);
  limit && (params.limit = limit);
  sort && (params.sort = sort);

  if (filters && filters.length > 0) {
    if (filters instanceof Array) {
      params.filter = `(${filters.join(') and (')})`;
    } else {
      params.filter = filters;
    }
  }

  return axios.get(SERVER_SETTINGS_URL, {
    params,
  });
}

// Get all server settings through v2 api
export async function getAllServerSettings(sort, filterQueries) {
  const settings = [];
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getServerSettings(
    0,
    MAX_DOORWAY_COUNT,
    sort,
    filterQueries,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getServerSettings(
          i * MAX_DOORWAY_COUNT,
          MAX_DOORWAY_COUNT,
          sort,
          filterQueries,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.splice(0, 0, firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(setting => {
        settings.push(setting);
      });
    }
  });

  return settings;
}

export function createServerSetting(
  payload,
  genericHuddleApisFlag,
  stableSettingFlagV1,
) {
  let url;
  if (stableSettingFlagV1) {
    url = OPS_MGMT_SETTINGS_V1_POST_PATCH_DEL_URL;
  } else if (genericHuddleApisFlag) {
    url = OPS_MGMT_SETTINGS_POST_PATCH_DEL_URL;
  } else {
    url = OPS_MGMT_SRVR_SETTINGS_POST_PATCH_DEL_URL;
  }
  return axios.post(url, payload);
}

export function updateServerSetting(
  id,
  payload,
  genericHuddleApisFlag,
  stableSettingFlagV1,
) {
  let url;
  if (stableSettingFlagV1) {
    url = OPS_MGMT_SETTINGS_V1_POST_PATCH_DEL_URL;
  } else if (genericHuddleApisFlag) {
    url = OPS_MGMT_SETTINGS_POST_PATCH_DEL_URL;
  } else {
    url = OPS_MGMT_SRVR_SETTINGS_POST_PATCH_DEL_URL;
  }
  return axios.patch(`${url}/${id}`, payload, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });
}

export function deleteServerSetting(
  id,
  genericHuddleApisFlag,
  stableSettingFlagV1,
) {
  let url;
  if (stableSettingFlagV1) {
    url = OPS_MGMT_SETTINGS_V1_POST_PATCH_DEL_URL;
  } else if (genericHuddleApisFlag) {
    url = OPS_MGMT_SETTINGS_POST_PATCH_DEL_URL;
  } else {
    url = OPS_MGMT_SRVR_SETTINGS_POST_PATCH_DEL_URL;
  }
  return axios.delete(`${url}/${id}`);
}

export function updateServerAutoIloFw(deviceIds, payload, stableServerAPIFlag) {
  let idQueryStr = '';
  // add deviceids to query string in the format baseurl?id=x&id=y...
  // replace the '+' in id with url encoding %2B
  deviceIds.map(id => (idQueryStr += `id=${id.replace(/\+/g, '%2B')}&`));
  if (stableServerAPIFlag) {
    return axios.patch(
      `${OPS_MGMT_SERVERS_V1_POST_PATCH_DEL_URL}?${idQueryStr}`,
      payload,
      {
        headers: { 'content-type': 'application/merge-patch+json' },
      },
    );
  }
  return axios.patch(
    `${OPS_MGMT_SERVERS_POST_PATCH_DEL_URL}?${idQueryStr}`,
    payload,
    {
      headers: { 'content-type': 'application/merge-patch+json' },
    },
  );
}
// Get all recent activities
export function getRecentActivities() {
  return axios.get(UI_DOORWAY_ACTIVITIES_URL, {
    params: {
      groups: NUM_ITEMS_IN_ACTIVITY_CARD,
    },
  });
}

function getStartOfDay(timestamp) {
  const startOfDay = new Date(timestamp);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay.toISOString();
}

function getEndOfDay(timestamp) {
  const endOfDay = new Date(timestamp);
  endOfDay.setHours(0, 0, 0, 0);
  endOfDay.setDate(endOfDay.getDate() + 1);
  return endOfDay.toISOString();
}

// Get a page of activities
export function getPaginatedActivities(
  start,
  count,
  sort,
  searchString,
  dateFilter,
  filterQueries,
) {
  const params = {
    offset: start,
    limit: count,
    sort,
  };
  const filters = [];

  searchString &&
    filters.push(
      `contains(toupper(source/displayName),'${searchString
        .trim()
        .replaceAll("'", "''")
        .toUpperCase()}')`,
    );

  if (dateFilter) {
    if (dateFilter[0]) {
      filters.push(`(createdAt ge ${getStartOfDay(dateFilter[0])})`);
    }
    if (dateFilter[1]) {
      filters.push(`(createdAt lt ${getEndOfDay(dateFilter[1])})`);
    }
  }

  // filterQueries are the checkboxes in the activity page filter layer
  // all of the checkbox's queries get OR'ed together and are not exclusive
  filterQueries &&
    filterQueries.length > 0 &&
    filters.push(`(${filterQueries.join(') or (')})`);

  // search + date + the result of the check boxes OR'ed together all get
  // AND'ed together so results must match the name and date, but only one of
  // the check boxes
  if (filters.length > 0) {
    params.filter = `(${filters.join(') and (')})`;
  }

  return axios.get(UI_DOORWAY_ACTIVITIES_URL, {
    params,
  });
}

// Get OIDC config from wellknown endpoint
export function getOpenIdConfig() {
  return axios.get(OPENID_CONFIG_URL);
}

export function createJob(payload) {
  return axios.post(JOBS_URL, payload);
}

export function createJobV1B2(payload) {
  return axios.post(COMPUTE_OPS_MGMT_JOBS_URL, payload);
}

export function createSchedule(payload) {
  return axios.post(COMPUTE_OPS_MGMT_SCHEDULE_URL, payload);
}

export function getSchedules(start, count, sort, filterQueries) {
  const params = {};
  const filters = filterQueries || [];
  start && (params.start = start);
  count && (params.count = count);
  sort && (params.sort = sort);

  if (filters && filters.length > 0) {
    if (filters instanceof Array) {
      params.filter = `(${filters.join(') and (')})`;
    } else {
      params.filter = filters;
    }
  }
  return axios.get(COMPUTE_OPS_MGMT_SCHEDULE_URL, {
    params,
  });
}

export async function getAllResources(resourceMethod, sort, filterQueries) {
  const resources = [];
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await resourceMethod(
    0,
    MAX_DOORWAY_COUNT,
    sort,
    filterQueries,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        resourceMethod(
          i * MAX_DOORWAY_COUNT,
          MAX_DOORWAY_COUNT,
          sort,
          filterQueries,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.splice(0, 0, firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(setting => {
        resources.push(setting);
      });
    }
  });

  return resources;
}

export function getAllSchedules(sort, filterQueries) {
  return getAllResources(getSchedules, sort, filterQueries);
}

export function getIloFWUpdateSchedules() {
  return getAllSchedules(
    null,
    `purpose eq '${SERVER_ILO_FW_UPDATE}' and nextStartAt ne null`,
  );
}

export function deleteSchedule(id) {
  return axios.delete(`${COMPUTE_OPS_MGMT_SCHEDULE_URL}/${id}`);
}

export function deleteSchedules(schedules) {
  const proms = [];
  schedules.forEach(schedule => {
    proms.push(deleteSchedule(schedule.id));
  });
  return Promise.all(proms);
}

export function updateSchedule(id, payload) {
  return axios.patch(`${COMPUTE_OPS_MGMT_SCHEDULE_URL}/${id}`, payload, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });
}

export function getBundles(offset, count, url) {
  const params = {};
  offset && (params.offset = offset);
  count && (params.limit = count);
  return axios.get(url, { params });
}

export async function getFirmwareBundles(firmwareBundlesFlag) {
  if (firmwareBundlesFlag) {
    const url = FIRMWARE_BUNDLES_URL_V1;
    let fetches = [];
    let total = 0;
    let count = 0;
    const firstFetch = await getBundles(0, MAX_DOORWAY_COUNT, url).catch(e => {
      throw e;
    });

    if (firstFetch?.data?.total) {
      total = firstFetch.data.total;
    }
    if (firstFetch?.data?.items?.length) {
      count = firstFetch.data.items.length;
    }

    if (total > count) {
      const promiseArray = [];
      const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
      for (let i = 1; i < numFetches; i++) {
        promiseArray.push(
          getBundles(i * MAX_DOORWAY_COUNT, MAX_DOORWAY_COUNT, url),
        );
      }

      fetches = await Promise.all(
        promiseArray.map(p =>
          p.catch(e => {
            throw e;
          }),
        ),
      ).then(values => values);

      fetches.forEach(fetch => {
        if (fetch?.data?.items) {
          firstFetch.data.items.push(...fetch.data.items);
          firstFetch.data.total += fetch.data.total;
          firstFetch.data.count += fetch.data.count;
        }
      });
    }

    if (firstFetch?.data?.items) {
      // with firmwareBundlesFlag on we receive different DTO keys:
      // for flag cleanup we should replace selfUri with resourceUri,
      // type with bundleType and hotfixBaseUri with basBundleUri.
      // Also in cleanup we can remove the need for changing
      // capitalization by changing exact string checks
      // to all use one case
      firstFetch.data.items.forEach(item => {
        item.selfUri = item.resourceUri.replace('/compute-ops-mgmt', '');
        item.resourceType = item.type;
        item.type = item.bundleType ? item.bundleType.toLowerCase() : '';
        item.platformFamily = item.platformFamily.toLowerCase();
        item.hotfixBaseUri = item.baseBundleUri
          ? item.baseBundleUri.replace('/compute-ops-mgmt', '')
          : null;
      });
    }

    return firstFetch;
  }

  return axios.get(FIRMWARE_BUNDLES_URL);
}

export function getApplianceFirmwareBundles(
  applianceUpdatePhase2Flag,
  applianceType,
  stableApplianceFwFlag,
) {
  const params = {};
  params.start = 0;
  params.limit = MAX_APPLIANCE_FW_BUNDLE_COUNT;
  if (applianceType) {
    params.filter = applianceUpdatePhase2Flag
      ? `applianceType eq '${applianceType.toUpperCase()}`
      : `eq(applianceType, '${applianceType.toUpperCase()}')`;
  }

  const applianceFwBundleUrl = stableApplianceFwFlag
    ? APPLIANCE_FIRMWARE_BUNDLES_URL_V1
    : APPLIANCE_FIRMWARE_BUNDLES_URL;

  return axios.get(
    applianceUpdatePhase2Flag
      ? APPLIANCE_FW_BUNDLE_UIDOORWAY_URL
      : `${applianceFwBundleUrl}?sort=applianceVersion:desc`,
    { params },
  );
}

export function getApplicableApplianceFirmwareBundles(
  applianceType,
  applianceUpdatePhase2Flag,
  stableApplianceFwFlag,
) {
  const params = {};
  params.start = 0;

  const applianceFwBundleFilter = stableApplianceFwFlag
    ? `isActive eq true and applianceType eq '${applianceType.toUpperCase()}'`
    : `and(eq(isActive,true),eq(applianceType, '${applianceType.toUpperCase()}'))`;

  params.filter = applianceUpdatePhase2Flag
    ? `(isActive eq true) and (applianceType eq '${applianceType.toUpperCase()}')`
    : applianceFwBundleFilter;

  const applianceFwBundleUrl = stableApplianceFwFlag
    ? APPLIANCE_FIRMWARE_BUNDLES_URL_V1
    : APPLIANCE_FIRMWARE_BUNDLES_URL;

  return axios.get(
    applianceUpdatePhase2Flag
      ? APPLIANCE_FW_BUNDLE_UIDOORWAY_URL
      : applianceFwBundleUrl,
    { params },
  );
}

export function getApplianceFirmware(
  applianceFirmwareId,
  applianceUpdatePhase2Flag,
  stableApplianceFwFlag,
) {
  const applianceFwBundleUrl = stableApplianceFwFlag
    ? APPLIANCE_FIRMWARE_BUNDLES_URL_V1
    : APPLIANCE_FIRMWARE_BUNDLES_URL;

  return axios.get(
    `${
      applianceUpdatePhase2Flag
        ? APPLIANCE_FW_BUNDLE_UIDOORWAY_URL
        : applianceFwBundleUrl
    }/${applianceFirmwareId}`,
  );
}

export function getFWULatest() {
  const params = {};
  params.start = 0;
  return axios.get(`${FIRMWARE_UPGRADES_URL}/latest`, params);
}

export function getFWUUpdate() {
  const params = {};
  params.start = 0;
  return axios.get(`${FIRMWARE_UPGRADES_URL}/update`, params);
}

/*
 * No uidoorway version of this method is available because
 * displayAppliances=true filter is not supported by uidoorway API
 * This will return list in applicableAppliances which is calculated by backend
 * Phoenix changes may be required to support returning applicableAppliances in the response
 */
export function getApplianceFWULatest(applianceType, stableApplianceFwFlag) {
  const params = {};
  params.start = 0;

  params.filter = stableApplianceFwFlag
    ? `isActive eq true and applianceType eq '${applianceType.toUpperCase()}'`
    : `and(eq(isActive,true),eq(applianceType, '${applianceType.toUpperCase()}'))`;

  const applianceFwBundleUrl = stableApplianceFwFlag
    ? APPLIANCE_FIRMWARE_BUNDLES_URL_V1
    : APPLIANCE_FIRMWARE_BUNDLES_URL;

  return axios.get(
    `${applianceFwBundleUrl}?sort=applianceVersion:desc&limit=1&displayAppliances=true`,
    { params },
  );
}

export function getBiosSettings() {
  return axios.get(BIOS_SETTINGS_URL);
}
export function cancelPendingJobs(parentjobId) {
  const payload = { input: { stopOnRequest: 'true' } };
  return axios.patch(`${JOBS_URL}/${parentjobId}`, payload);
}

export function cancelWaitingFWUJob(jobId) {
  const payload = { input: { cancel: true } };
  return axios.patch(`${JOBS_URL}/${jobId}`, payload);
}

export function cancelAnalyzeJob(parentjobId) {
  const payload = { input: { skipPreflightCheck: 'true' } };
  return axios.patch(`${JOBS_URL}/${parentjobId}`, payload);
}

export function dismissStalledJob(jobId) {
  const dismissPayload = { state: 'Error' };
  return axios.patch(`${JOBS_URL}/${jobId}`, dismissPayload);
}

export function getServerActivities(serverId) {
  const NUM_ITEMS_IN_SERVER_ACTIVITY_CARD = 10;
  const params = {};
  params.offset = 0;
  params.limit = NUM_ITEMS_IN_SERVER_ACTIVITY_CARD;
  params.filter = `(associatedServerUri eq '/compute-ops-mgmt/v1beta2/servers/${serverId}')`;

  return axios.get(UI_DOORWAY_ACTIVITIES_URL, {
    params,
  });
}

export function getApplianceActivities(appId, applianceType) {
  const NUM_ITEMS_IN_SERVER_ACTIVITY_CARD = 10;
  const params = {};
  params.offset = 0;
  params.limit = NUM_ITEMS_IN_SERVER_ACTIVITY_CARD;
  // assoiciateServerUri having servers reference in it.
  // Once fixed at lime, this should be updated correctly.
  if (applianceType === OV_APPLIANCE_TYPE.GATEWAY) {
    // eslint-disable-next-line max-len
    params.filter = `(associatedServerUri eq '/compute-ops-mgmt/v1beta2/servers/gateway+${appId}')`;
  } else {
    // eslint-disable-next-line max-len
    params.filter = `(associatedServerUri eq '/compute-ops-mgmt/v1beta2/servers/oneview+${appId}')`;
  }

  return axios.get(UI_DOORWAY_ACTIVITIES_URL, {
    params,
  });
}

export function getServerGroupAlerts(
  groupId,
  offset = 0,
  limit = MAX_DOORWAY_COUNT,
) {
  const params = { offset, limit };
  params.filter = "not category eq 'security' and clearedAt eq null";
  params.sort = 'severity desc,updatedAt desc';
  return axios.get(`${GROUPS_URL_V2}/${groupId}/alerts`, { params }).then(r => {
    // group alerts by serverId
    const obj =
      r.data.items?.reduce((obj, item) => {
        const serverName = item.server_?.name || item.serverId || 'unknown';
        if (!obj[serverName]) {
          obj[serverName] = [];
        }
        obj[serverName].push(item);
        return obj;
      }, {}) || {};
    r.data.items = Object.entries(obj).map(([device, alerts]) => ({
      device,
      alerts,
    }));
    return r;
  });
}

export function getAllServerGroupAlerts(groupId) {
  return getAllPages(
    offset => getServerGroupAlerts(groupId, offset, MAX_DOORWAY_COUNT),
    MAX_DOORWAY_COUNT,
  ).then(entries => {
    // merge entries for the same server from different pages together
    const obj = entries.reduce((obj, { device, alerts }) => {
      if (!obj[device]) {
        obj[device] = [];
      }
      obj[device].push(...alerts);
      return obj;
    }, {});
    const mergedEntries = Object.entries(obj);
    // getAllPages returns an array - re-wrap for code expecting axios response
    return {
      data: {
        offset: 0,
        count: mergedEntries.length,
        total: mergedEntries.length,
        items: mergedEntries.map(([device, alerts]) => ({
          device,
          alerts,
        })),
      },
    };
  });
}

export function getServerInventoryData(
  { id, format },
  serversFlag,
  stableServerAPIFlag,
) {
  let url;
  if (stableServerAPIFlag) {
    url = `${OPS_MGMT_SERVERS_V1_POST_PATCH_DEL_URL}/${id}/inventory?`;
  } else if (serversFlag) {
    url = `${OPS_MGMT_SERVERS_POST_PATCH_DEL_URL}/${id}/inventory?`;
  } else {
    url = `${SERVERS_POST_PATCH_DEL_URL}/${id}/inventory?`;
  }

  if (format === 'csv') {
    return axios.post(
      url.concat('format=csv'),
      SERVER_INVENTORY_TEMPLATE_JSON,
      {
        headers: { 'content-type': 'application/json' },
      },
    );
  }
  return axios.post(url.concat('format=json'), SERVER_INVENTORY_TEMPLATE_JSON, {
    headers: { 'content-type': 'application/json' },
  });
}

export function getServerNetworkConnectivity(serverId) {
  return axios.get(
    `${DOORWAY_URL}/compute/v1/servers/${serverId}/tor-port-mapping`,
  );
}

export function createSavedFilter(payload) {
  return axios.post(OPS_MGMT_SAVED_FILTERS_URL, payload);
}

export async function listSavedFilters(resourceType) {
  const pageSize = 10000; // max page size allowed by cleanslate
  const params = {
    sort: 'name asc',
    limit: `${pageSize}`,
    filter: `filterResourceType eq '${resourceType}'`,
  };

  const saveFiltersUrl = OPS_MGMT_SAVED_FILTERS_URL;

  const firstResp = await axios.get(saveFiltersUrl, { params });

  // The UI does not currently paginate the list of saved filters,
  // so fetch all of them if there are multiple pages.
  if (
    firstResp?.data?.total &&
    firstResp?.data?.count &&
    firstResp.data.total > firstResp.data.count
  ) {
    const numPages = Math.floor(firstResp.data.total / pageSize);
    const offsets = Array.from(
      Array(numPages),
      (_, page) => (page + 1) * pageSize,
    );

    const requests = offsets.map(index =>
      axios.get(saveFiltersUrl, { params: { offset: index, ...params } }),
    );
    const otherResps = await Promise.all(requests);
    const allItems = [firstResp.data?.items]
      .concat(otherResps.map(resp => resp?.data?.items))
      .flat();

    // If we are unlucky, changes to filters during fetches could result in dups/missing items
    // Duplicates would be most obvious, so at least eliminate those:
    const seen = {};
    const uniq = allItems.filter(item => {
      if (!item || !item?.id || item.id in seen) {
        return false;
      }
      seen[item.id] = true;
      return true;
    });
    return {
      status: 200,
      data: { count: uniq.length, total: uniq.length, items: uniq },
    };
  }

  return firstResp;
}

export function deleteSavedFilter(id) {
  return axios.delete(`${OPS_MGMT_SAVED_FILTERS_URL}/${id}`);
}

export function patchSavedFilter(id, payload) {
  return axios.patch(`${OPS_MGMT_SAVED_FILTERS_URL}/${id}`, payload, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });
}

export const createGroupBaselineUpdatRequest = async ({
  group,
  selectedDevices,
  serial,
  stopOnFailure,
  installSWDrivers,
  startTime,
  downgrade,
  powerOff,
  waitForReboot,
  rebootTimeout,
  serverKnownInstallFailuresPhase2Flag,
  isAnyDevicePoweredOn,
  cohesityFlag,
}) => {
  const successes = [];
  const errors = [];
  const authErrors = [];
  let apiCall = createJob;
  let requestBody = {
    resourceUri: group?.resourceUri,
    jobTemplateUri: FIRMWARE_BATCH_UPDATE_TEMPLATE_URI,
    data: {
      devices: selectedDevices.map(dev => dev.id),
      parallel: !serial,
      stopOnFailure,
      installSWDrivers,
    },
  };
  if (downgrade != null) {
    requestBody.data.downgrade = downgrade;
  }

  requestBody.data.powerOff = powerOff;
  requestBody.data.is_preflightcheck_skipped = false;

  if (cohesityFlag) {
    requestBody.data.wait_for_power_off_or_reboot = waitForReboot;
    requestBody.data.wait_for_power_off_or_reboot_timeout = rebootTimeout.value;
  }
  if (serverKnownInstallFailuresPhase2Flag) {
    requestBody.data.skip_blocklisted_components = true;
  }

  if (startTime) {
    const groupBaselineUpdateRequestBody = { ...requestBody };

    requestBody = {
      name: `${group?.name}`,
      purpose: 'GROUP_FW_UPDATE',
      description: JSON.stringify({
        is_any_device_powered_on: isAnyDevicePoweredOn,
      }),
      associatedResourceUri: group?.resourceUri,
      operation: {
        type: 'REST',
        method: 'POST',
        uri: SCHEDULE_JOB_URL,
        body: groupBaselineUpdateRequestBody,
      },
      schedule: {
        startAt: startTime,
      },
    };
    apiCall = createSchedule;
  }

  let response;
  try {
    response = await apiCall({
      ...requestBody,
    });
    if (response && response.status >= 200 && response.status <= 299) {
      successes.push(...selectedDevices);
    }
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      authErrors.push(...selectedDevices);
    } else {
      errors.push(...selectedDevices);
    }
  }

  return {
    successes,
    authErrors,
    errors,
  };
};

export const createServerIloFirmwareUpdateRequest = async (
  selectedDevices,
  iloFirmwareParams,
  startTime,
) => {
  const successes = [];
  const errors = [];
  const authErrors = [];

  const requestBody = {
    jobTemplateUri: UPDATE_ILO_FIRMWARE_SERVER_TEMPLATE_URI,
    data: {
      ilo_component: {},
    },
  };

  const scheduleRequestBody = {
    name: '',
    purpose: SERVER_ILO_FW_UPDATE,
    associatedResourceUri: '',
    operation: {
      type: 'REST',
      method: 'POST',
      uri: SCHEDULE_JOB_URL,
      body: requestBody,
    },
    schedule: {
      startAt: startTime,
    },
  };

  await Promise.allSettled(
    selectedDevices.map(device => {
      let payload;
      scheduleRequestBody.name = `${device?.name}`;
      requestBody.resourceUri = device.resourceUri;

      const iLOVersion = parseIloGeneration(device.iloFirmwareVersion_);
      const iLoComponent = iloFirmwareParams.find(
        x => x.comp_name === iLOVersion,
      );

      if (device.isIloFwUpdateTestVersionSupported_) {
        requestBody.data.ilo_component.filename = iLoComponent?.test_file_name;
        requestBody.data.ilo_component.version = iLoComponent?.test_version;
      } else {
        requestBody.data.ilo_component.filename = iLoComponent?.file_name;
        requestBody.data.ilo_component.version = iLoComponent?.version;
      }
      if (startTime) {
        scheduleRequestBody.associatedResourceUri = device.resourceUri;
        scheduleRequestBody.operation.body = requestBody;
        payload = scheduleRequestBody;
      } else {
        payload = requestBody;
      }
      return (
        startTime ? createSchedule({ ...payload }) : createJob({ ...payload })
      )
        .then(response => {
          if (response && response.status >= 200 && response.status <= 299) {
            successes.push(device);
          }
        })
        .catch(e => {
          if (e && e.response && e.response.status === 403) {
            authErrors.push(device);
          } else {
            errors.push(device);
          }
        });
    }),
  );

  return {
    successes,
    errors,
    authErrors,
  };
};

export const createServerBaselineUpdatRequest = async (
  selectedDevices,
  baseline,
  installSWDrivers,
  downgrade,
  powerOff,
  gen10Baseline,
  gen11Baseline,
  gen12Baseline,
  serverKnownInstallFailuresPhase2Flag,
  startTime,
  waitForReboot,
  rebootTimeout,
  isAnyDevicePoweredOn,
  cohesityFlag,
) => {
  const successes = [];
  const errors = [];
  const authErrors = [];

  let apiCall = createJob;

  const commonRequestBody = {
    jobTemplateUri: FIRMWARE_UPDATE_TEMPLATE_URI,
    data: {
      bundle_id: baseline.id,
      install_sw_drivers: installSWDrivers,
      downgrade,
      is_preflightcheck_skipped: false,
    },
  };

  if (cohesityFlag) {
    commonRequestBody.data.wait_for_power_off_or_reboot = waitForReboot;
    commonRequestBody.data.wait_for_power_off_or_reboot_timeout =
      rebootTimeout.value;
  }
  commonRequestBody.data.power_off = powerOff;

  if (serverKnownInstallFailuresPhase2Flag) {
    commonRequestBody.data.skip_blocklisted_components = true;
  }

  let toBundleName = '';

  await Promise.allSettled(
    selectedDevices.map(device => {
      let requestBody = { ...commonRequestBody };
      if (device.serverGeneration === GEN_10) {
        requestBody.data.bundle_id = gen10Baseline?.id;
        toBundleName = gen10Baseline?.displayName;
      } else if (device.serverGeneration === GEN_11) {
        requestBody.data.bundle_id = gen11Baseline?.id;
        toBundleName = gen11Baseline?.displayName;
      } else if (device.serverGeneration === GEN_12) {
        requestBody.data.bundle_id = gen12Baseline?.id;
        toBundleName = gen12Baseline?.displayName;
      }

      requestBody.resourceUri = device.resourceUri;

      if (cohesityFlag && startTime) {
        const updateRequestBody = { ...requestBody };

        requestBody = {
          name: `${device?.name}`,
          purpose: SERVER_FW_UPDATE,
          description: JSON.stringify({
            to_bundle: toBundleName,
            is_any_device_powered_on: isAnyDevicePoweredOn,
          }),
          associatedResourceUri: device.resourceUri,
          operation: {
            type: 'REST',
            method: 'POST',
            uri: SCHEDULE_JOB_URL,
            body: updateRequestBody,
          },
          schedule: {
            startAt: startTime,
          },
        };

        apiCall = createSchedule;
      }

      return apiCall({
        ...requestBody,
      })
        .then(response => {
          if (response && response.status >= 200 && response.status <= 299) {
            successes.push(device);
          }
        })
        .catch(e => {
          if (e && e.response && e.response.status === 403) {
            authErrors.push(device);
          } else {
            errors.push(device);
          }
        });
    }),
  );

  return {
    successes,
    errors,
    authErrors,
  };
};

export const createApplianceUpdateRequest = async (
  selectedAppliances,
  firmware,
  applianceUriIdFixFlag,
) => {
  const successes = [];
  const errors = [];
  const authErrors = [];

  const requestBody = {
    jobTemplateUri: OV_FIRMWARE_UPDATE_TEMPLATE_URI,
    data: {
      applianceFirmwareId: firmware.id,
    },
  };

  await Promise.allSettled(
    selectedAppliances.map(appliance =>
      createJob({
        ...requestBody,
        // eslint-disable-next-line max-len
        resourceUri: `${OPS_MGMT_OV_APPLIANCES_URI}/${applianceUriIdFixFlag ? appliance.id : appliance.deviceId}`,
      })
        .then(response => {
          if (response && response.status >= 200 && response.status <= 299) {
            successes.push(appliance);
          }
        })
        .catch(e => {
          if (e?.response && e.response.status === 403) {
            authErrors.push(appliance);
          } else {
            errors.push(appliance);
          }
        }),
    ),
  );

  return {
    successes,
    errors,
    authErrors,
  };
};

export const createGroupApplianceUpdateRequest = async ({
  group,
  selectedAppliances,
}) => {
  const successes = [];
  const errors = [];
  const authErrors = [];
  const apiCall = createJob;
  const requestBody = {
    resourceUri: group?.resourceUri,
    jobTemplateUri: APPLIANCE_BATCH_UPDATE_TEMPLATE_URI,
    data: {
      targetApplianceIds: selectedAppliances.map(
        appliance => appliance.deviceId,
      ),
    },
  };
  let response;
  try {
    response = await apiCall({
      ...requestBody,
    });
    if (response && response.status >= 200 && response.status <= 299) {
      successes.push(...selectedAppliances);
    }
  } catch (e) {
    if (e?.response && e.response.status === 403) {
      authErrors.push(...selectedAppliances);
    } else {
      errors.push(...selectedAppliances);
    }
  }

  return {
    successes,
    authErrors,
    errors,
  };
};

export const queryUserPermissions = () => axios.get(USER_PERMISSIONS_URL);

export const queryTenantUserPermissions = async tenantAcid =>
  axios.get(USER_PERMISSIONS_URL, {
    forceTenantAcid: tenantAcid,
  });

export const querySBAC = servers => {
  const payload = {
    operation_type: 'EDIT_SERVER_OPERATION',
    resources: servers.map(server => server.id),
  };

  return axios.post(SBAC_CHECK_URL, payload);
};

export function getPreferences() {
  return axios.get(`${PREFERENCES_URL}`);
}

export function getAccountType(acid) {
  return axios.get(`${MSP_URL}/${acid}`, {
    // define a custom config value for the interceptor to skip adding the tenant acid
    omitTenantAcid: true,
  });
}

export function getTenants(acid) {
  return axios.get(`${MSP_URL}/${acid}/tenants`, {
    // define a custom config value for the interceptor to skip adding the tenant acid
    omitTenantAcid: true,
  });
}

export const getLocationConfiguration = async serverId =>
  axios.get(`${SDI_CONFIG_URL}/${serverId}`);

export const getSdiLocations = () => axios.get(SDI_LOCATIONS_URL);

export function getAppliances(
  offset,
  limit,
  sort,
  searchString,
  filterQueries,
) {
  const params = {};
  const filters = filterQueries || [];

  offset && (params.offset = offset);
  limit && (params.limit = limit);
  sort && (params.sort = sort);
  searchString &&
    (params.search = `${searchString
      .trim()
      .replaceAll('\\', '\\\\')
      .replaceAll("'", "\\'")}`);

  if (filters && filters.length > 0) {
    if (filters instanceof Array) {
      params.filter = `(${filters.join(') and (')})`;
    } else {
      params.filter = filters;
    }
  }

  return axios.get(`${APPLIANCES_UIDOORWAY_URL}`, {
    params,
  });
}

export async function getAllAppliances(sort, searchString, filterQueries) {
  const appliances = [];
  const appliancesData = {};
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getAppliances(
    0,
    MAX_IRIS_COUNT,
    sort,
    searchString,
    filterQueries,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_IRIS_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getAppliances(
          i * MAX_IRIS_COUNT,
          MAX_IRIS_COUNT,
          sort,
          searchString,
          filterQueries,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.unshift(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(appliance => {
        appliances.push(appliance);
      });
    }
  });

  appliancesData.data = {
    items: appliances,
    offset: 0,
    total: appliances.length,
    count: appliances.length,
  };

  return appliancesData;
}

export async function getAllAppliancesInGroup(
  groupId,
  sort = null,
  filterQueries = [],
) {
  const localFilterQueries = [`group/id eq '${groupId}'`, ...filterQueries];
  const applianceData = await getAllAppliances(sort, null, localFilterQueries);
  const appliances = applianceData.data.items;
  return appliances;
}

export async function getAllSelectedAppliances(
  selected,
  unselected,
  allSelected,
  sort,
  searchString,
  filterQueries,
) {
  const appliances = [];
  const appliancesData = {};
  let fetches = [];
  let total = 0;
  let count = 0;

  if (
    allSelected !== true &&
    selected &&
    selected.length > 0 &&
    selected.length < MAX_FILTER_GET_ALL_APPLIANCES
  ) {
    const selectedQueries = [];
    selected.forEach(id => {
      selectedQueries.push(`'${id}'`);
    });
    let queriesToAdd = `${selectedQueries.join()}`;
    queriesToAdd = `id in (${queriesToAdd})`;
    queriesToAdd.length <= MAX_FILTER_GET_ALL_APPLIANCES &&
      filterQueries &&
      filterQueries.splice(0, filterQueries.length, queriesToAdd);
  }

  const firstFetch = await getAppliances(
    0,
    MAX_IRIS_COUNT,
    sort,
    searchString,
    filterQueries,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_IRIS_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getAppliances(
          i * MAX_IRIS_COUNT,
          MAX_IRIS_COUNT,
          sort,
          searchString,
          filterQueries,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.unshift(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(appliance => {
        if (isSelected(appliance.id, selected, unselected, allSelected)) {
          appliances.push(appliance);
        }
      });
    }
  });

  appliancesData.data = {
    items: appliances,
    offset: 0,
    total: appliances.length,
    count: appliances.length,
  };

  return appliancesData;
}

export function getApplianceSettingsTemplate(id) {
  return axios.get(`${OPS_MGMT_APPLIANCE_SETTINGS_TEMPLATE_URL}/${id}`);
}

export function getApplianceSettingsTemplateCounts() {
  return axios.get(APPLIANCE_SETTINGS_COUNTS_URL);
}

export function getApplianceById(id) {
  return axios.get(`${APPLIANCES_UIDOORWAY_URL}/${id}`);
}

export function getAppliancesStateCounts() {
  const fields = ['state'];
  return axios.get(APPLIANCES_COUNTS_UIDOORWAY_URL, { params: { fields } });
}

export function getAppliancesCounts() {
  const fields = ['state', 'healthSummary_', 'complianceState_'];
  return axios.get(APPLIANCES_COUNTS_UIDOORWAY_URL, { params: { fields } });
}

export function getAppliancesComplianceCounts() {
  const fields = ['complianceState_'];
  return axios.get(APPLIANCES_COUNTS_UIDOORWAY_URL, { params: { fields } });
}

export function getServersCountsByApplianceId(
  applianceId,
  serverEndpointsFlag,
) {
  if (serverEndpointsFlag) {
    return axios.get(
      `${OPS_MGMT_OV_SERVER_HW_URL}?limit=1&filter=eq(applianceId,'${applianceId}')`,
    );
  }
  return axios.get(
    `${OPS_MGMT_OV_RESOURCES_URL}?` +
      `limit=1&filter=and(${SERVERS_CATEGORY_FILTER},eq(applianceId,'${applianceId}'))`,
  );
}

export function getActivationKey(payload) {
  return axios.post(`${OPS_MGMT_OV_APPLIANCES_URL}/activation-key`, payload);
}

export function addAppliance(payload) {
  return axios.post(`${OPS_MGMT_OV_APPLIANCES_URL}`, payload);
}

export function checkApplianceSettingsCompliance(id) {
  return axios.post(`${OPS_MGMT_OV_APPLIANCES_URL}/${id}/compliance`, {});
}

export function getApplianceSettingsCompliance(id, complianceId) {
  return axios.get(
    `${OPS_MGMT_OV_APPLIANCES_URL}/${id}/compliance/${complianceId}`,
  );
}

export const removeAppliance = async id =>
  axios.delete(`${OPS_MGMT_OV_APPLIANCES_URL}/${id}`);

export const removeMgmtAppliance = async id =>
  axios.delete(`${OPS_MGMT_OV_APPLIANCES_URL}/${id}`);

export function updateJob(id, payload) {
  return axios.patch(`${JOBS_URL}/${id}`, payload);
}

// Get a page of appliance settings templates
export function getApplianceSettingsTemplates(
  offset,
  limit,
  sort,
  filterQueries,
) {
  const params = {};
  const filters = filterQueries || [];
  offset && (params.offset = offset);
  limit && (params.limit = limit);
  sort && (params.sort = sort);

  if (filters && filters.length > 0) {
    if (filters instanceof Array) {
      params.filter = `and(${filters.join(',')})`;
    } else {
      params.filter = filters;
    }
  }

  return axios.get(OPS_MGMT_APPLIANCE_SETTINGS_TEMPLATE_URL, {
    params,
  });
}

export function createApplianceSettingTemplates(payload) {
  return axios.post(OPS_MGMT_APPLIANCE_SETTINGS_TEMPLATE_URL, payload);
}

export function applyApplianceSettingsTemplate(id, payload) {
  return axios.post(
    `${OPS_MGMT_APPLIANCE_SETTINGS_TEMPLATE_URL}/${id}/apply`,
    payload,
  );
}

export function deleteApplianceSettingsTemplate(id) {
  return axios.delete(`${OPS_MGMT_APPLIANCE_SETTINGS_TEMPLATE_URL}/${id}`);
}

export const getInventory = async (
  applianceId,
  uri,
  serverEndpointsFlag,
  tenantAcid,
) => {
  if (serverEndpointsFlag) {
    return axios.get(`${OPS_MGMT_OV_SERVER_HW_URL}/${applianceId}${uri}`, {
      forceTenantAcid: tenantAcid,
    });
  }
  return axios.get(
    `${OPS_MGMT_OV_SERVER_HW_URL}?filter=and(eq(applianceId,'${applianceId}'),eq(uri,'${uri}'))`,
    { forceTenantAcid: tenantAcid },
  );
};

export const getInventoryStatus = async (filter, serverEndpointsFlag) => {
  const queryString = filter ? `?filter=${filter}` : '';
  if (serverEndpointsFlag) {
    return axios.get(
      `${OPS_MGMT_OV_SERVER_HW_COUNTS_URL}/status${queryString}`,
    );
  }
  return axios.get(`${OPS_MGMT_OV_HARDWARE_COUNTS_URL}/status${queryString}`);
};

export const getInventoriesByCategory = async (
  category,
  offset,
  limit,
  sort,
  searchString,
  filterQueries,
  serverEndpointsFlag,
) => {
  const params = {};
  offset && (params.offset = offset);
  limit && (params.limit = limit);
  sort && (params.sort = sort);
  searchString &&
    (params.search = `${searchString
      .trim()
      .replaceAll('\\', '\\\\')
      .replaceAll("'", "\\'")}`);
  const filters = filterQueries || [];

  if (filters && filters.length > 0) {
    params.filter = `and(${filters.join(',')})`;
  }
  if (serverEndpointsFlag) {
    return axios.get(`${OPS_MGMT_OV_SERVER_HW_URL}?`, { params });
  }

  return axios.get(`${OPS_MGMT_OV_RESOURCES_URL}?`, { params });
};

export async function getAllInventories(sort, filterQueries) {
  const resources = [];
  let fetches = [];
  let total = 0;
  let count = 0;
  const firstFetch = await getInventoriesByCategory(
    null,
    0,
    MAX_IRIS_INVENTORY_COUNT,
    sort,
    null,
    filterQueries,
    null,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_IRIS_INVENTORY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getInventoriesByCategory(
          null,
          i * MAX_IRIS_INVENTORY_COUNT,
          MAX_IRIS_INVENTORY_COUNT,
          sort,
          null,
          filterQueries,
          null,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.unshift(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(resource => {
        resources.push(resource);
      });
    }
  });
  return resources;
}

export const getServerProfile = async (
  applianceId,
  uri,
  serverEndpointsFlag,
) => {
  if (serverEndpointsFlag) {
    return axios.get(`${OPS_MGMT_OV_SERVER_PROFILE_URL}/${applianceId}${uri}`);
  }
  return axios.get(
    `${OPS_MGMT_OV_RESOURCES_URL}?filter=and(eq(applianceId,'${applianceId}'),eq(uri,'${uri}'))`,
  );
};

export const getProfileStatus = async (filter, serverEndpointsFlag) => {
  const queryString = filter ? `?filter=${filter}` : '';
  if (serverEndpointsFlag) {
    return axios.get(`${OPS_MGMT_OV_SERVER_PROFILE_COUNTS_URL}/status`);
  }
  return axios.get(`${OPS_MGMT_OV_PROFILE_COUNTS_URL}/status${queryString}`);
};

export const getServerProfiles = async (
  offset,
  limit,
  sort,
  searchString,
  filterQueries,
  serverEndpointsFlag,
) => {
  const params = {};
  offset && (params.offset = offset);
  limit && (params.limit = limit);
  sort && (params.sort = sort);
  searchString &&
    (params.search = `${searchString
      .trim()
      .replaceAll('\\', '\\\\')
      .replaceAll("'", "\\'")}`);
  const filters = filterQueries || [];

  if (filters && filters.length > 0) {
    params.filter = `and(${filters.join(',')})`;
  }
  if (serverEndpointsFlag) {
    return axios.get(`${OPS_MGMT_OV_SERVER_PROFILE_URL}?`, { params });
  }
  return axios.get(`${OPS_MGMT_OV_RESOURCES_URL}?`, { params });
};

export function getAllExternalServices(serviceType) {
  return axios.get(
    `${OPS_MGMT_EXTERNAL_SERVICE_URL}?filter=serviceType eq '${serviceType}'`,
  );
}

export function getExternalServiceById(id) {
  return axios.get(`${OPS_MGMT_EXTERNAL_SERVICE_URL}/${id}`);
}

export function getExternalServiceActivities(externalServiceId) {
  const NUM_ITEMS_IN_EXTERNAL_SERVICE_ACTIVITY_CARD = 10;
  const params = {};
  params.offset = 0;
  params.limit = NUM_ITEMS_IN_EXTERNAL_SERVICE_ACTIVITY_CARD;
  params.filter = "(associatedServerId eq '" + `${externalServiceId}')`;

  return axios.get(UI_DOORWAY_ACTIVITIES_URL, {
    params,
  });
}

export function removeExternalService(id) {
  return axios.delete(`${OPS_MGMT_EXTERNAL_SERVICE_URL}/${id}`);
}

export function synchFirmwareBaselineForVcenter(id) {
  return axios.post(`${OPS_MGMT_EXTERNAL_SERVICE_URL}/${id}/synchFirmware`, {});
}

export function testExternalService(id) {
  return axios.post(`${OPS_MGMT_EXTERNAL_SERVICE_URL}/${id}/test`, {});
}

export function editServiceNowConfig(
  id,
  incidentUrl,
  oauthUrl,
  clientId,
  clientSecret,
  refreshToken,
  refreshTokenExpiry,
) {
  const reqBody = {
    name: SERVICE_NOW_NAME,
    authentication: {
      clientId,
      clientSecret,
      refreshToken,
    },
    serviceData: {
      oauthUrl,
      incidentUrl,
      refreshTokenExpiryInDays: parseInt(refreshTokenExpiry, 10),
    },
  };
  return axios.patch(`${OPS_MGMT_EXTERNAL_SERVICE_URL}/${id}`, reqBody, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });
}

export function configureServiceNow(
  incidentUrl,
  oauthUrl,
  clientId,
  clientSecret,
  refreshToken,
  refreshTokenExpiry,
) {
  const reqBody = {
    name: SERVICE_NOW_NAME,
    serviceType: SERVICE_NOW_TYPE,
    authenticationType: OAUTH,
    authentication: {
      clientId,
      clientSecret,
      refreshToken,
    },
    serviceData: {
      oauthUrl,
      incidentUrl,
      refreshTokenExpiryInDays: parseInt(refreshTokenExpiry, 10),
    },
  };
  return axios.post(OPS_MGMT_EXTERNAL_SERVICE_URL, reqBody);
}

export function configureArubaCentral(
  state,
  nbUrl,
  clientId,
  clientSecret,
  refreshToken,
) {
  const reqBody = {
    name: ARUBA_CENTRAL_NAME,
    serviceType: ARUBA_CENTRAL_TYPE,
    authenticationType: OAUTH,
    state,
    authentication: {
      clientId,
      clientSecret,
      refreshToken,
    },
    serviceData: { nbUrl },
  };
  return axios.post(OPS_MGMT_EXTERNAL_SERVICE_URL, reqBody);
}

export function editArubaCentralConfig(
  id,
  state,
  nbUrl,
  clientId,
  clientSecret,
  refreshToken,
) {
  const reqBody = {
    authentication: { clientId },
    serviceData: { nbUrl },
    state,
  };
  if (clientSecret) {
    reqBody.authentication.clientSecret = clientSecret;
  }
  if (refreshToken) {
    reqBody.authentication.refreshToken = refreshToken;
  }
  return axios.patch(`${OPS_MGMT_EXTERNAL_SERVICE_URL}/${id}`, reqBody, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });
}

export const getDsccIntegration = async () => getAllExternalServices(DSCC_TYPE);

export function editDsccConfig(id, formValues) {
  const reqBody = {
    authentication: {
      clientId: formValues?.clientId,
      clientSecret: formValues?.clientSecret,
    },
    state: formValues?.state,
  };

  if (!formValues?.clientId || !formValues?.clientSecret) {
    delete reqBody?.authentication;
  }
  return axios.patch(`${OPS_MGMT_EXTERNAL_SERVICE_URL}/${id}`, reqBody, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });
}

export function configureDscc(formValues) {
  const reqBody = {
    name: DSCC_NAME,
    serviceType: DSCC_TYPE,
    authenticationType: OAUTH,
    authentication: {
      clientId: formValues?.clientId,
      clientSecret: formValues?.clientSecret,
    },
    state: formValues?.state || 'ENABLED',
  };

  return axios.post(OPS_MGMT_EXTERNAL_SERVICE_URL, reqBody);
}
export function clearUtilizationAlert(alert) {
  return axios.post(OPS_MGMT_SERVERS_V1_POST_PATCH_DEL_URL, alert);
}
export function configureMetricsData(
  flag,
  computeOpsMgmtStableApiMetricFlag,
  utilizationAlertsFlag,
  powerThresholdAlerts,
  powerUtilizationThreshold,
  grouplist,
) {
  const reqBody = {
    metricsCollection: flag ? 'SYSTEM_SCHEDULED' : 'ON_DEMAND',
  };
  if (utilizationAlertsFlag) {
    reqBody.powerThresholdAlerts = powerThresholdAlerts;
    reqBody.powerUtilizationThreshold = powerUtilizationThreshold;
    reqBody.alertsResource = { group: grouplist };
  }

  if (computeOpsMgmtStableApiMetricFlag) {
    return axios.post(`${METRICS_DATA_URL_V1}`, reqBody);
  }
  return axios.post(`${OPS_MGMT_METRICS_DATA_URL}`, reqBody);
}

export function getMetricsData(computeOpsMgmtStableApiMetricFlag) {
  if (computeOpsMgmtStableApiMetricFlag) {
    return axios.get(`${METRICS_DATA_URL_V1}`);
  }
  return axios.get(`${OPS_MGMT_METRICS_DATA_URL}`);
}

export function getMetricsDataRange(reportType) {
  return axios.get(`${METRICS_DATA_RANGE}?reportType=${reportType}`);
}

export function getSustainabilityGraphDetails(
  startDate,
  endDate,
  projectionDays,
) {
  const params = {};
  if (startDate) params['start-date'] = startDate;
  if (endDate) params['end-date'] = endDate;
  if (projectionDays) params['projection-days'] = projectionDays;

  return axios.get(`${ENERGY_OVER_TIME}`, { params });
}

export function getAllServerUtilizationAverageDetails(startDate, endDate) {
  const params = {};
  if (startDate) params['start-date'] = startDate;
  if (endDate) params['end-date'] = endDate;
  params['metric-type'] = 'CPU_UTILIZATION';

  return axios.get(`${UTILIZATION_AVERAGE_OVER_TIME}`, { params });
}
export function updateMetricsData(
  flag,
  id,
  computeOpsMgmtStableApiMetricFlag,
  utilizationAlertsFlag,
  powerThresholdAlerts,
  powerUtilizationThreshold,
  grouplist,
) {
  const reqBody = {
    metricsCollection: flag ? 'SYSTEM_SCHEDULED' : 'ON_DEMAND',
  };
  if (utilizationAlertsFlag) {
    reqBody.powerThresholdAlerts = powerThresholdAlerts;
    reqBody.powerUtilizationThreshold = powerUtilizationThreshold;
    reqBody.alertsResource = { groups: grouplist };
  }

  if (computeOpsMgmtStableApiMetricFlag) {
    return axios.patch(`${METRICS_DATA_URL_V1}/${id}`, reqBody, {
      headers: { 'content-type': 'application/merge-patch+json' },
    });
  }

  return axios.patch(`${OPS_MGMT_METRICS_DATA_URL}/${id}`, reqBody, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });
}

export async function getApplianceAlertsV2(applianceId, offset, limit) {
  const params = {};
  params.offset = offset || 0;
  params.limit = limit || MAX_DOORWAY_COUNT;
  params.sort = 'severity asc';
  params.filter = "(severity in ('CRITICAL','WARNING'))";

  return axios.get(
    `${DOORWAY_URL}/compute/v2/appliances/${applianceId}/alerts`,
    {
      params,
    },
  );
}

export async function getAllApplianceAlertsV2(applianceId) {
  const alerts = [];
  let response = {};
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getApplianceAlertsV2(
    applianceId,
    0,
    MAX_DOORWAY_COUNT,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getApplianceAlertsV2(
          applianceId,
          i * MAX_DOORWAY_COUNT,
          MAX_DOORWAY_COUNT,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.unshift(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(alert => {
        alerts.push(alert);
      });
    }
  });

  // Re-wrap for code expecting axios response
  response = {
    data: {
      offset: 0,
      count: alerts.length,
      total: alerts.length,
      items: alerts,
    },
  };

  return response;
}

export async function getHardwareAlerts(
  applianceId,
  hardwareUri,
  offset,
  limit,
) {
  const params = {};
  params.offset = offset || 0;
  params.limit = limit || MAX_IRIS_INVENTORY_COUNT;
  params.sort = 'status:asc';
  // eslint-disable-next-line max-len
  params.filter = `and(eq(category,'alerts'),in(state,'Active','Locked'),in(status,'Critical','Warning'),eq(applianceId,'${applianceId}'),eq(attributes.resourceUri,'${hardwareUri}'))`;

  return axios.get(`${OPS_MGMT_OV_RESOURCES_URL}`, { params });
}

export async function getHardwareAlertsV2(hardwareId, offset, limit) {
  const params = {};
  params.offset = offset || 0;
  params.limit = limit || MAX_DOORWAY_COUNT;
  params.sort = 'severity asc';
  params.filter = "(severity in ('CRITICAL','WARNING'))";

  return axios.get(`${DOORWAY_URL}/compute/v2/servers/${hardwareId}/alerts`, {
    params,
  });
}

export async function getAllHardwareAlertsV2(hardwareId) {
  const alerts = [];
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getHardwareAlertsV2(
    hardwareId,
    0,
    MAX_DOORWAY_COUNT,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getHardwareAlertsV2(
          hardwareId,
          i * MAX_DOORWAY_COUNT,
          MAX_DOORWAY_COUNT,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.unshift(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(alert => {
        alerts.push(alert);
      });
    }
  });

  return alerts;
}

export async function getAllHardwareAlerts(applianceId, hardwareUri) {
  const alerts = [];
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getHardwareAlerts(
    applianceId,
    hardwareUri,
    0,
    MAX_IRIS_INVENTORY_COUNT,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_IRIS_INVENTORY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getHardwareAlerts(
          applianceId,
          hardwareUri,
          i * MAX_IRIS_INVENTORY_COUNT,
          MAX_IRIS_INVENTORY_COUNT,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.unshift(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(alert => {
        alerts.push(alert);
      });
    }
  });

  return alerts;
}

export const getServerProfileData = async (
  applianceId,
  serverEndpointsFlag,
  serverHardwareUri,
) => {
  if (serverEndpointsFlag) {
    return axios.get(
      // eslint-disable-next-line max-len
      `${
        OPS_MGMT_OV_SERVER_PROFILE_URL
        // eslint-disable-next-line max-len
      }?filter=and(eq(applianceId,'${applianceId}'),eq(attributes.serverHardwareUri,'${serverHardwareUri}'))`,
    );
  }
  return axios.get(
    // eslint-disable-next-line max-len
    `${
      OPS_MGMT_OV_RESOURCES_URL
      // eslint-disable-next-line max-len
    }?filter=and(eq(applianceId,'${applianceId}'),eq(attributes.serverHardwareUri,'${serverHardwareUri}'),eq(category,'server-profiles'))`,
  );
};

export const getJobById = async id => axios.get(`${JOBS_URL}/${id}`);

export const getJobByIdV1B2 = async id =>
  axios.get(`${COMPUTE_OPS_MGMT_JOBS_URL}/${id}`);

export const getJobs = async filter => {
  const queryString = filter ? `?filter=${filter}` : '';
  return axios.get(`${JOBS_URL}${queryString}`);
};

export const getJobsV1B2 = async filter => {
  const queryString = filter ? `?filter=${filter}` : '';
  return axios.get(`${COMPUTE_OPS_MGMT_JOBS_URL}${queryString}`);
};

export const getJobsV1B2WithLimit = async (
  filter = '',
  limit = 25,
  offset = 0,
) => {
  const params = new URLSearchParams();
  if (filter) params.append('filter', filter);
  params.append('limit', limit);
  params.append('offset', offset);
  const queryString = params.toString() ? `?${params.toString()}` : '';

  const url = `${COMPUTE_OPS_MGMT_JOBS_URL}${queryString}`;

  return axios.get(url);
};

export const getAllJobsV1B2WithLimit = async (filter = '', limit = 25) =>
  getAllPages(
    offset => getJobsV1B2WithLimit(filter, limit, offset),
    limit,
  ).then(jobs =>
    // getAllPages returns an array - re-wrap for code expecting axios response
    ({
      data: {
        offset: 0,
        count: jobs.length,
        total: jobs.length,
        items: jobs,
      },
    }),
  );

export const createViewHardwareInventoryJob = (
  hardwareId,
  applianceId,
  togglePowerOn,
) => {
  const jobTemplateUri = SERVER_INVENTORY_TEMPLATE_URI;
  const requestBody = {
    resourceUri: `${OPS_MGMT_OV_APPLIANCES_URI}/${applianceId}/rest/server-hardware/${hardwareId}`,
    jobTemplateUri: `${jobTemplateUri}`,
    data: {
      power_on_server: togglePowerOn,
      is_activity_message_needed: true,
    },
  };
  requestBody.resourceUri = `/compute-ops-mgmt/v1beta2/servers/${hardwareId}`;
  const promise = createJobV1B2({
    ...requestBody,
  });

  return promise;
};

export const createViewServerInventoryJob = (serverId, togglePowerOn) => {
  const requestBody = {
    resourceUri: `/compute-ops-mgmt/v1beta2/servers/${serverId}`,
    jobTemplateUri: `${SERVER_INVENTORY_TEMPLATE_URI}`,
    data: {
      power_on_server: togglePowerOn,
      is_activity_message_needed: true,
    },
  };
  const promise = createJobV1B2({
    ...requestBody,
  });

  return promise;
};

export const getExistingNetworkConnectivityJob = async serverId => {
  // replace the '+' in serverId with url encoding %2B
  const serverIdEncoded = serverId.replace(/\+/g, '%2B');
  const networkConnectivityJobTemplateId = getIdFromUri(
    NETWORK_CONNECTIVITY_TEMPLATE_URI,
  );
  // eslint-disable-next-line max-len
  const filter = `(associated_resource_uri='/api/compute/v1/servers/${serverIdEncoded}' and job_template_id='${networkConnectivityJobTemplateId}' and (state='${JOB_STATE.PENDING.toLowerCase()}' or state='${JOB_STATE.RUNNING.toLowerCase()}'))`;
  return getJobs(filter);
};

export const createViewNeworkConnectivityJob = serverId =>
  createJob({
    resourceUri: `/api/compute/v1/servers/${serverId}`,
    jobTemplateUri: `${NETWORK_CONNECTIVITY_TEMPLATE_URI}`,
  });

export const createCheckExternalStorageComplianceJob = groupUri =>
  createJob({
    resourceUri: groupUri,
    jobTemplateUri: EXTERNAL_STORAGE_COMPLIANCE_TEMPLATE_URI,
  });

export const getExternalStorageCompliance = (groupId, esComplianceFlag) =>
  axios.get(
    // eslint-disable-next-line max-len
    `${esComplianceFlag ? OPS_MGMT_GROUPS_V1_URL : GROUPS_URL_V2}/${groupId}/external-storage-compliance`,
  );

export const getOVSubscription = () =>
  axios.get(`${DOORWAY_URL}/compute/v1/oneview-subscriptions`);

export async function getDeviceSubscriptions(limit, filters, sort) {
  const params = {};
  if (limit) params.limit = limit;
  if (filters) params.filter = `(${filters.join(') and (')})`;
  if (sort) params.sort = sort;
  return axios.get(`${DOORWAY_URL}/compute/v1/subscriptions`, { params });
}

// for offset based call
export function getSubscriptionKeys(
  offset,
  limit,
  sort,
  searchString,
  filterQueries,
) {
  const params = {};
  const filters = filterQueries || [];

  offset && (params.offset = offset);
  limit && (params.limit = limit);
  sort && (params.sort = sort);
  searchString &&
    (params.search = `${searchString
      .trim()
      .replaceAll('\\', '\\\\')
      .replaceAll("'", "\\'")}`);

  if (filters && filters.length > 0) {
    if (filters instanceof Array) {
      params.filter = `(${filters.join(') and (')})`;
    } else {
      params.filter = filters;
    }
  }

  return axios.get(SUBSCRIPTION_URL, { params });
}

export async function getAllSubscriptionKeys(
  sort,
  searchString,
  filterQueries,
) {
  const subscriptionKeys = [];
  const subscriptionKeysData = {};
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getSubscriptionKeys(
    0,
    MAX_DOORWAY_COUNT,
    sort,
    searchString,
    filterQueries,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getSubscriptionKeys(
          i * MAX_DOORWAY_COUNT,
          MAX_DOORWAY_COUNT,
          sort,
          searchString,
          filterQueries,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.unshift(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(item => {
        subscriptionKeys.push(item);
      });
    }
  });

  subscriptionKeysData.data = {
    items: subscriptionKeys,
    offset: 0,
    total: subscriptionKeys.length,
    count: subscriptionKeys.length,
  };

  return subscriptionKeysData;
}

export * from './api/reports';

export function createReport(payload) {
  return axios.post(COMPUTE_OPS_MGMT_REPORTS_URL, payload);
}

export const getApplianceFilterInfo = (next, limit) => {
  const params = {};
  if (limit) params.limit = limit;
  if (next) params.next = next;
  return axios.get(`${DOORWAY_URL}/compute/v1/appliances`, {
    params,
  });
};

export async function getAllApplianceFilterInfo() {
  const applianceFilterInfo = [];
  let first = true;
  let next = null;
  let fetch = null;

  while (first || next) {
    // Must wait for each response to get the
    // next parameter for the next request.
    // eslint-disable-next-line no-await-in-loop
    fetch = await getApplianceFilterInfo(next, MAX_DOORWAY_COUNT).catch(e => {
      throw e;
    });
    next = fetch?.data?.next ? fetch.data.next : null;
    if (fetch?.data?.items) {
      fetch.data.items.forEach(applianceInfo => {
        applianceFilterInfo.push(applianceInfo);
      });
    }
    first = false;
  }
  return {
    data: {
      next: null,
      count: applianceFilterInfo.length,
      items: applianceFilterInfo,
    },
  };
}

export async function getOvSettingsByApplianceId(applianceId) {
  return axios.get(
    `${OV_APPLIANCE_SETTINGS_URL}?filter=and(eq(applianceId,'${applianceId}'))`,
  );
}

export async function getDeviceActivationKey(
  subscriptionKey,
  keyExpireDurationInHours,
  serverTags,
  serverLocation,
  tokenBasedOnboardingPhase2Flag,
  secureGatewayFlag,
  applianceId,
) {
  // expiresIn: expiration of activation key in hours
  const payload = {
    expirationInHours: keyExpireDurationInHours,
    subscriptionKey,
  };

  if (tokenBasedOnboardingPhase2Flag) {
    payload.location = serverLocation;
    payload.tags = serverTags;
  }
  if (secureGatewayFlag && applianceId && applianceId !== DIRECT_CONNECT) {
    payload.gatewayApplianceId = `gateway+${applianceId}`;
  }

  return axios.post(SERVER_ACTIVATION_TOKEN_URL, payload);
}
export async function getPinBasedDeviceActivationKey(
  subscriptionKey,
  connectionType,
  keyExpireDurationInHours,
  applianceId,
) {
  // expiresIn: expiration of activation key in hours
  const payload = {
    expirationInHours: keyExpireDurationInHours,
    targetDevice: TARGET_DEVICE_ILO,
  };

  if (subscriptionKey) {
    payload.subscriptionKey = subscriptionKey;
  }
  // check for connection type before adding applianceUri
  if (connectionType === SECURE_GATEWAY && applianceId) {
    payload.applianceUri = `${GATEWAY_APPLIANCE_URL}+${applianceId}`;
  }

  return axios.post(ACTIVATION_KEYS_URL, payload);
}

export async function getGatewayActivationKey(
  pinBasedOnboardingFlag,
  keyExpireDurationInHours,
) {
  const payload = {};
  if (!pinBasedOnboardingFlag) {
    // expiresIn: expiration of activation key in hours
    payload.expiresIn = keyExpireDurationInHours;
    payload.applianceType = 'gateway';
  } else {
    payload.expirationInHours = keyExpireDurationInHours;
    payload.targetDevice = TARGET_DEVICE_SECURE_GATEWAY;
  }

  const url = pinBasedOnboardingFlag
    ? ACTIVATION_KEYS_URL
    : SECURE_GATEWAY_ACTIVATION_KEY_URL;

  return axios.post(url, payload);
}

export async function addServer({
  host,
  username,
  password,
  certificateFingerprint,
  applianceId,
}) {
  const payload = {
    hardware: {
      bmc: {
        userDefined: {
          host,
          username,
          password,
          certificateFingerprint,
        },
      },
    },
    appliance: {
      applianceId,
    },
  };
  const response = await axios.post(
    `${OPS_MGMT_SERVERS_V1_POST_PATCH_DEL_URL}`,
    payload,
  );

  return response.data;
}

export const removeServer = async id =>
  axios.delete(`${OPS_MGMT_SERVERS_V1_POST_PATCH_DEL_URL}/${id}`);

export async function configureVcenter(reqBody) {
  return axios.post(OPS_MGMT_EXTERNAL_SERVICE_URL, reqBody);
}

export async function editVcenterConfig(id, reqBody) {
  return axios.patch(`${OPS_MGMT_EXTERNAL_SERVICE_URL}/${id}`, reqBody);
}

export async function getActivationKeys(filterString) {
  const params = {};
  filterString && (params.filter = filterString);

  return axios.get(ACTIVATION_KEYS_URL, { params });
}

export async function deleteActivationKey(id) {
  return axios.delete(`${ACTIVATION_KEYS_URL}/${id}`);
}

export async function getOvServerTemplates(offset, count, filterString) {
  const params = {};
  offset && (params.offset = offset);
  count && (params.limit = count);
  filterString && (params.filter = filterString);

  return axios.get(`${OPS_MGMT_OV_SERVER_TEMPLATES_URL}`, {
    params,
  });
}

export async function getAllOvServerTemplates(applianceId) {
  const serverTemplates = [];
  let fetches = [];
  let total = 0;
  let count = 0;
  const localFilterString = `eq(applianceId,'${applianceId}')`;

  const firstFetch = await getOvServerTemplates(
    0,
    MAX_IRIS_INVENTORY_COUNT,
    localFilterString,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_IRIS_INVENTORY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getOvServerTemplates(
          i * MAX_IRIS_INVENTORY_COUNT,
          MAX_IRIS_INVENTORY_COUNT,
          localFilterString,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.push(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(serverTemplate => {
        serverTemplates.push(serverTemplate);
      });
    }
  });

  return serverTemplates;
}

export const getOvServerTemplatesByUri = async serverTemplateUri =>
  axios.get(
    // eslint-disable-next-line max-len
    `${OPS_MGMT_OV_SERVER_TEMPLATES_URL}?filter=and(eq(uri,'${serverTemplateUri}'))`,
  );

export const getOvServerTemplatesByUris = async serverTemplateUris => {
  let uris = '';
  serverTemplateUris.forEach(uri => {
    uris += `'${uri}',`;
  });
  uris = uris.slice(0, -1);
  return axios.get(
    // eslint-disable-next-line max-len
    `${OPS_MGMT_OV_SERVER_TEMPLATES_URL}?filter=in(uri, ${uris})`,
  );
};

export const updateOneViewServerTemplatesJob = (
  settingsId,
  templatesList,
  stableSettingFlagV1,
) => {
  const url = stableSettingFlagV1
    ? OPS_MGMT_SETTING_V1_URI_PREFIX
    : OPS_MGMT_SETTING_URI_PREFIX;
  const settingsUri = `${url}/${settingsId}`;
  const requestBody = {
    jobTemplateUri: `${OV_SERVER_TEMPLATE_UPDATE_URI}`,
    resourceUri: settingsUri,
    data: {
      templates_data: templatesList,
    },
  };
  const promise = createJobV1B2({
    ...requestBody,
  });
  return promise;
};

export const getExistingOneViewServerTemplateUpdateJob = async (
  settingsId,
  stableSettingFlagV1,
) => {
  const ovServerTemplateUpdateJobTemplateId = getIdFromUri(
    OV_SERVER_TEMPLATE_UPDATE_URI,
  );
  const url = stableSettingFlagV1
    ? OPS_MGMT_SETTING_V1_URI_PREFIX
    : OPS_MGMT_SETTING_URI_PREFIX;
  const settingsUri = `${url}/${settingsId}`;

  const filter =
    // eslint-disable-next-line max-len
    `(associated_resource_uri='${settingsUri}' and job_template_id='${ovServerTemplateUpdateJobTemplateId}' and (state='${JOB_STATE.PENDING.toLowerCase()}' or state='${JOB_STATE.RUNNING.toLowerCase()}'))`;
  const promise = getJobsV1B2(filter);
  return promise;
};

export async function getServerLocations() {
  return axios.get(`${DOORWAY_URL}/compute/v1/locations`);
}

export const getExistingServerVolumeDetailsJob = async serverId => {
  const encodedServerId = serverId.replace(/\+/g, '%2B');
  const serverVolumeDetailsJobTemplateId = getIdFromUri(
    SERVER_VOLUME_DETAILS_TEMPALTE_URI,
  );
  // eslint-disable-next-line max-len
  const filter = `(associated_resource_uri='/compute-ops-mgmt/v1beta2/servers/${encodedServerId}' and job_template_id='${serverVolumeDetailsJobTemplateId}' and (state='${JOB_STATE.PENDING.toLowerCase()}' or state='${JOB_STATE.RUNNING.toLowerCase()}'))`;
  const promise = getJobsV1B2(filter);
  return promise;
};

export const createViewServerVolumeDetailsJob = serverId => {
  const requestBody = {
    resourceUri: `/compute-ops-mgmt/v1beta2/servers/${serverId}`,
    jobTemplateUri: `${SERVER_VOLUME_DETAILS_TEMPALTE_URI}`,
  };
  const promise = createJobV1B2({
    ...requestBody,
  });

  return promise;
};

export const getServerVolumeDetails = (serverId, stableServerAPIFlag) => {
  let url = `${OPS_MGMT_SERVERS_POST_PATCH_DEL_URL}/${serverId}/external-storage-details`;
  if (stableServerAPIFlag) {
    url = `${OPS_MGMT_SERVERS_V1_POST_PATCH_DEL_URL}/${serverId}/external-storage-details`;
  }
  return axios.get(url);
};
export const getOVLocations = (limit, filters, next) => {
  const params = {};
  if (limit) params.limit = limit;
  if (filters) params.filter = `(${filters.join(') and (')})`;
  if (next) params.next = next;
  return axios.get(`${DOORWAY_URL}/compute/v1/locations`, {
    params,
  });
};

export const deleteOVLocations = (serverStr, locationId) => {
  const headers = {
    headers: { 'Content-Type': 'application/json' },
  };
  // eslint-disable-next-line max-len
  const url = `${COM_API_ROOT_URL}/compute-ops-mgmt/v1beta1/server-locations/${locationId}/servers?id=${serverStr}`;
  return axios.delete(url, {}, headers);
};

export const setOVLocations = (servers, location) => {
  const payload = { servers };
  const headers = {
    headers: { 'Content-Type': 'application/json' },
  };
  // eslint-disable-next-line max-len
  const url = `${COM_API_ROOT_URL}/compute-ops-mgmt/v1beta1/server-locations/${location.id}/servers`;
  return axios.post(url, payload, headers);
};

export function getCustomers(
  mspAcid,
  offset,
  count,
  sort,
  searchString,
  filterQueries,
) {
  const params = {};
  const filters = filterQueries || [];

  offset && (params.offset = offset);
  count && (params.limit = count);
  sort && (params.sort = sort);
  searchString &&
    (params.search = `${searchString
      .trim()
      .replaceAll('\\', '\\\\')
      .replaceAll("'", "\\'")}`);

  if (filters && filters.length > 0) {
    if (filters instanceof Array) {
      params.filter = `(${filters.join(') and (')})`;
    } else {
      params.filter = filters;
    }
  }

  return axios.get(`${CUSTOMERS_URL}/${mspAcid}/tenants`, {
    params,
  });
}

export function getCustomerCounts(mspAcid) {
  return axios.get(`${CUSTOMERS_URL}/${mspAcid}/tenants/counts`);
}

export function getCustomersData(
  format,
  mspAcid,
  sort,
  searchString,
  filterQueries,
) {
  const params = {};
  const filters = filterQueries || [];

  format && (params.format = format);
  sort && (params.sort = sort);
  searchString &&
    (params.search = `${searchString
      .trim()
      .replaceAll('\\', '\\\\')
      .replaceAll("'", "\\'")}`);

  if (filters && filters.length > 0) {
    if (filters instanceof Array) {
      params.filter = `(${filters.join(') and (')})`;
    } else {
      params.filter = filters;
    }
  }

  return axios.get(`${CUSTOMERS_URL}/${mspAcid}/data`, {
    params,
  });
}

export function createCopyServerTemplatesJob(groupId, selectedDevices) {
  const url = OPS_MGMT_GROUPS_V1_URI_PREFIX;

  const groupUri = `${url}/${groupId}`;

  const requestBody = {
    resourceUri: groupUri,
    jobTemplateUri: COPY_OV_SERVER_TEMPLATE_URI,
    data: {
      targetApplianceIds: selectedDevices.map(dev => dev.deviceId),
    },
  };

  const promise = createJobV1B2({
    ...requestBody,
  });
  return promise;
}

export function createApplyApplianceSettingsJob(
  groupId,
  selectedDevices,
  applianceUriDiscrepancyFlag,
) {
  const url = OPS_MGMT_GROUPS_V1_URI_PREFIX;

  const groupUri = `${url}/${groupId}`;

  const requestBody = {
    resourceUri: groupUri,
    jobTemplateUri: APPLY_OV_APPLIANCE_SETTINGS_TEMPLATE_URI,
    data: {
      targetApplianceIds: selectedDevices.map(dev =>
        applianceUriDiscrepancyFlag ? dev.id : dev.deviceId,
      ),
    },
  };
  const promise = createJobV1B2({
    ...requestBody,
  });
  return promise;
}

export function createRefreshApplianceSettingsJob(
  appliance,
  uriDiscrepancyFlag,
) {
  const requestBody = {
    resourceUri: uriDiscrepancyFlag
      ? appliance?.resourceUri
      : `${OPS_MGMT_OV_APPLIANCES_URI}/${appliance?.deviceId}`,
    jobTemplateUri: REFRESH_APPLIANCE_SETTINGS_TEMPLATE_URI,
  };
  const promise = createJobV1B2({
    ...requestBody,
  });
  return promise;
}

export function getLastAHSUploadDetails(ahsPublicApiFlag) {
  const params = {};
  params.sort = ahsPublicApiFlag
    ? 'parsingStatus/statusModifiedAt desc'
    : 'analysis_update_time desc';
  params.limit = 1;
  return axios.get(AHS_FILES_URL, { params });
}

export function createAHSFileResource(fileName) {
  const requestBody = {
    fileName,
  };
  return axios.post(AHS_FILES_URL, requestBody);
}

export function uploadFileToS3(preSignedUrl, fileObject, ahsPublicApiFlag) {
  if (ahsPublicApiFlag) {
    const formData = new FormData();
    const { parameters } = preSignedUrl;
    parameters.forEach(item => {
      formData.append(item.name, item.value);
    });

    formData.append('file', fileObject);

    return axios.post(preSignedUrl.baseUrl, formData, {
      omitAuth: true,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }
  return axios.put(preSignedUrl, fileObject, {
    omitAuth: true,
    headers: {
      'content-type': 'application/octet-stream',
    },
  });
}

export function patchAHSFileResource(fileUUID, requestBody) {
  return axios.patch(`${AHS_FILES_URL}/${fileUUID}`, requestBody, {
    headers: { 'content-type': 'application/merge-patch+json' },
  });
}

export function initiateAHSAnalysis(
  fileUUID,
  fileName,
  components,
  ahsPublicApiFlag,
) {
  let requestBody = {};
  if (ahsPublicApiFlag) {
    requestBody = {
      components,
    };
  } else {
    requestBody = {
      fileName,
      method: 'non-connected',
    };
  }
  return axios.post(`${AHS_FILES_URL}/${fileUUID}/parse`, requestBody);
}

export function getServerDataFromAHS(filePath, id, ahsPublicApiFlag) {
  let params;
  if (ahsPublicApiFlag) {
    params = {
      filename: filePath,
    };
  } else {
    params = {
      jsonpath: filePath,
    };
  }
  return ahsPublicApiFlag
    ? axios.get(`${AHS_FILES_URL}/${id}/download`, { params })
    : axios.get(`${AHS_FILES_URL}/${id}/data`, { params });
}

export function getCertificateDataFromServer(filePath, id) {
  const params = { filename: filePath };
  const CERTIFICATE_API_URL = `${OPS_MGMT_OV_APPLIANCES_URL}/${id}/certificate`;
  return axios.get(`${CERTIFICATE_API_URL}`, { params });
}

export function getServerInsights() {
  return axios.get(SERVER_INSIGHTS_URL);
}

export function getInsightsCount() {
  const url = `${SERVERS_COUNTS_URL}?fields[]=insights_ `;
  return axios.get(url);
}

export function cancelPendingJob(id) {
  return axios.delete(`${OPS_MGMT_JOBS_V1B3_URL}/${id}`);
}

export function getServerSSO(id, ssoUri) {
  return axios.get(`${COM_API_ROOT_URL}${ssoUri}`);
}

export function exportSustainabilityReport(
  startDate,
  endDate,
  projectionDays,
  valueType,
) {
  const params = {};
  if (startDate) params['start-date'] = startDate;
  if (projectionDays) params['projection-days'] = projectionDays;
  if (endDate) params['end-date'] = endDate;

  const headers =
    valueType === 'CSV'
      ? { accept: 'text/csv, text/plain, */*' }
      : { accept: 'application/json, text/plain, */*' };

  return axios.get(`${ENERGY_DATA_EXPORT_URL}`, {
    params,
    headers,
  });
}

export function getSustainabilityAnalysisTableData(
  startDate,
  projectionDays,
  excludedServers,
  aggregateBy,
  exportType,
  endDate,
  offset,
  count,
  sort,
  filter,
) {
  const params = {};
  params.offset = offset || 0;
  count && (params.limit = count);
  sort && (params.sort = sort);
  if (startDate) params['start-date'] = startDate;
  if (excludedServers) params['excluded-servers'] = excludedServers;
  if (projectionDays) params['projection-days'] = projectionDays;
  if (aggregateBy) params['aggregate-by'] = aggregateBy;
  if (exportType) params['export-as'] = exportType;
  if (endDate) params['end-date'] = endDate;
  if (filter) params.filter = filter;

  return axios.get(`${ENERGY_BY_ENTITY_URL}`, { params });
}

export function exportServerUtilizationReport(
  startDate,
  projectionDays,
  endDate,
  valueType,
  metricType,
) {
  const params = {};
  if (startDate) params['start-date'] = startDate;
  if (projectionDays) params['projection-days'] = projectionDays;
  if (metricType) params['metric-type'] = metricType;
  if (endDate) params['end-date'] = endDate;

  const headers =
    valueType === 'CSV'
      ? { accept: 'text/csv, text/plain, */*' }
      : { accept: 'application/json, text/plain, */*' };

  return axios.get(`${UTILIZATION_DATA_EXPORT_URL}`, {
    params,
    headers,
  });
}

export function getServerUtilizationTableData(
  startDate,
  projectionDays,
  excludedServers,
  exportType,
  endDate,
  metricType,
  offset,
  count,
  sort,
) {
  const params = {};
  params.offset = offset || 0;
  count && (params.limit = count);
  sort && (params.sort = sort);
  if (startDate) params['start-date'] = startDate;
  if (excludedServers) params['excluded-servers'] = excludedServers;
  if (projectionDays) params['projection-days'] = projectionDays;
  if (exportType) params['export-as'] = exportType;
  if (endDate) params['end-date'] = endDate;
  if (metricType) params['metric-type'] = metricType;

  return axios.get(`${UTILIZATION_BY_ENTITY_URL}`, { params });
}

export async function getServersForEachAggregateBy(
  startDate,
  aggregateBy,
  endDate,
  filter,
  serverCount,
) {
  const servers = [];
  let fetches = [];
  const total = serverCount;
  let count = 0;
  const firstFetch = await getSustainabilityAnalysisTableData(
    startDate,
    endDate == null ? DEFAULT_PROJECTION_DAYS : null,
    null,
    aggregateBy,
    null,
    endDate,
    0,
    MAX_GET_SERVERS_ANALYTICS_COUNT,
    null,
    filter,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }
  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_GET_SERVERS_ANALYTICS_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getSustainabilityAnalysisTableData(
          startDate,
          // endDate is not needed for projection, only for historical
          endDate == null ? DEFAULT_PROJECTION_DAYS : null,
          null,
          aggregateBy,
          null,
          endDate,
          i * MAX_GET_SERVERS_ANALYTICS_COUNT,
          MAX_GET_SERVERS_ANALYTICS_COUNT,
          null,
          filter,
        ),
      );
    }
    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.push(firstFetch);
  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(server => {
        servers.push(server);
      });
    }
  });
  return servers;
}

export async function getApprovalRequests(sort, searchString, filterQueries) {
  const params = {};
  sort && (params.sort = sort);
  searchString &&
    (params.search = `${searchString
      .trim()
      .replaceAll('\\', '\\\\')
      .replaceAll("'", "\\'")}`);
  const filters = filterQueries || [];

  if (filters && filters.length > 0) {
    params.filter = `(${filters.join(') and (')})`;
  }
  return axios.get(`${APPROVAL_REQUESTS_URL}`, { params });
}

// Chat API functions
export async function postQuery(query) {
  return axios.post(CHAT_URL, { query });
}

export function sendChatFeedback(query, rating, feedback) {
  const body = {
    question: query,
    rating,
    feedback,
  };

  return axios.post(CHAT_FEEDBACK_URL, body);
}

export async function getApprovalPolicies(sort, searchString, filterQueries) {
  const params = {};

  sort && (params.sort = sort);
  searchString &&
    (params.search = `${searchString
      .trim()
      .replaceAll('\\', '\\\\')
      .replaceAll("'", "\\'")}`);
  const filters = filterQueries || [];
  if (filters && filters.length > 0) {
    params.filter = `(${filters.join(') and (')})`;
  }

  return axios.get(`${APPROVAL_POLICY_URL}`, { params });
}

export async function getApproverList() {
  return axios.get(`${APPROVER_LIST_URL}`);
}

// Get a page of groups using an approval policy
export async function getGroupsUsingPolicy(policyId, offset, limit) {
  let url = OPS_MGMT_APPROVAL_POLICIES_V1_URI_PREFIX;
  const params = {};
  const filters = [`approvalPolicy/id eq '${policyId}'`];

  if (filters && filters.length > 0) {
    if (filters instanceof Array) {
      params.filter = `(${filters.join(') and (')})`;
    } else {
      params.filter = filters;
    }
  }

  offset && (params.offset = offset);
  limit && (params.limit = limit);

  url = GROUPS_URL_V2;
  return axios.get(`${url}`, {
    params,
  });
}

export async function getAllGroupsUsingPolicy(policyId) {
  const groups = [];
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getGroupsUsingPolicy(
    policyId,
    0,
    MAX_DOORWAY_COUNT,
  ).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / MAX_DOORWAY_COUNT);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(
        getGroupsUsingPolicy(
          policyId,
          i * MAX_DOORWAY_COUNT,
          MAX_DOORWAY_COUNT,
        ),
      );
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.splice(0, 0, firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(group => {
        groups.push(group);
      });
    }
  });

  return groups;
}

export function getPolicy(id) {
  return axios.get(`${APPROVAL_POLICY_URL}/${id}`);
}

export function deleteApprovalPolicy(id) {
  const url = APPROVAL_POLICY_URL;

  return axios.delete(`${url}/${id}`);
}

export function assignGroupToPolicy(policyId, payload) {
  const url = APPROVAL_POLICY_URL;

  return axios.patch(`${url}/${policyId}`, payload);
}
