// Copyright 2025 - Hewlett Packard Enterprise Company
import { useLayoutEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import useFlags from '../../hooks/useFlags';

export default function Header() {
  const flags = useFlags();
  const showGLPHeader = flags.T_20240505_931_INTEGRATE_GLP_HEADER_BOOL();

  useLayoutEffect(() => {
    // re-insert global header/footer on component mount to ensure it exists and is visible.
    // By default, init is only called on initial page load but not if route changes and
    // PageLayout is unmounted/remounted which destroys the elements constructed during init.
    if (!showGLPHeader) {
      window.HPEHFWSLOADER?.insertHF(window.hfwsHeader, window.hfwsFooter);
      window.HPEHF?.forceReInit();
    }

    return () => {
      // init bails early if this is true, so set false when unmounted
      // as the element no longer exists and needs to be initialized again on mount
      if (window.HPEHF) {
        window.HPEHF.hfInitialized = false;
      }
    };
  }, [showGLPHeader]);

  // HFWS global header footer service populates id="header" and id="footer" in body
  return (
    <HelmetProvider>
      {!showGLPHeader && (
        <>
          <Helmet>
            <script src="https://h50007.www5.hpe.com/hfws-static/js/framework/jquery/v-3-6-0/jquery.js" />
            <script src="https://h50007.www5.hpe.com/hfws/us/en/hpe/latest.r/root?contentType=js&switchToCHF=true&hide_country_selector=true&console_mode=true&hide_main_nav=true&hide_search=true&hide_cart=true&hide_sign_in=true&context=cpcc" />
          </Helmet>
          <div id="header" className="header">
            {/* HFWS will insert its header here */}
          </div>
        </>
      )}
      {showGLPHeader && <></>}
    </HelmetProvider>
  );
}
