// Copyright 2025 - Hewlett Packard Enterprise Company

import './tcaas.css';

import { useContext, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

// import { ContextualHelp } from '@glcp/contextual-help';
import { ContextualHelp } from '@glcp/contextual-help/dist/ContextualHelp';

import { SidebarContext } from '../../providers/SidebarProvider';
import useFlags from '../../hooks/useFlags';
import { getLocale } from '../../utils/i18nHelpers';

const TOPICS = {
  '/activity': 'com-activity-tt',
  '/ahsLogAnalyzer': 'com-ahs-analyzer-tt',
  '/analytics': 'com-analytics-tt',
  '/analytics/carbonFootprint': 'com-sustainability-tt',
  '/analytics/serverUtilization': 'com-serverutilization-tt',
  '/applianceDetail/:id': 'com-appliance-detail-tt',
  '/appliances': 'com-appliances-tt',
  '/arubaCentral': 'com-aruba-tt',
  '/dsccIntegration': 'com-dscc-tt',
  '/emailNotification': 'com-email-notification-by-instance-tt',
  '/error/:code': 'com-error-tt',
  '/firmware': 'com-firmware-tt',
  '/firmwareUpdateReport/:id': 'com-firmware-report-tt',
  '/groupComplianceIssue/:id': 'com-group-compliance-issue-tt',
  '/groupComplianceIssues/:id': 'com-group-compliance-issues-tt',
  '/groupDetail/:id': 'com-group-details-page-tt',
  '/groupExternalStorageCompliance/:id':
    'com-group-compliance-storage-issue-tt',
  '/groupIloDetailIssue/:id': 'com-group-compliance-ilo-issue-tt',
  '/groups': 'com-groups-tt',
  '/hardwareDetail/:applianceId/:hardwareId': 'com-hwdetail-app-hw-tt',
  '/inventory': 'com-inventory-tt',
  '/manage': 'com-manage-tt',
  '/metricsData': 'com-metrics-dc-tt',
  '/overview': 'com-overview-tt',
  '/reports': 'com-reports-tt',
  '/reports/carbonFootprint/:id': 'com-sustainability-report-tt',
  '/reports/serverHardwareInventory/:id': 'com-hardware-inventory-report-tt',
  '/serverDetail/:id': 'com-serverdetail-tt',
  '/servers': 'com-servers-tt',
  '/serviceNow': 'com-servicenow-tt',
  '/settingDetail/:id': 'com-setting-details-tt',
  '/settings': 'com-settings-tt',
};

const DEFAULT_TOPIC = 'com-404-tt';

const resolveTopic = matchedPath => {
  // lookup mapping
  if (matchedPath && TOPICS[matchedPath]) {
    return TOPICS[matchedPath];
  }

  return DEFAULT_TOPIC;
};

export default function Help({ ...rest }) {
  const flags = useFlags();
  const contextualHelpFlag =
    flags.T_20240505_18961_INTEGRATE_CONTEXTUAL_HELP_BOOL();
  const showTopicUrlFlag =
    flags.T_20250315_18961_CONTEXTUAL_HELP_SHOW_TOPIC_URL_BOOL();

  const tcaasLocale = getLocale();

  const match = useRouteMatch(Object.keys(TOPICS));
  const topic = resolveTopic(match?.path);

  const { setIsContextualHelpOpen } = useContext(SidebarContext);

  const onClose = useCallback(() => {
    // do not emit the event to close contextual help because it would just reopen it!
    const emitEvent = false;
    setIsContextualHelpOpen(false, emitEvent);
  }, [setIsContextualHelpOpen]);

  return (
    <>
      {contextualHelpFlag && (
        <ContextualHelp
          locale={tcaasLocale}
          topicsId={topic}
          proxy={window.indigo.REACT_APP_HELP_PROXY}
          viewTopicPrefix={showTopicUrlFlag ? 'hpesc_' : null}
          viewAllUrl="https://www.hpe.com/info/com-docs"
          onClose={onClose}
          hideTopicUrl={!showTopicUrlFlag} // invert the flag value. flag = true means show
          {...rest}
        />
      )}
    </>
  );
}
