// Copyright 2025 - Hewlett Packard Enterprise Company
import React from 'react';
import { Box, Text } from 'grommet';
import PropTypes from 'prop-types';
import { User } from 'grommet-icons/icons/User';

const ChatQuery = ({ query }) => (
  <Box width={{ max: '100%' }} direction="column" margin={{ bottom: 'small' }}>
    <Box style={{ width: 'fit-content', position: 'relative' }}>
      {query !== undefined && (
        <>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '5px', // Adjust this value to position the icon as needed
              transform: 'translateY(-50%)',
            }}
          >
            <User color="text-strong" />
          </Box>
          <Box
            data-testid="chat-user-query"
            direction="row"
            background="background-front"
            pad="small"
            round="small"
            style={{
              width: 'fit-content',
              alignSelf: 'end',
              marginLeft: '40px',
            }}
          >
            <Text
              data-testid="chat-user-query-text"
              style={{ wordBreak: 'break-word' }}
            >
              {query}
            </Text>
          </Box>
        </>
      )}
    </Box>
  </Box>
);

export default ChatQuery;

ChatQuery.propTypes = {
  query: PropTypes.string.isRequired,
};
