// Copyright 2022-2025 - Hewlett Packard Enterprise Company
import { DateTime } from 'luxon';
import { differenceInCalendarDays } from 'date-fns';
import { NOT_SET } from '../constants';
import { getLocale } from './i18nHelpers';

const MIN_MILLIS = 60000;
const DAY_MILLIS = 24 * 60 * MIN_MILLIS;

const MILITARY_TIME_OPTIONS = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const MILITARY_TIME_MASK = [
  {
    length: 2,
    options: MILITARY_TIME_OPTIONS,
    regexp: /^2[0,1-3]$|^1[0,1-9]$|^0[0,1-9]$|^0$|^1$|^2$/,
    placeholder: 'hh',
  },
  { fixed: ':' },
  {
    length: 2,
    options: ['00', '15', '30', '45'],
    regexp: /^[0-5][0-9]$|^[0-9]$/,
    placeholder: 'mm',
  },
];

/*
  This method is used by for validation of user's provided
  date & time input with current date time.
*/
export function getDayBoundaryDates(days) {
  const now = new Date();
  const daysFromNow = new Date(now.getTime() + days * DAY_MILLIS);
  const bounds = [now, daysFromNow];
  return bounds;
}

/*
  This method is used by DateInput to validate the date bound.
  Resetting Date's time to midnight because of the positive time zone UTC
  offset such as IST causing a date at the boundary to be seen as invalid
*/
export function getDayBoundaryStrings(days) {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const daysFromNow = new Date(now.getTime() + days * DAY_MILLIS);
  const bounds = [now.toISOString(), daysFromNow.toISOString()];
  return bounds;
}

export function addTimeToDate(date, timeStr) {
  const timeParts = timeStr.split(':');
  let hours = timeParts.length > 0 ? parseInt(timeParts[0], 10) : 0;
  if (Number.isNaN(hours)) hours = 0;
  let minutes = timeParts.length > 1 ? parseInt(timeParts[1], 10) : 0;
  if (Number.isNaN(minutes)) minutes = 0;
  date.setHours(hours, minutes, 0, 0);
  return date;
}

export function isScheduleOutOfBounds(dateStr, timeStr, bounds) {
  let date = new Date(dateStr);
  date = addTimeToDate(date, timeStr);

  return (
    date.getTime() < bounds[0].getTime() ||
    date.getTime() >= bounds[1].getTime()
  );
}

export function isScheduleTimeFormatValid(timeStr) {
  return (
    timeStr &&
    timeStr.match(/^(2[0,1-3]|1[0,1-9]|0[0,1-9]):([0-5][0-9]|[0-9])$/)
  );
}

export function formatDateWithOptions(date, options) {
  return new Intl.DateTimeFormat(getLocale(), options).format(date);
}

export function formatScheduleDate(scheduleDate) {
  const date = new Date(scheduleDate);
  const params = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return formatDateWithOptions(date, params);
}

export function formatScheduleTimeFromDate(date) {
  const localTimeStr = new Intl.DateTimeFormat(getLocale(), {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  }).format(date);

  return localTimeStr;
}

export function getFormattedLocalTime(dateStr, timeStr) {
  let date = new Date(dateStr);
  date = addTimeToDate(date, timeStr);
  const startTime = date.toString().split(/[x^+-]/)[0];
  const myDate = new Date(startTime);
  return myDate.toLocaleString(getLocale(), {
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function formatScheduleTime(dateStr, timeStr) {
  let date = new Date(dateStr);
  date = addTimeToDate(date, timeStr);
  return formatScheduleTimeFromDate(date);
}

export function getScheduleTimestamp(dateStr, timeStr) {
  let date = new Date(dateStr);
  date = addTimeToDate(date, timeStr);
  return date.toISOString();
}

export function getScheduleDateTimeStrings(timestamp) {
  const dateComponent = new Date(timestamp);
  // Pass in the schedule date at midnight to grommet DateInput
  dateComponent.setHours(0, 0, 0, 0);

  const timeComponent = new Date(timestamp);
  const time = Intl.DateTimeFormat(getLocale(), {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
  }).format(timeComponent);
  // Sometimes the browser only returns 1 digit for the hour
  // even though we specify hourCycle h23.
  const paddedTime = time && time.length < 5 ? `0${time}` : time;

  return {
    scheduleDate: dateComponent.toISOString(),
    scheduleTime: paddedTime,
  };
}

export function formatScheduleStartTimestamp(timestamp) {
  if (!timestamp) {
    return NOT_SET;
  }
  const date = new Date(timestamp);
  return date.toLocaleString([], { timeStyle: 'short', dateStyle: 'medium' });
}

export function hasScheduleStarted(schedule) {
  return !schedule.nextStartAt;
}

export function getScheduleSummary(formValues) {
  const { scheduleDate, scheduleTime } = formValues;
  if (!scheduleDate || !scheduleTime) {
    return '';
  }
  if (!isScheduleTimeFormatValid(scheduleTime)) {
    return '';
  }
  if (
    isScheduleOutOfBounds(scheduleDate, scheduleTime, getDayBoundaryDates(7))
  ) {
    return '';
  }
  const formattedTime = formatScheduleTime(scheduleDate, scheduleTime);
  return `The scheduled date and time is ${formattedTime}`;
}

function formatReportDateV2(d) {
  const dt = new Date(Date.parse(d)).toUTCString();
  const splitDate = dt.split(' ');
  const selectedElements = splitDate.slice(1, 4);

  // Join the selected elements with a space
  return `${selectedElements.join(' ')}`;
}

export function formatReportDateRangeV2(start, end) {
  return `${formatReportDateV2(start)} - ${formatReportDateV2(end)}`;
}

export function formatISODate(date) {
  // like Date.prototype.toISOString, but preserve Date object's timezone
  const offset = Math.abs(date.getTimezoneOffset());
  const dif = date.getTimezoneOffset() < 0 ? '+' : '-';
  const pad = num => (num < 10 ? '0' : '') + num;

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate(),
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds(),
  )}${dif}${pad(Math.floor(offset / 60))}:${pad(offset % 60)}`;
}

export function getReportBucketDate(timestamp, bucketDurationInSec) {
  // convert start UTC time to end time in browser's timezone
  const end = new Date(
    new Date(timestamp).getTime() + bucketDurationInSec * 1000,
  );
  return end.toISOString();
}

export function constructDate(dateObj) {
  const pad = num => (num < 10 ? '0' : '') + num;
  const year = dateObj.getUTCFullYear();
  const month = pad(dateObj.getUTCMonth() + 1);
  const day = pad(dateObj.getUTCDate());
  return `${year}-${month}-${day}`;
}

export function addTimeDetails(date, timestamp) {
  return date + timestamp;
}

export function getTimeStamp(dateString) {
  return `${dateString.split(' ')[4]}`;
}

export function getLocalDate(reportTime) {
  const utcDate = new Date(reportTime?.split('.')[0]);
  return new Date(
    utcDate?.getTime() - utcDate?.getTimezoneOffset() * 60 * 1000,
  );
}

// Converts a utc time string (ex. "20:00") to a local
// DateTime object and returns true if the resulting
// local time is in the morning.
export function isMorning(utcTimeStr) {
  const timeParts = utcTimeStr.split(':');
  let hours = timeParts.length > 0 ? parseInt(timeParts[0], 10) : 0;
  if (Number.isNaN(hours)) hours = 0;
  let minutes = timeParts.length > 1 ? parseInt(timeParts[1], 10) : 0;
  if (Number.isNaN(minutes)) minutes = 0;
  const utcDateTime = DateTime.fromObject(
    { hour: hours, minute: minutes },
    { zone: 'utc' },
  );
  const localDateTime = utcDateTime.toLocal();
  // hour is 0-23
  const { hour } = localDateTime;
  return hour < 12;
}

export function getDateFormatString(lang = 'default') {
  const formatObj = new Intl.DateTimeFormat(lang).formatToParts(new Date());

  return formatObj
    .map(obj => {
      switch (obj.type) {
        case 'day':
          return 'dd';
        case 'month':
          return 'mm';
        case 'year':
          return 'yyyy';
        default:
          return obj.value;
      }
    })
    .join('');
}

export function getReportGeneratedOnDateTime(timestamp) {
  const date = new Date(timestamp);
  const localDateStr = new Intl.DateTimeFormat(getLocale(), {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(date);
  const formattedDate = `${localDateStr} ${date.toLocaleTimeString()}`;
  return formattedDate;
}

// example timestamp parameter: 2023-10-24T10:40:05Z
// formattedDateTime output: 10/24/2023 3:40 AM PDT
export function getFormattedDateTimeWithTimeZone(timestamp) {
  const dateTime = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  };
  const formattedDateTime = dateTime.toLocaleString(getLocale(), options);
  return formattedDateTime;
}

// example timestamp parameter: 2023-10-24T10:40:05Z
// formattedDateTime output: 10/24/2023 3:40 AM
export function getFormattedDateTime(timestamp) {
  const dateTime = new Date(timestamp);
  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = dateTime.toLocaleString(getLocale(), dateOptions);
  const formattedTime = dateTime.toLocaleTimeString(getLocale(), timeOptions);
  return { formattedDate, formattedTime };
}

// example input dayOfMonth: 1
// output: 1st
export function getOrdinalforDayofMonth(dayOfMonth) {
  if (dayOfMonth > 3 && dayOfMonth < 21) return 'th';
  switch (dayOfMonth % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

// example timestamp parameter: 2023-10-24T10:40:05Z
// formattedDateTime output: 20231024-1610
export function formatDateTimeForAhsDownload(timestamp) {
  const datetime = new Date(timestamp);
  const hours = datetime.getHours().toString().padStart(2, '0');
  const minutes = datetime.getMinutes().toString().padStart(2, '0');
  const year = datetime.getFullYear();
  const month = (datetime.getMonth() + 1).toString().padStart(2, '0');
  const day = datetime.getDate().toString().padStart(2, '0');
  return `${year}${month}${day}-${hours}${minutes}`;
}

// Converts a date to UTC, stripping time information
export function toUTCDate(date) {
  return new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
  );
}

// Formats a date for API calls (YYYY-MM-DD)
export function formatForAPI(date) {
  return new Date(date).toISOString().split('T')[0];
}

// Formats a date for display purposes
export function formatForDisplay(date, showYear) {
  const options = {
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  };
  if (showYear) {
    options.year = 'numeric';
  }
  return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
}

// Gets a formatted date range string eg: Jan 14 - Mar 14 (30 days) can have year or not
export function getDateRange(startDate, endDate, showYear) {
  const days = differenceInCalendarDays(endDate, startDate) + 1;
  const start = formatForDisplay(startDate, showYear);
  const end = formatForDisplay(endDate, showYear);
  return `${start} - ${end} (${days} ${days === 1 ? 'day' : 'days'})`;
}

export function getDaysAgo(days) {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return toUTCDate(date);
}

// Gets today's date in UTC
export function getToday() {
  return toUTCDate(new Date());
}

// Calculate date difference in days
export function getDaysDifference(startDate, endDate) {
  return differenceInCalendarDays(endDate, startDate) + 1;
}

export function getExpireDateTimeFromHoursHumanFormat(numHours) {
  // Create a new Date object based on the calculated milliseconds
  const currTime = new Date();
  const result = new Date(currTime.getTime() + numHours * 60 * 60 * 1000);

  // Format date and time using toLocaleDateString and toLocaleTimeString
  const formattedDate = result.toDateString(getLocale());
  const formattedTime = result.toLocaleTimeString(getLocale(), {
    hour: 'numeric',
    minute: '2-digit',
  });

  return {
    date: formattedDate,
    time: formattedTime,
  };
}
