// Copyright 2025 - Hewlett Packard Enterprise Company
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextArea, Button, Text, ResponsiveContext } from 'grommet';

import { Send } from 'grommet-icons';
import { postQuery } from '../../actions/Api';

const ChatFooter = ({
  addConversation,
  updateConversation,
  setLoading,
  conversations,
}) => {
  const { t } = useTranslation('common');
  const [query, setQuery] = useState('');
  const [loading, setLoadingState] = useState(false);
  const textAreaRef = useRef(null);
  const size = useContext(ResponsiveContext);

  const adjustHeight = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = 'auto';
      const newHeight = Math.min(textArea.scrollHeight, 5 * 20); // Assuming 20px per line
      textArea.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [query]);

  const sendQuery = async () => {
    if (query.length > 0 && query.trim() !== '') {
      const index = conversations.length;
      addConversation(query);
      setQuery('');
      setLoading(true);
      setLoadingState(true);
      try {
        const answer = await postQuery(query)
          .then(response => response.data)
          .catch(error => {
            throw error;
          });
        updateConversation(index, answer);
      } catch (error) {
        updateConversation(index, 'Error retrieving answer');
      } finally {
        setLoading(false);
        setLoadingState(false);
      }
    }
  };

  const handleTextAreaChange = e => {
    setQuery(e.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey && !loading) {
      event.preventDefault();
      sendQuery();
    }
  };

  return (
    <Box
      data-testid="chat-footer"
      background="background-back"
      style={{ position: 'sticky', bottom: 0 }}
      pad={{ top: 'small' }}
    >
      <Box
        direction="row"
        align="end"
        width="100%"
        pad={{ horizontal: 'small', top: 'small' }}
        gap="small"
      >
        <Box
          background="background-front"
          fill="horizontal"
          round={['xsmall', 'small'].includes(size) ? 'small' : 'xsmall'}
          border={{ color: 'brand', size: 'small' }}
        >
          <TextArea
            value={query}
            placeholder={t('chat.chat_footer_placeholder')}
            focusIndicator={false}
            ref={textAreaRef}
            autoFocus
            onChange={handleTextAreaChange}
            onKeyDown={handleKeyDown}
            style={{
              minHeight: 'xsmall', // 2 lines
              maxHeight: 'medium', // 5 lines
              resize: 'none',
              overflow: 'auto',
              border: 'none',
            }}
            data-testid="chat-user-query"
          />
        </Box>
        <Box width="xxsmall" align="center">
          <Button
            icon={<Send />}
            onClick={sendQuery}
            primary
            disabled={loading} // Disable the button while loading
          />
        </Box>
      </Box>
      <Box
        pad={{ horizontal: 'small', bottom: 'small', top: 'xsmall' }}
        align="start"
        justify="start"
      >
        <Text size="xsmall" color="text-weak" data-testid="chat-user-warning">
          {t('chat.chat_footer_info')}
        </Text>
      </Box>
    </Box>
  );
};

export default ChatFooter;
