// Copyright 2025 - Hewlett Packard Enterprise Company
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Paragraph, Text } from 'grommet';
import { compiler } from 'markdown-to-jsx';

const StyledBox = styled(Box)`
  *:last-child {
    margin-bottom: 0;
  }
`;

const Wrapper = props => <StyledBox gap="none" {...props} />;

const MarkdownComponent = ({ children, compact }) => {
  const paragraphMargin = compact ? 'none' : { top: 'none', bottom: 'small' };
  const listMargin = { top: 'none', bottom: 'small', left: 'medium' };

  const options = {
    wrapper: Wrapper,
    disableParsingRawHTML: true,
    overrides: {
      ul: {
        props: { style: { 'overflow-wrap': 'break-word' } },
      },
      span: {
        component: Text,
        props: { wordBreak: 'break-word' },
      },
      p: {
        component: Paragraph,
        props: { margin: paragraphMargin, fill: true },
      },
      a: {
        component: ({ children, title, href }) => {
          const label =
            children && children.length > 0 ? children.join(' ') : '';

          if (label === href) {
            return <>{href}</>;
          }

          return (
            <>
              [{label || title}](
              {href}
              {title ? ` "${title}"` : ''})
            </>
          );
        },
      },
      img: {
        component: ({ title, alt, src }) => (
          <>
            ![{alt}]({src}
            {title ? ` "${title}"` : ''})
          </>
        ),
      },
    },
  };

  if (!compact) {
    options.overrides.ul = {
      component: Paragraph,
      props: { margin: listMargin, fill: true },
    };
  }

  return compiler(children, options);
};

MarkdownComponent.propTypes = {
  children: PropTypes.node.isRequired,
  compact: PropTypes.bool,
};

export default MarkdownComponent;
