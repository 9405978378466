// Copyright 2021-2024 - Hewlett Packard Enterprise Company

import { useCallback, useMemo, useState, useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ldFlagDefaults } from '../constants/ldFlagDefaults';

function featureFlag(ldClient, flagName, flagDefault) {
  return () => {
    if (ldClient) {
      return ldClient.variation(flagName, flagDefault);
    }
    return flagDefault;
  };
}

export default function useFlags() {
  const ldClient = useLDClient();
  const [ldFlags, setLdFlags] = useState(() => {
    if (ldClient) {
      return ldClient.allFlags();
    }
    return {};
  });

  const onFlagsChange = useCallback(() => {
    if (ldClient) {
      setLdFlags(ldClient.allFlags());
    }
  }, [ldClient]);

  useEffect(() => {
    if (ldClient) {
      // if the client receives an event that the LD flags changed, check if any
      // new flag functions need to be added
      ldClient.on('initialized', onFlagsChange);
      ldClient.on('change', onFlagsChange);
    }

    return () => {
      if (ldClient) {
        // remove listeners when unmounting
        ldClient.off('initialized', onFlagsChange);
        ldClient.off('change', onFlagsChange);
      }
    };
  }, [onFlagsChange, ldClient]);

  return useMemo(() => {
    const flags = {};

    // create flag wrapper fns for all statically defined flags with defaults
    Object.entries(ldFlagDefaults).forEach(([key, defaultValue]) => {
      flags[key] = featureFlag(ldClient, key, defaultValue);
    });

    // create flag wrapper fns for flags that aren't statically defined in code
    Object.keys(ldFlags).forEach(key => {
      // don't recreate functions that already exist
      if (!(key in flags)) {
        flags[key] = () => featureFlag(ldClient, key)();
      }
    });

    return flags;
  }, [ldFlags, ldClient]);
}
