// Copyright 2025 - Hewlett Packard Enterprise Company
import React, { useRef, useEffect } from 'react';
import { Box } from 'grommet';
import PropTypes from 'prop-types';
import ChatQuery from './ChatQuery';
import ChatAnswer from './ChatAnswer';

const ChatMainContent = ({ conversation }) => {
  const chatBodyRef = useRef();

  const scrollToBottom = () => {
    chatBodyRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    setTimeout(() => {
      requestAnimationFrame(() => {
        scrollToBottom();
      });
    }, 0);
  }, [conversation.length]);

  return (
    <Box
      pad="small"
      align="start"
      data-testid="chat-main-content"
      overflow="auto" // Ensure the container can scroll
      flex="grow"
      background="background-back"
      border="horizontal"
      fill="vertical"
      style={{ paddingBottom: '30px' }}
    >
      {conversation?.map((exchange, index) => (
        <Box
          key={index}
          width="100%"
          pad={{ vertical: 'small' }}
          margin={{ bottom: 'small' }}
          style={{ maxWidth: '100%' }}
          flex={false}
        >
          <ChatQuery query={exchange.query} />
          <ChatAnswer query={exchange.query} answer={exchange.answer} />
        </Box>
      ))}
      <div ref={chatBodyRef} />
    </Box>
  );
};

export default ChatMainContent;

ChatMainContent.propTypes = {
  conversation: PropTypes.array.isRequired,
};
