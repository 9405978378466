// Copyright 2025 - Hewlett Packard Enterprise Company
import PropTypes from 'prop-types';
import { Box, ResponsiveContext } from 'grommet';
import React, { useState, useContext } from 'react';
import ChatHeader from './ChatHeader';
import ChatFooter from './ChatFooter';
import ChatMainContent from './ChatMainContent';

export default function ChatContainer({ onClose }) {
  const size = useContext(ResponsiveContext);
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);

  const addConversation = query => {
    setConversations(prevConversations => [
      ...prevConversations,
      { query, answer: 'Loading' },
    ]);
  };

  const updateConversation = (index, answer) => {
    setConversations(prevConversations => {
      const updatedConversations = [...prevConversations];
      if (index >= 0 && index < updatedConversations.length) {
        updatedConversations[index].answer = answer;
        return updatedConversations;
      }
      return prevConversations;
    });
  };

  const clearConversations = () => {
    setConversations([]);
  };

  return (
    <Box data-testid="chatContainerBox" height="100vh">
      <Box
        fill="vertical"
        gap="none"
        width={{
          min: !['xsmall', 'small'].includes(size) ? 'medium' : undefined,
        }}
      >
        <ChatHeader onClose={onClose} clearConversations={clearConversations} />
        <ChatMainContent conversation={conversations} />
      </Box>
      <ChatFooter
        addConversation={addConversation}
        updateConversation={updateConversation}
        setLoading={setLoading}
        conversations={conversations}
      />
    </Box>
  );
}

ChatContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
};
