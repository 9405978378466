// Copyright 2025 - Hewlett Packard Enterprise Company

import i18n from '../i18n';

export function getLocale() {
  const locale = i18n?.resolvedLanguage;
  return locale || 'en-US';
}

export function getLocaleWithUnderscore() {
  const locale = getLocale();
  return locale ? locale.replace(/-/g, '_') : 'en_US';
}
