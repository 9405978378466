// Copyright 2025 - Hewlett Packard Enterprise Company
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, DropButton, Button, Heading } from 'grommet';
import { MoreVertical, FormClose, Chat, History } from 'grommet-icons';
import PropTypes from 'prop-types';
import ExternalAnchor from '../ExternalAnchor/ExternalAnchor';

export default function ChatHeader({ onClose, clearConversations }) {
  const { t } = useTranslation('common');
  const [openDropMenu, setOpenDropMenu] = useState();

  const onCloseChatBot = () => {
    // treat escape/clicking outside the same
    onClose();
  };

  return (
    <Box
      data-testid="chat-header"
      justify="between"
      flex={false}
      gap="small"
      background="background-front"
      style={{
        position: 'sticky',
        top: 0,
      }}
      direction="row"
      pad={{ right: 'small' }}
    >
      <DropButton
        label={<MoreVertical size="small" />}
        open={openDropMenu}
        onOpen={() => setOpenDropMenu(true)}
        onClose={() => setOpenDropMenu(false)}
        dropContent={
          <Box align="start">
            <Button
              default
              kind="option"
              icon={<Chat />}
              label={t('chat.new_chat')}
              onClick={() => {
                clearConversations(); // Clear the conversations
                setOpenDropMenu(false);
              }}
              data-testid="clear-conversations-button"
            />
            <Button
              default
              kind="option"
              icon={<History />}
              label={t('chat.view_history')}
              onClick={() => {
                setOpenDropMenu(false);
                // TO DO: implement view chat history
              }}
              data-testid="chat-view-chat-history"
            />
            <Box border="top" pad="xsmall">
              <Button default>
                <ExternalAnchor href="https://www.hpe.com/us/en/legal/privacy.html#privacystatement">
                  {t('chat.hpe_privacy_policy')}
                </ExternalAnchor>
              </Button>
            </Box>
          </Box>
        }
        dropAlign={{
          left: 'left',
          top: 'bottom',
        }}
        data-testid="chat-header-drop-button"
      />
      <Box direction="row" gap="xxsmall" alignSelf="center">
        <Heading margin="none" level={2}>
          {t('chat.chat_title')}
        </Heading>
        <Box style={{ position: 'relative', top: '-0.5em' }}>
          <Text size="small">{t('chat.chat_title_beta')}</Text>
        </Box>
      </Box>
      <Button default icon={<FormClose />} onClick={onCloseChatBot} />
    </Box>
  );
}

ChatHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};
