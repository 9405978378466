// Copyright 2022-2025 - Hewlett Packard Enterprise Company
/* eslint-disable import/prefer-default-export */

/*
 * When adding code that consumes an LD flag, add the flag and a default value
 * here as well.  This helps ensure that there is no gap during the initial
 * application loading window prior to receiving the flags from LD where the
 * flag is completely unavailable, causing a run time error.
 */

// Add/remove flags that indigo currently consumes in the following object map
export const ldFlagDefaults = {
  P_20220820_9616_ILO_ONLY_FW_UPDATE_PARAMS_JSON: [],
  P_20230606_569_FWU_PROD_IDS_EXCLUDE_JSON: [
    'P59867-B21',
    'P59868-B21',
    'P59869-B21',
    'P59870-B21',
  ],
  P_20241104_51729_FWU_EXCLUDE_MODEL_GEN_JSON: [{}],
  P_20220921_8527_ANNOUNCEMENT_COUNT_INT: 0,
  P_20230104_11587_CID_DISPLAY_BOOL: false,
  T_20230119_12538_SERVERS_PAGE_CSV_FILE_EXPORT: false,
  T_20230227_113_COM_DENALI_UNIFIED_CONSOLE_BOOL: false,
  T_20230418_997_ENDPOINTS_FOR_SERVER_HW_AND_PROFILES_BOOL: false,
  T_20230419_158_DISABLE_SUBSCRIPTION_TIERS_BOOL: false,
  T_20230524_8648_SERVER_PROFILES_UI_BOOL: false,
  T_20230613_877_SERVER_GRACEFUL_SHUTDOWN_BOOL: false,
  T_20230627_6458_DOWNGRADE_COMPONETS_UI_BOOL: false,
  T_20230726_6894_SERVER_COMPONENTS_REPORT_BOOL: false,
  T_20230816_7618_ENHANCE_SERVER_HW_INV_REPORT_AGG_BY_DRIVES_BOOL: false,
  T_20230726_10157_RUN_VIEW_FW_COMPLIANCE_REPORT_BOOL: false,
  T_20230726_7582_SERVER_HW_DEVICE_CONTROLLER_REPORT: false,
  P_20231018_20166_NOTIFICATION_BANNER_JSON: {},
  T_20230911_939_TOKEN_BASED_ONBOARDING_PHASE2_BOOL: false,
  T_20240111_1065_UI_USER_DEFINED_ILO_SETTINGS_BOOL: false,
  T_20240205_27140_OVE_FIRMWARE_UPDATE_UI_PHASE2: false,
  T_20240202_28002_APPLIANCE_URI_ID_DISCREPANCY_FIX: false,
  T_20231130_19046_PAGINATION_CF_REPORT_BOOL: false,
  T_20240209_23430_LOCAL_GATEWAY_UI_BOOL: false,
  T_20241014_52081_MAP_VIEW_ENHANCEMENTS_UI_BOOL: false,
  T_20240410_30981_AHS_FILE_UPLOAD_UI_BOOL: false,
  T_20240607_250_COMPUTE_OPS_MGMT_SHOW_SCHEDULED_PENDING_JOBS_BOOL: false,
  T_20240520_36790_CUSTOM_BIOS_SETTINGS_BOOL: false,
  T_20240529_40026_DEVICE_INDEPENDENT_HUDDLE_APIS_UI_BOOL: false,
  T_20240617_42920_PIN_BASED_ONBOARDING_UI_BOOL: false,
  T_20240626_35511_MULTI_VOLUME_SUPPORT_UI_BOOL: false,
  T_20240705_39301_METRICS_CONFIG_STABLE_VERSION_BOOL: false,
  T_20240810_35187_CONFIGURE_OV_SERVER_LOCATION_SERVER_DETAILS_PAGE: false,
  T_20240717_35495_STABLE_SETTINGS_API_BOOL: false,
  T_20240821_43505_ADD_ILO_OS_HOSTNAME: false,
  T_20240827_49287_STABLE_API_FW_BUNDLE_BOOL: false,
  T_20240830_49291_STABLE_SERVER_API_BOOL: false,
  T_20240828_49289_STABLE_USER_PREFERENCES_API_BOOL: false,
  T_20241503_24212_VMEDIA_PREFLIGHT_BOOL: false,
  T_20240828_2900_GEN12_SUPPORT_BOOL: false,
  T_20241113_32914_NEW_APPLIANCE_SETTINGS_BOOL: false,
  T_20240919_49412_AIPOS_TRENDING_PREDICTIVE_SUSTAINABILITY_UI_BOOL: false,
  T_20241114_49238_UI_SHOW_ACTIVE_NOTIFICATIONS_JSON: {},
  T_20241022_49238_UI_CUSTOM_KNOWN_ISSUE_JSON: {},
  T_20240918_44812_COM_VCENTER_INTEG_UI_BOOL: false,
  T_20241009_44568_COHESITY_FWU_CASE_BOOL: false,
  T_20240927_52513_BLOCKLISTING_PHASE2_UI_BOOL: false,
  T_20241002_14036_DEPENDENCY_FAILURES_UI_BOOL: false,
  T_20241009_52572_OV_SYNERGY_SERVER_TEMPLATES_UI_BOOL: false,
  T_20241031_3962_COM_ILO_ADV_BOOL: false,
  T_20241118_54368_SUPPORT_CUSTOM_OS_PHASE2_UI_BOOL: false,
  T_20240910_52081_MAP_VIEW_CUSTOMAPI_BOOL: false,
  T_20241120_4246_AHS_CSV_INVENTORY_BOOL: false,
  T_20250311_4246_AHS_CSV_JSON_BOOL: false,
  T_20250317_4632_AHS_PWR_TMP_SENS_CSV_JSON_BOOL: false,
  T_20241126_3350_DEVICE_SUPPORT_CASES_UI: false,
  T_20241210_53647_AHS_PUBLIC_API_BOOL: false,
  T_20240830_35875_WARNING_TO_PREFLIGHT_BOOL: false,
  T_20241218_50462_ES_COMPLIANCE_ROLLUP_BOOL: false,
  T_20241112_35860_SERVER_WARRANTY_STATUS_BOOL: false,
  T_20250102_4572_UTILIZATION_ALERTS_UI_BOOL: false,
  T_20250127_59443_CPU_UTILIZATION_REPORT_UI_BOOL: false,
  T_20250127_59444_MEM_IO_BUS_UTILIZATION_REPORT_UI_BOOL: false,
  T_20241210_53281_CHATBOT_SUPPORT_BOOL: false,
  T_20250120_55051_COM_APPROVALPOLICY_UI_BOOL: false,
  T_20250130_50978_ILO_IP_COLUMN_UI_BOOL: false,
  T_20240505_18961_INTEGRATE_CONTEXTUAL_HELP_BOOL: false,
  T_20250312_18961_CONTEXTUAL_HELP_LEARN_MORE_LINKS_BOOL: false,
  T_20250315_18961_CONTEXTUAL_HELP_SHOW_TOPIC_URL_BOOL: false,
  T_20240505_931_INTEGRATE_GLP_HEADER_BOOL: false,
  T_20240917_47914_SUT_STAGING_PATH_INVALID_BOOL: false,
  T_20250205_61638_LIQUID_COOLING_SECTION_HW_INV_UI_BOOL: false,
  T_20250205_4647_ILO7_DISPLAY_BOOL: false,
  T_20250207_4857_THIRD_PARTY_SERVER_UI_BOOL: false,
  T_20250206_60795_SSO_PERMISSIONS_BOOL: false,
  T_20240620_35388_STABLE_APPLIANCE_FIRMWARE: false,
  T_20241204_48023_ILO7_SMS_BOOL: false,
  T20250128_4632_AHS_CSV_JSON_BOOL: false,
  T_20250226_63723_CLICKABLE_CARDS_UI_BOOL: false,
  T_20250312_64198_ILO7_HTTPS_SUPPORT_UI_BOOL: false,
};
