// Copyright 2025 - Hewlett Packard Enterprise Company
import React, { createContext, useCallback, useMemo, useState } from 'react';

import useFlags from '../hooks/useFlags';

export const SidebarContext = createContext();

export default function SidebarProvider({ children }) {
  const flags = useFlags();
  const chatBotFlag = flags.T_20241210_53281_CHATBOT_SUPPORT_BOOL();
  const contextualHelpFlag =
    flags.T_20240505_18961_INTEGRATE_CONTEXTUAL_HELP_BOOL();

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isContextualHelpOpen, setIsContextualHelpOpen] = useState(() => {
    document.body.className.split(' ').includes('helpOpen');
  });

  const contextualHelpOpen = useCallback((isOpen, emitEvent = true) => {
    if (isOpen) {
      // close other sidebars
      setIsChatOpen(false);

      setIsContextualHelpOpen(true);
    } else {
      setIsContextualHelpOpen(false);
    }

    if (emitEvent) {
      dispatchEvent(new Event('context-help'));
    }
  }, []);

  const chatOpen = useCallback(
    isOpen => {
      if (isOpen) {
        // close other sidebars
        if (isContextualHelpOpen) {
          setIsContextualHelpOpen(false);
          dispatchEvent(new Event('context-help'));
        }

        setIsChatOpen(true);
      } else {
        setIsChatOpen(false);
      }
    },
    [isContextualHelpOpen],
  );

  const isSidebarOpen = useMemo(
    () =>
      !!(
        (chatBotFlag && isChatOpen) ||
        (contextualHelpFlag && isContextualHelpOpen)
      ),
    [chatBotFlag, contextualHelpFlag, isChatOpen, isContextualHelpOpen],
  );

  const toggleIsChatOpen = useCallback(() => {
    chatOpen(!isChatOpen);
  }, [chatOpen, isChatOpen]);

  const toggleIsContextualHelpOpen = useCallback(() => {
    contextualHelpOpen(!isContextualHelpOpen);
  }, [contextualHelpOpen, isContextualHelpOpen]);

  const value = useMemo(
    () => ({
      isChatOpen: chatBotFlag && isChatOpen,
      setIsChatOpen: chatOpen,
      isContextualHelpOpen: contextualHelpFlag && isContextualHelpOpen,
      setIsContextualHelpOpen: contextualHelpOpen,
      isSidebarOpen,
      toggleIsChatOpen,
      toggleIsContextualHelpOpen,
    }),
    [
      chatBotFlag,
      chatOpen,
      contextualHelpFlag,
      contextualHelpOpen,
      isChatOpen,
      isContextualHelpOpen,
      isSidebarOpen,
      toggleIsChatOpen,
      toggleIsContextualHelpOpen,
    ],
  );

  // the current design is to only allow a single side bar panel to be open at a time
  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
}
