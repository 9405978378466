// Copyright 2025 - Hewlett Packard Enterprise Company
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Layer, TextArea, Button, Anchor } from 'grommet';
import PropTypes from 'prop-types';
import { Like, Dislike, Hpe } from 'grommet-icons';
import MessageLoader from './MessageLoader';
import { SERVER_NAME_NOT_SET } from '../../constants';
import { sendChatFeedback } from '../../actions/Api';
import TextEmphasis from '../TextEmphasis/TextEmphasis';
import MarkdownComponent from '../MarkdownComponent/MarkdownComponent';

const ChatAnswer = ({ query, answer }) => {
  const { t } = useTranslation('common');
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isLiked, setIsLiked] = useState(null);
  const [chatResponse, setChatResponse] = useState({ summary: '', items: [] });
  const [showAllItems, setShowAllItems] = useState(false);
  const [leftMargin, setLeftMargin] = useState(0);
  const answerBoxRef = useRef(null);

  useEffect(() => {
    if (answer === 'Error retrieving answer' || answer === 'TEST') {
      setChatResponse({
        summary:
          'We encountered an error while retrieving the answer. Please try again later.',
        items: [],
      });
    } else if (answer !== 'Loading') {
      setChatResponse(answer);
    }
  }, [answer, t]);

  const handleLikeClick = () => {
    setIsLiked(true);
    setShowFeedbackPopup(true);
  };

  const handleDislikeClick = () => {
    setIsLiked(false);
    setShowFeedbackPopup(true);
  };

  const closePopup = () => {
    setShowFeedbackPopup(false);
    setFeedback('');
    setIsLiked(null);
  };

  const submitFeedback = async () => {
    // Handle feedback submission logic here
    const rating = isLiked ? 1 : 0;
    try {
      await sendChatFeedback(query, rating, feedback);
      setShowSuccessModal(true);
    } catch (error) {
      setShowErrorModal(true);
    }
    closePopup();
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  const toggleShowItems = () => {
    setShowAllItems(!showAllItems);
  };

  const itemsToShow = showAllItems
    ? chatResponse.items
    : chatResponse.items.slice(0, 3);

  useLayoutEffect(() => {
    if (answerBoxRef.current) {
      setLeftMargin(answerBoxRef.current.offsetWidth);
    }
  }, [chatResponse]);

  return (
    answer && (
      <Box
        width="100%"
        direction="column"
        align="start"
        data-testid="chat-answer-outer-box"
        flex="grow"
      >
        <Box
          width="100%"
          direction="row"
          alignSelf="start"
          align="start"
          gap="small"
          pad={{ right: 'small', bottom: '0', left: '0' }}
          data-testid="chat-answer-inner-box"
          style={{ position: 'relative' }}
          flex="grow"
        >
          {answer === 'Loading' && <MessageLoader />}
          {answer !== 'Loading' && (
            <Box direction="row">
              <Box
                style={{
                  position: 'absolute',
                  top: '35px',
                  left: '-10px', // Adjust this value to position the icon as needed
                  transform: 'translateY(-50%)',
                }}
              >
                <Hpe color="plain" size="large" />
              </Box>
              <Box
                ref={answerBoxRef}
                pad={{ horizontal: 'small', vertical: 'small' }}
                style={{
                  marginLeft: '40px',
                  marginTop: '5px',
                  position: 'relative',
                }}
                round="small"
                data-testid="chat-answer-summary-box"
                background="background-front"
              >
                <Text data-testid="chatTypographyElement">
                  {chatResponse.summary && (
                    <MarkdownComponent data-testid="chat-answer-markdown">
                      {chatResponse.summary}
                    </MarkdownComponent>
                  )}
                  {chatResponse.items && (
                    <ul>
                      {itemsToShow.map((item, index) => (
                        <li key={index}>
                          <Anchor
                            href={`/serverDetail/${item.id}`}
                            label={`${item?.host?.hostname ?? SERVER_NAME_NOT_SET} (${item?.hardware?.serialNumber ?? 'N/A'})`} // eslint-disable-line max-len
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{ textDecoration: 'none' }}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                  {chatResponse.items.length > 3 && (
                    <Anchor
                      onClick={toggleShowItems}
                      label={
                        showAllItems ? t('chat.show_less') : t('chat.show_more')
                      }
                    />
                  )}
                </Text>
              </Box>
            </Box>
          )}
        </Box>
        {chatResponse.summary && (
          <Box
            direction="row"
            gap="xsmall"
            margin={{ top: 'small', left: `${leftMargin}px` }}
            style={{ width: '100%' }}
          >
            <Like border="border-weak" size="small" onClick={handleLikeClick} />
            <Dislike
              border="border-weak"
              size="small"
              onClick={handleDislikeClick}
            />
          </Box>
        )}
        {showFeedbackPopup && (
          <Layer onEsc={closePopup}>
            <Box pad="medium" gap="small" width="medium">
              <TextEmphasis>
                {t('chat.feedback.additional_feedback')}
              </TextEmphasis>
              <Box direction="row" gap="small" align="center">
                <Like
                  color={isLiked ? 'brand' : 'plain'}
                  onClick={() => setIsLiked(true)}
                />
                <Dislike
                  color={isLiked === false ? 'brand' : 'plain'}
                  onClick={() => setIsLiked(false)}
                />
              </Box>
              <TextArea
                placeholder="Additional comments"
                value={feedback}
                onChange={event => setFeedback(event.target.value)}
              />
              <Box
                direction="row"
                gap="small"
                justify="end"
                margin={{ top: 'small' }}
              >
                <Button label="Cancel" onClick={closePopup} />
                <Button label="Submit" primary onClick={submitFeedback} />
              </Box>
            </Box>
          </Layer>
        )}
        {showSuccessModal && (
          <Layer onEsc={closeSuccessModal}>
            <Box pad="medium" gap="small" width="medium">
              <TextEmphasis>
                {t('chat.feedback.thank_you_message')}
              </TextEmphasis>
              <Box direction="row" justify="end">
                <Button
                  primary
                  label={t('chat.feedback.close_btn')}
                  onClick={closeSuccessModal}
                />
              </Box>
            </Box>
          </Layer>
        )}
        {showErrorModal && ( // Error modal
          <Layer onEsc={closeErrorModal}>
            <Box pad="medium" gap="small" width="medium">
              <TextEmphasis>{t('chat.feedback.error_message')}</TextEmphasis>
              <Text>{t('chat.feedback.try_again')}</Text>
              <Box direction="row" justify="end">
                <Button
                  primary
                  label={t('chat.feedback.close_btn')}
                  onClick={closeErrorModal}
                />
              </Box>
            </Box>
          </Layer>
        )}
      </Box>
    )
  );
};

export default ChatAnswer;

ChatAnswer.propTypes = {
  answer: PropTypes.any.isRequired,
};
