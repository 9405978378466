// Copyright 2024 - Hewlett Packard Enterprise Company
import { Anchor, Box, Text } from 'grommet';
import { ShareRounded } from 'grommet-icons/icons/ShareRounded';
import PropTypes from 'prop-types';

const ExternalAnchor = ({ children, href, truncateWidth, ...rest }) => {
  const label = (
    <Box direction="row" align="center" gap="xxsmall">
      <Box width={truncateWidth ? { max: truncateWidth } : {}}>
        {truncateWidth ? (
          <Text truncate="tip">{children}</Text>
        ) : (
          <Text>{children}</Text>
        )}
      </Box>
      <ShareRounded />
    </Box>
  );

  return (
    <Anchor
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      label={label}
      {...rest}
    />
  );
};

ExternalAnchor.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  truncateWidth: PropTypes.string,
};

export default ExternalAnchor;
