// Copyright 2021-2025 - Hewlett Packard Enterprise Company
import React, { lazy, Suspense, useContext } from 'react';
import { Grommet, Box, Grid, ResponsiveContext } from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import AuthProvider, { AuthConsumer } from './providers/AuthProvider';
import HelpProvider from './providers/HelpProvider';
import SidebarProvider, { SidebarContext } from './providers/SidebarProvider';

import LaunchDarklyUser from './components/Flags/LaunchDarklyUser';
import StorageEventHandler from './components/StorageEventHandler/StorageEventHandler';
import LoadingSpinnerWithText from './components/LoadingSpinner/LoadingSpinnerWithText';
import UnauthenticatedApp from './UnauthenticatedApp';
import { REDIRECT_URI_KEY } from './constants/authConst';
import Login from './auth/Login';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import SidebarContent from './components/SidebarContent/SidebarContent';
import Header from './components/Header/Header';
import useFlags from './hooks/useFlags';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

const spinner = (
  <Box align="center" pad="small">
    <LoadingSpinner size="medium" />
  </Box>
);

export default function App() {
  return (
    <SidebarProvider>
      <HelpProvider>
        <Grommet theme={hpe}>
          <AuthProvider>
            <StorageEventHandler />
            <LaunchDarklyUser />
            <UnauthenticatedApp>
              <ContentLayout>
                <Suspense
                  fallback={
                    <Box
                      height="xsmall"
                      margin={{ top: 'small' }}
                      width="100%"
                      align="center"
                      justify="center"
                      alignSelf="center"
                    >
                      <LoadingSpinnerWithText
                        label="Loading..."
                        orientation="horizontal"
                      />
                    </Box>
                  }
                >
                  <AuthConsumer>
                    {({ isAuthenticated, token }) => {
                      if (isAuthenticated()) {
                        return token ? <AuthenticatedApp /> : spinner;
                      }
                      sessionStorage.setItem(
                        REDIRECT_URI_KEY,
                        window.location.pathname,
                      );
                      return <Login />;
                    }}
                  </AuthConsumer>
                </Suspense>
              </ContentLayout>
            </UnauthenticatedApp>
          </AuthProvider>
        </Grommet>
      </HelpProvider>
    </SidebarProvider>
  );
}

const MainContent = ({ children }) => (
  <Box overflow="auto" fill>
    <Box>
      <Header />
    </Box>
    <Box fill>{children}</Box>
  </Box>
);

const ContentLayout = ({ children }) => {
  const flags = useFlags();
  const size = useContext(ResponsiveContext);
  const { isSidebarOpen, isChatOpen } = useContext(SidebarContext);
  const chatBotFlag = flags.T_20241210_53281_CHATBOT_SUPPORT_BOOL();
  const contextualHelpFlag =
    flags.T_20240505_18961_INTEGRATE_CONTEXTUAL_HELP_BOOL();

  const hideMainContent =
    (chatBotFlag || contextualHelpFlag) &&
    isSidebarOpen &&
    ['xsmall', 'small', 'medium'].includes(size);

  // if the chat UI is open always use the smaller size
  const openWidth = isChatOpen || ['large'].includes(size) ? '425px' : '625px';
  let sidebarWidth = hideMainContent ? 'auto' : openWidth;
  if (!isSidebarOpen) {
    sidebarWidth = 'min-content';
  }
  const columns = hideMainContent ? ['auto'] : ['auto', sidebarWidth];
  const areas = hideMainContent ? [['sidebar']] : [['appLayout', 'sidebar']];

  return (
    <Box margin="none" overflow="hidden">
      <Grid columns={columns} rows={['auto']} areas={areas} height="100vh">
        {!hideMainContent && (
          <Box gridArea="appLayout">
            <MainContent>{children}</MainContent>
          </Box>
        )}
        <Box gridArea="sidebar">
          <SidebarContent width={sidebarWidth} />
        </Box>
      </Grid>
    </Box>
  );
};
