// Copyright 2025 - Hewlett Packard Enterprise Company

import { useContext } from 'react';
import { Box } from 'grommet';

import { SidebarContext } from '../../providers/SidebarProvider';

import ChatContainer from '../Chat/ChatContainer';
import Help from '../Help/Help';
import useFlags from '../../hooks/useFlags';

export default function SidebarContent({ width, ...rest }) {
  const flags = useFlags();
  const chatBotFlag = flags.T_20241210_53281_CHATBOT_SUPPORT_BOOL();
  const contextualHelpFlag =
    flags.T_20240505_18961_INTEGRATE_CONTEXTUAL_HELP_BOOL();

  const { isChatOpen, setIsChatOpen } = useContext(SidebarContext);

  return (
    <Box border="left" width={width} fill overflow="auto" {...rest}>
      {chatBotFlag && isChatOpen && <ChatContainer onClose={setIsChatOpen} />}
      {contextualHelpFlag && <Help width={width} />}
    </Box>
  );
}
