// Copyright 2021-2025 - Hewlett Packard Enterprise Company
import axios from 'axios';
import { DateTime } from 'luxon';
import { messaging } from '../messaging/Messaging';
import i18n from '../i18n';
import {
  APPLIANCE_NAME_NOT_SET,
  BASELINE_TYPE,
  BUNDLE_GEN_10,
  BUNDLE_GEN_11,
  BUNDLE_GEN_12,
  BUNDLE_GENERATION_MAP,
  COMPLIANT,
  CONNECTED,
  CONNECTIVITY_STATES,
  DERIVED_STATE_NOT_SET,
  DISABLED_CONDITIONS,
  DISCONNECTED,
  DO_NOT_APPLY_HOTFIX,
  DO_NOT_APPLY_PATCH,
  FIRMWARE_BUNDLE_FILTERKEYS_NOT_SET,
  HEALTH_CRITICAL,
  HEALTH_DISABLED,
  HEALTH_NOT_PRESENT,
  HEALTH_OK,
  HEALTH_READY,
  HEALTH_WARNING,
  ILO6,
  ILO7,
  JOB_NAME_URI_MAP,
  LED_BLINKING,
  LED_LIT,
  LED_OFF,
  MINIMUM_ILO5_VERSION_FLOAT,
  NO_HOTFIXES_AVAILABLE,
  NO_PATCH_BUNDLES_AVAILABLE,
  NOT_ACTIVATED,
  NOT_COMPLIANT,
  NOT_SET,
  PLATFORMS_ELIGIBLE,
  POWER_OFF_STATE,
  POWER_ON_STATE,
  POWER_POWERING_OFF_STATE,
  POWER_POWERING_ON_STATE,
  POWER_RESET_STATE,
  SUBSCRIPTION_STATES,
  SUPPORT_STATES,
  UNKNOWN,
  POWER_UNKNOWN_STATE,
  OV_STATE_UNKNOWN,
  OV_STATE_ADDING,
  OV_STATE_NO_PROFILE_APPLIED,
  OV_STATE_MONITORED,
  OV_STATE_UNMANAGED,
  OV_STATE_REMOVING,
  OV_STATE_REMOVE_FAILED,
  OV_STATE_REMOVED,
  OV_STATE_APPLYING_PROFILE,
  OV_STATE_PROFILE_APPLIED,
  OV_STATE_REMOVING_PROFILE,
  OV_STATE_PROFILE_ERROR,
  OV_STATE_UNSUPPORTED,
  OV_STATE_UPDATING_FIRMWARE,
  FIRMWARE_BUNDLE_FILTERKEYS_NOT_APPLICABLE,
  FIRMWARE_COUNTS_NOT_APPLICABLE_KEY,
  MINIMUM_ILO6_VERSION_FLOAT,
  MINIMUM_APPLIANCE_VERSION,
  BUNDLE_PROLIANT,
  DIRECT_CONNECTED_SERVERS,
  OV_MANAGED_SERVERS,
  ILO_FW_VERSION_COUNTS_UNKNOWN_KEY,
  DEFAULT_SERVER_TABLE_COLUMNS_VIEW,
  SERVER_TABLE_COLUMNS_STORAGE_KEY,
  SPP_MIN_VERSION_LENGTH,
  NOT_ACTIVATED_STATE,
  PLATFORM_PROLIANT_UPPER,
  SECURE_GATEWAY,
  OV_APPLIANCE_TYPE,
  MINIMUM_ILO6_SSO_VERSION_FLOAT,
  MINIMUM_ILO5_SSO_VERSION_FLOAT,
  MINIMUM_ILO7_VERSION_FLOAT,
  MINIMUM_ILO7_SSO_VERSION_FLOAT,
} from '../constants';

import { DEV_ACID_KEY, TENANT_ACID_KEY } from '../constants/authConst';
import { isDevEnvAuth } from './authHelpers';
import { getLocale } from './i18nHelpers';

export function isProliantBundle(bundle) {
  return (
    bundle.platformFamily === undefined ||
    bundle.platformFamily?.toUpperCase() === BUNDLE_PROLIANT
  );
}

export function isProliantPlatform(platformFamily) {
  return platformFamily.toUpperCase() === PLATFORM_PROLIANT_UPPER;
}

export function isDateInPast(dateToCheck) {
  const currentDate = new Date();
  return dateToCheck < currentDate;
}

export const getBundleGeneration = bundle =>
  BUNDLE_GENERATION_MAP[bundle?.bundleGeneration]
    ? BUNDLE_GENERATION_MAP[bundle?.bundleGeneration]
    : '';

export function getLastActivities(data) {
  let activities;
  if (data) {
    activities = [];

    data.forEach(activity => {
      const date = new Date(activity.createdAt);
      const timestamp = `${date.toLocaleDateString(
        getLocale(),
      )} ${date.toLocaleTimeString(getLocale())}`;
      const description = activity.message;
      const action = activity.recommendedAction;

      activities.push({
        topic: activity.title,
        timestamp,
        description,
        action,
      });
    });
  }
  return activities;
}

export function getStateStats(
  stateCounts,
  iloSecurityStatusCount,
  subscriptionStateCount,
  supportStateCount,
  serversWithUtilizationAlerts,
) {
  const deviceStateStats = {
    not_activated: 0,
    subscription_required: 0,
    subscription_expired: 0,
    disconnected: 0,
    ilo_security_at_risk: 0,
    expired_support: 0,
    utilization_alert_present: 0,
    not_monitored: 0,
  };

  if (stateCounts) {
    if (stateCounts['Not activated']) {
      deviceStateStats.not_activated = stateCounts['Not activated'];
    }
    if (stateCounts['Not connected']) {
      deviceStateStats.disconnected = stateCounts['Not connected'];
    }
    if (stateCounts['Expired support']) {
      deviceStateStats.support_expired = stateCounts['Expired support'];
    }
    if (stateCounts['Not monitored']) {
      deviceStateStats.not_monitored = stateCounts['Not monitored'];
    }
  }

  if (iloSecurityStatusCount) {
    if (iloSecurityStatusCount.RISK || iloSecurityStatusCount.Risk) {
      deviceStateStats.ilo_security_at_risk =
        iloSecurityStatusCount.RISK || iloSecurityStatusCount.Risk;
    }
  }

  if (subscriptionStateCount) {
    if (subscriptionStateCount.REQUIRED) {
      deviceStateStats.subscription_required = subscriptionStateCount.REQUIRED;
    }
    if (subscriptionStateCount.EXPIRED) {
      deviceStateStats.subscription_expired = subscriptionStateCount.EXPIRED;
    }
  }

  if (supportStateCount) {
    if (supportStateCount.EXPIRED) {
      deviceStateStats.expired_support = supportStateCount.EXPIRED;
    }
  }

  if (serversWithUtilizationAlerts) {
    deviceStateStats.utilization_alert_present = serversWithUtilizationAlerts;
  }

  return deviceStateStats;
}

export function getDisabledServerCondition(server) {
  if (server?.serverState_ === 'Not activated') {
    return DISABLED_CONDITIONS.NOT_ACTIVATED;
  }
  if (server?.serverState_ === 'Not assigned') {
    return DISABLED_CONDITIONS.NOT_ASSIGNED;
  }

  if (server?.state?.subscriptionState === SUBSCRIPTION_STATES.REQUIRED) {
    return DISABLED_CONDITIONS.SUBSCRIPTION_REQUIRED;
  }
  if (server?.state?.subscriptionState === SUBSCRIPTION_STATES.EXPIRED) {
    return DISABLED_CONDITIONS.SUBSCRIPTION_EXPIRED;
  }
  return undefined;
}

export function prepareBaselinesForSelect(baselines) {
  return baselines.map((base, index) => {
    let selectName = base.displayName;
    if (!base.isActive) {
      selectName = `${base.displayName} *no longer supported`;
    } else if (base.isLatestBaseline) {
      selectName = `${base.displayName} *latest available`;
    }

    return { selectName, ...base };
  });
}

// Used to populate the bundle filter keys with values
// from the firmware-bundles and counts REST API's.
//
// keys - existing filter keys array
// bundles - items array returned by the firmware-bundles
//   REST API.
// counts - counts object returned for firmwareDisplayName
//   by the counts REST API.
// notSetKey - the key used to filter for resources without
//   a baseline (ex. '' or null)
export function updateBundleFilterKeys(keys, bundles, counts, notSetKey) {
  if (keys && bundles && counts) {
    // Remove existing keys
    keys.splice(0, keys.length);

    // Add keys for active bundles returned by the firmware-bundles REST
    // request.  Also, add a key for each inactive bundle that is set on
    // a resource.  Use the data returned by the counts REST API to
    // determine if a bundle is set.
    bundles.forEach(bundle => {
      if (bundle.isActive || counts[bundle.selfUri]) {
        keys.push({
          label: `${getBundleGeneration(bundle)} ${bundle.displayName}`,
          key: bundle.selfUri,
        });
      }
    });

    keys.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    // Always add the 'Not set' label and the key
    // specified by the notSetKey parameter.  This
    // key comes after the bundle keys.
    keys.push({
      label: FIRMWARE_BUNDLE_FILTERKEYS_NOT_SET,
      key: notSetKey === undefined ? null : notSetKey,
    });

    // If the counts REST API response indicates that
    // some servers do not support firmware update,
    // add the 'Not applicable' key.
    if (counts[FIRMWARE_COUNTS_NOT_APPLICABLE_KEY]) {
      keys.push({
        label: i18n.t('common:api_helpers.not_applicable_filter_label'),
        key: FIRMWARE_BUNDLE_FILTERKEYS_NOT_APPLICABLE,
      });
    }
  }

  return keys;
}

export function updateApplianceBundleFilterKeys(
  keys,
  bundles,
  appliances,
  notSetKey,
) {
  if (keys && bundles) {
    // Remove existing keys
    keys.splice(0, keys.length);
    const keySet = new Set();

    // For each OneView type of appliance, add a key for each available software bundle
    bundles.forEach(bundle => {
      if (
        bundle.name &&
        !keySet.has(bundle.name) &&
        (bundle.applianceType === OV_APPLIANCE_TYPE.VM ||
          bundle.applianceType === OV_APPLIANCE_TYPE.SYNERGY)
      ) {
        keys.push({ label: bundle.name, key: bundle.name });
        keySet.add(bundle.name);
      }
    });

    // For each gateway type of appliance, add a key for each firmwareDisplayName_
    appliances.forEach(appliance => {
      if (
        appliance.applianceType === OV_APPLIANCE_TYPE.GATEWAY &&
        appliance.firmwareDisplayName_ &&
        !keySet.has(appliance.firmwareDisplayName_)
      ) {
        keys.push({
          label: appliance.firmwareDisplayName_,
          key: appliance.firmwareDisplayName_,
        });
        keySet.add(appliance.firmwareDisplayName_);
      }
    });

    keys.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    keys.push({
      label: FIRMWARE_BUNDLE_FILTERKEYS_NOT_SET,
      key: notSetKey === undefined ? null : notSetKey,
    });
  }

  return keys;
}

export function getBaselinePatchSelection(baseline, baselinePatchSelections) {
  let base;
  let patch;

  if (baseline?.type === 'base') {
    base = baseline;
  } else if (baselinePatchSelections) {
    patch = baseline;
    base = baselinePatchSelections.find(
      item => item.selfUri === patch.hotfixBaseUri,
    );
  }

  const baseSelection = base
    ? baselinePatchSelections.find(item => item.id === base.id)
    : undefined;
  let patchSelection =
    patch && baseSelection
      ? baseSelection.patchSelections.find(item => item.id === patch.id)
      : undefined;

  if (baseSelection && !patchSelection) {
    // Pre-select either 'Do not apply a patch' or
    // 'No patches available' if only a baseline is set
    // and a patch isn't set.
    patchSelection = baseSelection.patchSelections.find(
      item =>
        item.selectName === DO_NOT_APPLY_HOTFIX ||
        item.selectName === DO_NOT_APPLY_PATCH ||
        item.selectName === NO_HOTFIXES_AVAILABLE ||
        item.selectName === NO_PATCH_BUNDLES_AVAILABLE,
    );
  }

  return { baseSelection, patchSelection };
}

// Returns the f/w baseline update selection for a
// firmware update quick action on the firmware page.
// The selection is used to populate the firmware
// update form values.
export function getLatestBaselineSelection(baseline) {
  const selection = {};

  function setSelection(key, value) {
    if (value.type === BASELINE_TYPE) {
      selection[`${key}Baseline`] = value;
    } else {
      selection[`${key}Patch`] = value;
    }
  }

  if (baseline?.bundleGeneration === BUNDLE_GEN_10) {
    setSelection('gen10', baseline);
  }
  if (baseline?.bundleGeneration === BUNDLE_GEN_11) {
    setSelection('gen11', baseline);
  }
  if (baseline?.bundleGeneration === BUNDLE_GEN_12) {
    setSelection('gen12', baseline);
  }

  return selection;
}

// Returns true if the item identified by the id parameter is
// selected.  The selected, unselected, and allSelected parameters
// are available from the selection context.  This method is used
// by isItemSelected() in the selection context.
export function isSelected(id, selected, unselected, allSelected) {
  if (allSelected && !unselected.includes(id)) {
    return true;
  }
  if (!allSelected && selected.includes(id)) {
    return true;
  }
  return false;
}

function getJobTypeName(jobTemplateName) {
  let jobTypeName = 'Background task';

  if (jobTemplateName === 'Restart') {
    jobTypeName = 'Reset';
  } else if (jobTemplateName === 'PowerOff') {
    jobTypeName = 'Power off';
  } else if (jobTemplateName === 'PowerOn') {
    jobTypeName = 'Power on';
  } else if (jobTemplateName === 'FirmwareUpdate') {
    jobTypeName = 'Firmware update';
  }

  return jobTypeName;
}

export function isOffline(device) {
  return device.hardware?.powerState === 'OFF';
}

export function isNotActivated(device) {
  let connectedModifiedAt;

  // Support both the cobalt and doorway server DTO's
  if (device && device.state && typeof device.state === 'object') {
    connectedModifiedAt = device.state?.connectedModifiedAt;
  } else {
    connectedModifiedAt = device?.connectedModifiedAt;
  }

  if (device?.productId === 'oneview' || device?.productId === 'gateway') {
    if (device?.state === 'not activated') connectedModifiedAt = false;
    else connectedModifiedAt = true;
  }

  return !connectedModifiedAt;
}

export function isNotConnected(device) {
  let connected;

  // Support both the cobalt and doorway server DTO's
  if (device && device.state && typeof device.state === 'object') {
    connected = device.state?.connected;
  } else if (CONNECTIVITY_STATES.includes(device.serverState_)) {
    connected = true;
  }

  if (device?.productId === 'oneview' || device?.productId === 'gateway') {
    if (device?.state_ === 'Not connected') connected = false;
    else connected = true;
  }

  return !connected;
}

export function requiresSubscription(device) {
  let subscriptionState;

  // Support both the cobalt and doorway server DTO's
  if (device && device.state && typeof device.state === 'object') {
    subscriptionState = device.state.subscriptionState;
  } else {
    subscriptionState = device?.subscriptionState;
  }
  const reqd =
    subscriptionState === SUBSCRIPTION_STATES.REQUIRED ||
    subscriptionState === SUBSCRIPTION_STATES.REQUIRED.toUpperCase();
  // console.log('### Required= ' + reqd +'  val= ' + subscriptionState + '  ###')

  return reqd;
}

export function requiresSupport(device) {
  let supportState;

  if (device && device.state && typeof device.state === 'object') {
    supportState = device.state.supportState;
  }

  const reqd =
    supportState === SUPPORT_STATES.REQUIRED ||
    supportState === SUPPORT_STATES.REQUIRED.toUpperCase();

  return reqd;
}

export function expiredSubscription(device) {
  let subscriptionState;

  // Support both the cobalt and doorway server DTO's
  if (device && device.state && typeof device.state === 'object') {
    subscriptionState = device.state.subscriptionState;
  } else {
    subscriptionState = device?.subscriptionState;
  }
  const exprd =
    subscriptionState === SUBSCRIPTION_STATES.EXPIRED ||
    subscriptionState === SUBSCRIPTION_STATES.EXPIRED.toUpperCase();
  return exprd;
}

export function isTerminalJobState(jobState) {
  const lowerJobState = jobState ? jobState.toLowerCase() : '';
  return lowerJobState === 'complete' || lowerJobState === 'error';
}

// check if the device has os install job in progress
// and also if the job is able to complete by user
// if yes then open preflight modal to complete the job
// else open respective action modal.
export const hasActiveOSJob = device =>
  device?.jobContextData?.isMonitoringForOsJobCompletion === 'True' ||
  device?.job_?.data?.isMonitoringForOsJobCompletion === true ||
  device?.job_?.data?.isMonitoringForOsJobCompletion === 'True';

export function hasActiveJob(device) {
  let isActiveJob = false;

  // cobalt format- UI retrieved active job and glued it into the DTO
  if (device.activeJob) {
    isActiveJob = true;
  } else if (device.job_?.state && !isTerminalJobState(device.job_.state)) {
    // crossroads format- eagle populated active job during transform
    isActiveJob = true;
  }

  // if os job is running and manually completion of that job is initiated
  // by user then wont block the other action. mark activejob as false.
  if (
    device.state_ === 'OS image installation in progress' &&
    hasActiveOSJob(device) &&
    device?.explicitCompleteOsJob
  ) {
    isActiveJob = false;
  }
  return isActiveJob;
}

export function hasStalledJob(device) {
  const jobState = device.job_?.state;
  const lowerJobState = jobState ? jobState.toLowerCase() : '';
  return lowerJobState === 'stalled';
}

export function hasDuplicateJob(device, jobTemplateUri) {
  let isDuplicateJob = false;

  // only match the job template ids
  // since it is possible to have differing versions of the same job template
  if (
    hasActiveJob(device) &&
    device.job_?.jobTemplateUri?.split('/').pop() ===
      jobTemplateUri?.split('/').pop()
  )
    isDuplicateJob = true;

  if (
    device.pendingJobs_?.some(
      job =>
        job.jobTemplateUri?.split('/').pop() ===
        jobTemplateUri?.split('/').pop(),
    )
  )
    isDuplicateJob = true;

  return isDuplicateJob;
}

export function getActionState(device, jobTemplateUri, t) {
  if (
    hasActiveJob(device) &&
    device.job_?.jobTemplateUri?.split('/').pop() ===
      jobTemplateUri?.split('/').pop()
  )
    return t('preflight_modal.state.in_progress');

  if (
    device.pendingJobs_?.some(
      job =>
        job.jobTemplateUri?.split('/').pop() ===
        jobTemplateUri?.split('/').pop(),
    )
  )
    return t('preflight_modal.state.pending');

  return device.state_;
}

export function hasPendingJobs(device) {
  if (device.pendingJobs_) return true;
  return false;
}

export function getInprogressOrPendingJob(device) {
  // It may happen sometimes that phoenix may not update
  // the server's state with next job in progress, we will show first pending job from queue.
  if (!hasActiveJob(device) && hasPendingJobs(device))
    return device.pendingJobs_[0].displayName_;

  return device.state_;
}

export function isPlatformIneligible(device) {
  return !(
    PLATFORMS_ELIGIBLE.includes(device?.platformFamily?.toUpperCase()) ||
    device?.productId === 'oneview'
  );
}

export function isIdInProductIdList(id, productList) {
  if (!productList) {
    return false;
  }
  return productList.includes(id);
}

export function isUnsupportedModelAndGen(model, unsupportedList) {
  if (unsupportedList?.length > 0) {
    model = model?.toLowerCase();
    for (let i = 0; i < unsupportedList?.length; i++) {
      const pattern = unsupportedList[i]?.model_regex;
      if (pattern && new RegExp(pattern).test(model)) {
        return true;
      }
    }
  }
  return false;
}
// Comparison function for sorting bundles by version
// Returns 0 if version are the same
// Returns < 0 if a is more recent than b
// Returns > 0 if b is more recent than a
export const compareBundles = (a, b) => {
  const aVersion = a.split('-')[0];
  const bVersion = b.split('-')[0];
  if (!a && !b) return 0;
  if (!a && b) return 1;
  if (a && !b) return -1;
  // the release version has a format like 8.60.1
  const aVersParts = aVersion.split('.');
  const bVersParts = bVersion.split('.');
  if (
    aVersParts.length >= SPP_MIN_VERSION_LENGTH &&
    bVersParts.length >= SPP_MIN_VERSION_LENGTH
  ) {
    const totalOctets =
      aVersParts.length === bVersParts.length
        ? aVersParts.length
        : SPP_MIN_VERSION_LENGTH;
    for (let i = 0; i < totalOctets; i++) {
      const aNum = parseInt(aVersParts[i], 10);
      const bNum = parseInt(bVersParts[i], 10);
      if (Number.isNaN(aNum) || Number.isNaN(bNum)) {
        break;
      }
      if (aNum !== bNum) {
        return bNum - aNum;
      }
    }
  }
  // versions are  malformed or equal
  return 0;
};

export function isVersionIneligible(
  device,
  minAppVersion = MINIMUM_APPLIANCE_VERSION,
) {
  if (device?.productId === 'oneview') {
    return compareBundles(device.version, minAppVersion) > 0;
  }
  return false;
}

// export const hasNoVolumes = device =>
//   !(device.volumes && device.volumes.length > 0);

export function hasNoVolumes(device) {
  const inventoryArray = Object.values(device.storageInventory_ || {});
  for (let i = 0; i < inventoryArray.length; i++) {
    const volumes = inventoryArray[i]?.volumes;
    if (volumes && volumes.length > 0) {
      return false;
    }
  }
  return true;
}

export function isServerIneligibleForActions(
  device,
  excludedProducts,
  scheduledAndPendingJobsFlag,
) {
  // eslint-disable-next-line no-unneeded-ternary
  return (
    // check for active job only when flag is false
    (scheduledAndPendingJobsFlag ? false : hasActiveJob(device)) ||
    isNotActivated(device) ||
    isNotConnected(device) ||
    requiresSubscription(device) ||
    expiredSubscription(device) ||
    isPlatformIneligible(device) ||
    isIdInProductIdList(device, excludedProducts) ||
    isVersionIneligible(device)
  );
}

export const getIloVersionForUpdate = (server, doNotCastToFloat = false) => {
  let iloVersion;

  if (server?.firmwareInventory) {
    const ilo = server.firmwareInventory.find(inventory =>
      inventory.name.includes('iLO'),
    );
    iloVersion = ilo ? ilo?.version.split(' ')[0] : undefined;
  }

  if (iloVersion && !doNotCastToFloat) {
    // cast to a number
    iloVersion = parseFloat(iloVersion);
  }

  return iloVersion;
};

export const getIloGeneration = server => {
  let iloGeneration;

  if (server && server?.firmwareInventory) {
    const ilo = server.firmwareInventory.find(inventory =>
      inventory.name.includes('iLO'),
    );
    iloGeneration = ilo ? ilo?.name : undefined;
  }
  return iloGeneration;
};

// If ilo version is unknown, this method will return true.
// If ilo version is known, this method will check for valid ilo version.
export const isValidIloVersionIfKnown = device => {
  const iloVersion = getIloVersionForUpdate(device);
  if (iloVersion) {
    const iloGen = getIloGeneration(device);

    // otherwise check ilo minimum version
    let minIloVersion;
    if (iloGen === ILO7) {
      minIloVersion = MINIMUM_ILO7_VERSION_FLOAT;
    } else if (iloGen === ILO6) {
      minIloVersion = MINIMUM_ILO6_VERSION_FLOAT;
    } else {
      minIloVersion = MINIMUM_ILO5_VERSION_FLOAT;
    }
    return iloVersion >= minIloVersion;
  }
  return true;
};

// If ilo version is unknown, this method will return true.
// If ilo version is known, this method will check for valid ilo version.
export const isValidIloSsoVersionIfKnown = device => {
  const iloVersion = getIloVersionForUpdate(device);
  if (iloVersion) {
    const iloGen = getIloGeneration(device);

    // otherwise check ilo minimum version
    let minIloVersion;
    if (iloGen === ILO7) {
      minIloVersion = MINIMUM_ILO7_SSO_VERSION_FLOAT;
    } else if (iloGen === ILO6) {
      minIloVersion = MINIMUM_ILO6_SSO_VERSION_FLOAT;
    } else {
      minIloVersion = MINIMUM_ILO5_SSO_VERSION_FLOAT;
    }
    return iloVersion >= minIloVersion;
  }
  return true;
};

export function isILOVersionSelected(version, selection) {
  const iLOGeneration = selection.map(device => getIloGeneration(device));

  return iLOGeneration.includes(version);
}

export const getILOVersion = (servers, generation, iLOFwParams) => {
  let iLOVersion = '';
  let iLOFwDetails = [];
  iLOFwDetails = iLOFwParams.find(firmwareDetails =>
    firmwareDetails.comp_name.includes(generation),
  );
  const genServer = servers.find(
    server => generation === getIloGeneration(server),
  );
  if (genServer?.isIloFwUpdateTestVersionSupported) {
    iLOVersion = iLOFwDetails?.test_version;
  } else {
    iLOVersion = iLOFwDetails?.version;
  }

  return iLOVersion;
};

export const parseIloGeneration = iloVersion => {
  if (!iloVersion) {
    return 'Unknown';
  }
  const iloGen = iloVersion.match(/iLO\s\d+/g);
  return iloGen ? iloGen[0] : 'Unknown';
};

// Comparison function for sorting iLO versions
// Returns 0 if versions are the same
// Returns < 0 if a is more recent than b
// Returns > 0 if b is more recent than a
function compareIloVersions(a, b) {
  if (a.numericVersion === b.numericVersion) {
    // Attempt to compare dates if versions are the same
    if (a.iloDate && !b.iloDate) {
      return -1;
    }
    if (b.iloDate && !a.iloDate) {
      return 1;
    }
    if (a.iloDate !== b.iloDate) {
      // Attempt to parse the dates
      const aDate = DateTime.fromFormat(a.iloDate, 'LLL dd yyyy');
      const bDate = DateTime.fromFormat(b.iloDate, 'LLL dd yyyy');
      if (aDate.isValid && !bDate.isValid) {
        return -1;
      }
      if (bDate.isValid && !aDate.isValid) {
        return 1;
      }
      if (!aDate.isValid && !bDate.isValid) {
        return 0;
      }
      if (aDate > bDate) {
        return -1;
      }
      if (bDate > aDate) {
        return 1;
      }
      return 0;
    }
    return 0;
  }
  return b.numericVersion - a.numericVersion;
}

// Comparison function for sorting iLO generations
// Returns 0 if generations are the same
// Returns < 0 if a is more recent than b
// Returns > 0 if b is more recent than a
function compareIloGenerations(a, b) {
  return b.numericIloGen - a.numericIloGen;
}

// Returns a list (array) of iLO generations found in the
// iloFirmwareVersion_ counts returned by the server counts
// REST API call.
//
// Each iLO generation array entry contains an object with
// an iloGen property (ex. "iLO 5", "iLO 6", "UNKNOWN"), a
// numericIloGen property used for sorting (ex. 5, 6, -1),
// and an iloVersions array.
export const getIloGenerationFirmwareList = iloFwVersionCounts => {
  // A temporary object used to process information in the
  // iloFirmwareVersion_ counts returned by the server counts REST API.
  // The keys are the iLO generations (ex. "iLO 5", "iLO 6", "UNKNOWN").
  // The value for each key is an array iLO version objects.
  const iloGenerations = {};

  const addToGenerationVersions = versionInfo => {
    const { iloGen } = versionInfo;
    if (!iloGenerations[iloGen]) {
      iloGenerations[iloGen] = [];
    }
    iloGenerations[iloGen].push(versionInfo);
  };

  if (iloFwVersionCounts) {
    Object.keys(iloFwVersionCounts).forEach(fullVersion => {
      if (fullVersion === ILO_FW_VERSION_COUNTS_UNKNOWN_KEY) {
        addToGenerationVersions({
          fullVersion: i18n.t('common:api_helpers.unknown_ilo_fw_version'),
          count: iloFwVersionCounts[fullVersion],
          numericVersion: -1, // Unknown is shown last
          key: fullVersion,
          iloGen: ILO_FW_VERSION_COUNTS_UNKNOWN_KEY,
          numericIloGen: -1, // Unknown is shown last
          iloVersionAndDate: i18n.t(
            'common:api_helpers.not_set_ilo_version_and_date',
          ),
          iloDate: '',
        });
      } else {
        const versionParts = fullVersion?.split?.(' ');
        const iloGen =
          versionParts && versionParts.length > 1
            ? `${versionParts[0]} ${versionParts[1]}`
            : ILO_FW_VERSION_COUNTS_UNKNOWN_KEY;
        const numericIloGen =
          versionParts && versionParts.length > 1
            ? parseInt(versionParts[1], 10)
            : 0;
        const numericVersion =
          versionParts && versionParts.length > 2
            ? parseFloat(versionParts[2])
            : 0;
        const iloVersionAndDate =
          versionParts && versionParts.length > 2
            ? [...versionParts].slice(2).join(' ')
            : '';
        const iloDate =
          versionParts && versionParts.length > 3
            ? [...versionParts].slice(3).join(' ')
            : '';
        addToGenerationVersions({
          fullVersion,
          count: iloFwVersionCounts[fullVersion],
          numericVersion: Number.isNaN(numericVersion) ? 0 : numericVersion,
          key: fullVersion,
          iloGen,
          numericIloGen: Number.isNaN(numericIloGen) ? 0 : numericIloGen,
          iloVersionAndDate,
          iloDate,
        });
      }
    });
  }

  // Sort the iLO versions for each generation
  Object.keys(iloGenerations).forEach(iloGenKey => {
    iloGenerations[iloGenKey].sort(compareIloVersions);
  });

  // Create an array of iLO generation objects.
  // Each object contains an iloGen property, a numericIloGen
  // property, and an array of iLO versions.
  const iloGenerationFirmwareList = Object.keys(iloGenerations)
    .map(iloGenKey => ({
      iloGen: iloGenKey,
      numericIloGen:
        iloGenerations[iloGenKey] && iloGenerations[iloGenKey].length > 0
          ? iloGenerations[iloGenKey][0].numericIloGen
          : 0,
      iloVersions: iloGenerations[iloGenKey],
    }))
    .sort(compareIloGenerations);

  return iloGenerationFirmwareList;
};

// Used to populate the iLO firmware version filter keys with
// values from the iloFirmwareVersion_ server counts REST API.
//
// keys - existing filter keys array
// counts - counts object returned for iloFirmwareVersion_
//   by the server counts REST API.
export function updateIloVersionFilterKeys(keys, counts) {
  if (keys && counts) {
    // Remove existing keys
    keys.splice(0, keys.length);

    // Add keys for values returned by the server counts REST API
    let sortedIloFwVersionList = [];

    const genList = getIloGenerationFirmwareList(counts);
    genList.forEach(genObj => {
      sortedIloFwVersionList = sortedIloFwVersionList.concat(
        genObj.iloVersions,
      );
    });

    sortedIloFwVersionList.forEach(versionInfo => {
      keys.push({ label: versionInfo.fullVersion, key: versionInfo.key });
    });
  }
  return keys;
}

/*
 * not activated - managed is true and connection is false and connection time is not set
 * not connected - managed is true and connection is false
 *
 * Jobs: connected may have jobs- can only have 1 job in non terminal state
 * job in progress - like "power on in progress".  State is "running"
 * job is stalled - "power on is stalled".  The state will reflect "stalled"
 *
 * most recent failed or completed job in the last 24 hours:
 * need to show the type of job and the outcome.
 *
 * connected - managed and connection true
 */
export function getDeviceState(device, resourceJobs) {
  if (!device) {
    return DERIVED_STATE_NOT_SET;
  }

  if (isNotActivated(device)) {
    return 'Not activated';
  }

  if (isNotConnected(device)) {
    return 'Not connected';
  }

  if (requiresSubscription(device)) {
    return 'Subscription required';
  }

  if (expiredSubscription(device)) {
    return 'Subscription expired';
  }

  if (requiresSupport(device)) {
    return 'Support expired';
  }

  if (resourceJobs) {
    let job;

    if (resourceJobs.active) {
      job = resourceJobs.active;
    } else if (resourceJobs.recent) {
      job = resourceJobs.recent;
    }

    if (job) {
      const jobTypeName = getJobTypeName(JOB_NAME_URI_MAP[job.jobTemplateUri]);

      if (job.state === 'Running') {
        if (
          jobTypeName === 'Firmware update' &&
          job.status &&
          job.status.startsWith('Step')
        ) {
          // Add step count to firmware update in progress state
          return `${job.status} ${jobTypeName.toLowerCase()} in progress`;
        }
        return `${jobTypeName} in progress`;
      }

      if (job.state === 'Stalled') {
        return `${jobTypeName} is stalled`;
      }

      if (
        (job.state === 'Complete' || job.state === 'Error') &&
        job.resultCode === 'Failure'
      ) {
        return `${jobTypeName} failed`;
      }

      if (job.state === 'Complete' && job.resultCode === 'Success') {
        return `${jobTypeName} completed`;
      }

      if (job.state === 'Error' && job.resultCode === null) {
        return `${jobTypeName} encountered an error`;
      }
    }
  }

  return 'Connected';
}

export function getGroupJobState(job) {
  if (job) {
    if (job.state === 'RUNNING') {
      return `${job.displayName_} in progress`;
    }

    if (job.state === 'STALLED') {
      return `${job.displayName_} is stalled`;
    }

    if (job.state === 'COMPLETE') {
      return `${job.displayName_} completed`;
    }

    if (job.state === 'ERROR') {
      return `${job.displayName_} encountered an error`;
    }
  }

  return NOT_SET;
}

export function getApplianceType(appliance) {
  if (appliance?.applianceType) {
    if (appliance?.applianceType === 'VM') {
      return `OneView ${appliance?.applianceType} appliance`;
    }

    if (appliance?.applianceType === 'SYNERGY') {
      const synergyCaps =
        appliance?.applianceType.charAt(0) +
        appliance?.applianceType.slice(1).toLowerCase();
      return `OneView ${synergyCaps} appliance`;
    }

    if (appliance?.applianceType === 'GATEWAY') {
      return 'Secure gateway';
    }
  }
  return NOT_SET;
}

export function getApplianceStateStats(stateCountsObj) {
  const deviceStateStats = {
    connected: 0,
    disconnected: 0,
    not_activated: 0,
    total: 0,
  };
  const stateCounts = stateCountsObj?.state;
  if (stateCounts) {
    if (stateCounts[CONNECTED]) {
      deviceStateStats.connected = stateCounts[CONNECTED];
    }
    if (stateCounts[DISCONNECTED]) {
      deviceStateStats.disconnected = stateCounts[DISCONNECTED];
    }
    if (stateCounts[NOT_ACTIVATED]) {
      deviceStateStats.not_activated = stateCounts[NOT_ACTIVATED];
    }
    deviceStateStats.total = stateCountsObj.total;
  }
  return deviceStateStats;
}

function getStateCounts(deviceStateStats, stateCounts) {
  if (stateCounts[CONNECTED]) {
    deviceStateStats.connected = stateCounts[CONNECTED];
  }
  if (stateCounts[DISCONNECTED]) {
    deviceStateStats.disconnected = stateCounts[DISCONNECTED];
  }
  if (stateCounts[NOT_ACTIVATED]) {
    deviceStateStats.not_activated = stateCounts[NOT_ACTIVATED];
  }
  return deviceStateStats;
}

function getComplianceCounts(deviceStateStats, complianceCounts) {
  if (complianceCounts[COMPLIANT]) {
    deviceStateStats.compliant = complianceCounts[COMPLIANT];
  }
  if (complianceCounts[NOT_COMPLIANT]) {
    deviceStateStats.not_compliant = complianceCounts[NOT_COMPLIANT];
  }
  if (complianceCounts[UNKNOWN]) {
    deviceStateStats.unknown = complianceCounts[UNKNOWN];
  }
}
export function getApplianceStateSummary(stateCountsObj) {
  const deviceStateStats = {
    connected: 0,
    disconnected: 0,
    not_activated: 0,
    compliant: 0,
    not_compliant: 0,
    unknown: 0,
    total: 0,
  };
  const stateCounts = stateCountsObj?.state;
  const complianceCounts = stateCountsObj.complianceState_;
  if (stateCounts) {
    getStateCounts(deviceStateStats, stateCounts);
    getComplianceCounts(deviceStateStats, complianceCounts);
    deviceStateStats.total = stateCountsObj.total;
  }
  return deviceStateStats;
}

export function getApplianceVersion(appliance) {
  if (!appliance?.version) {
    return NOT_SET;
  }
  return appliance.version;
}

export function getDeviceTotal(healthCounts) {
  let total = 0;
  if (healthCounts && healthCounts.counts) {
    Object.keys(healthCounts.counts).forEach(key => {
      total += healthCounts.counts[key];
    });
  }
  return total;
}

export function getDisabledApplianceCondition(appliance) {
  if (
    appliance &&
    appliance.state &&
    appliance.state.toLowerCase() === NOT_ACTIVATED
  ) {
    return DISABLED_CONDITIONS.NOT_ACTIVATED;
  }
  if (
    appliance &&
    appliance.state &&
    appliance.state.toLowerCase() === DISCONNECTED
  ) {
    return DISABLED_CONDITIONS.NOT_CONNECTED;
  }
  return undefined;
}

export const isValidUUID = input => {
  // Regular expression to check if the given input is a valid UUID
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return uuidRegex.test(input);
};

export function getLocalDateTime(timestamp) {
  let lastChecked = NOT_SET;

  if (timestamp) {
    const date = new Date(timestamp).toLocaleDateString(getLocale());
    const time = new Date(timestamp).toLocaleTimeString(getLocale());
    lastChecked = `${date} ${time}`;
  }

  return lastChecked;
}

export function isApplianceDisabled(appliance) {
  return appliance?.state_ === NOT_ACTIVATED_STATE;
}

/*
 * Install or update access token for Messaging
 */
export function installMessagingToken(token) {
  messaging.installToken(token);
}

export function forceNewWebsocket() {
  messaging.closeAndReconnect();
}

/*
 * Returns the most recent job with the specified job template
 * from an array of jobs.
 */
export function getMostRecentJobForTemplate(jobs, jobTemplateUri) {
  let mostRecentJob;
  let mostRecentJobModifiedAt;
  jobs.forEach(job => {
    if (job.jobTemplateUri === jobTemplateUri) {
      const modifiedAt = new Date(job.modifiedAt);
      if (
        !mostRecentJob ||
        modifiedAt.getTime() > mostRecentJobModifiedAt.getTime()
      ) {
        mostRecentJob = job;
        mostRecentJobModifiedAt = modifiedAt;
      }
    }
  });
  return mostRecentJob;
}

export const getTokenFromStorage = sessionKey => {
  const oidcStorage = JSON.parse(sessionStorage.getItem(sessionKey));
  const token =
    !!oidcStorage && !!oidcStorage.access_token
      ? oidcStorage.access_token
      : null;
  return token;
};

/*
 * Install axios request interceptor which adds the access token to the Auth
 * header in all outgoing requests
 */
export function initAuthInterceptors(sessionKey) {
  axios.interceptors.request.use(
    config => {
      if (config?.omitAuth) {
        return config;
      }
      // install the token if it exists in local storage
      const token = getTokenFromStorage(sessionKey);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        delete config.headers.Authorization;
      }

      const tenantAcid = localStorage.getItem(TENANT_ACID_KEY);
      if (config.forceTenantAcid) {
        config.headers['tenant-acid'] = config.forceTenantAcid;
      } else if (tenantAcid && !config.omitTenantAcid) {
        config.headers['tenant-acid'] = tenantAcid;
      } else {
        delete config.headers['tenant-acid'];
      }

      const devEnvAcid = localStorage.getItem(DEV_ACID_KEY);
      if (devEnvAcid && isDevEnvAuth()) {
        config.headers['acid-dev-override'] = devEnvAcid;
      } else {
        delete config.headers['acid-dev-override'];
      }

      return config;
    },
    error => {
      Promise.reject(error);
    },
  );
}

/*
 * install response interceptor to handle 401 responses.
 */
export function initUnauthorizedInterceptor() {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (
        (error && error.response && error.response.status === 401) ||
        (error && error.data && error.data.message === '401 Unauthorized')
      ) {
        // eslint-disable-next-line no-console
        console.log('Access token is missing or invalid.');
        // Call logout for now. May enhance it with a modal dialog later.
        window.location = '/logout';
      }
      return Promise.reject(error);
    },
  );
}

export function initLanguageInterceptors(data) {
  axios.interceptors.request.use(
    config => {
      if (data?.language) {
        config.headers['Accept-Language'] = data.language;
      }
      return config;
    },
    error => {
      Promise.reject(error);
    },
  );
}

// Create an array of tags in the format expected by the
// TextInputSearchDrop component.  If the same tag exists for
// multiple resource types, only add one tag into the array.
// Also, if the list of tags includes different
// tags of the same tag name (ignoring case),
// use the fist tag.
// This function was slightly modified from groupHelpers/prepareTagsForEditor
export function prepareTagsInputSearch(tags) {
  const editorTags = [];
  tags.forEach(value => {
    const nextTag = {
      name: value.key,
      value: value.value,
      label: value.value ? `${value.key} : ${value.value}` : `${value.key}`,
    };
    if (
      !editorTags.find(
        tag =>
          tag.name.toLowerCase() === nextTag.name.toLowerCase() &&
          tag.value.toLowerCase() === nextTag.value.toLowerCase(),
      )
    ) {
      const tagWithSameName = editorTags.find(
        tag => tag.name.toLowerCase() === nextTag.name.toLowerCase(),
      );
      if (tagWithSameName) {
        // Found case insensitive match
        nextTag.name = tagWithSameName.name;
      }
      editorTags.push(nextTag);
    }
  });
  return editorTags;
}

// Create an array of tags in the format expected by the
// TagsEditor component.  If the same tag exists for
// multiple resource types, only add one tag into the array.
// Also, if the list of tags includes different
// capitalizations of the same tag name, only use the first
// capitalization.
export function prepareTagsForEditor(tags) {
  const editorTags = [];
  tags.forEach(value => {
    const nextTag = { name: value.key, value: value.value };
    if (
      !editorTags.find(
        tag =>
          tag.name.toLowerCase() === nextTag.name.toLowerCase() &&
          tag.value.toLowerCase() === nextTag.value.toLowerCase(),
      )
    ) {
      const tagWithSameName = editorTags.find(
        tag => tag.name.toLowerCase() === nextTag.name.toLowerCase(),
      );
      if (tagWithSameName) {
        // Only use one capitialization of the same tag name
        nextTag.name = tagWithSameName.name;
      }
      editorTags.push(nextTag);
    }
  });
  return editorTags;
}

// Create an array of groups in the format expected by the
// TextInputSearchDrop component.
export function prepareGroupsInputSearch(groups) {
  const editorGroups = [];
  groups.forEach(item => {
    const nextGroup = item.isDeleted
      ? item
      : {
          name: item.name,
          id: item.id,
          label: item.name,
          isDeleted: false,
        };
    editorGroups.push(nextGroup);
  });
  return editorGroups;
}

export function hasFetchError(dataFetchError, baselines) {
  return dataFetchError || !baselines || (baselines && baselines.length === 0);
}

export function getResultInfo(response, opName, message) {
  let result;
  if (response && response.status >= 200 && response.status <= 299) {
    result = {
      title: opName,
      message,
      status: 'normal',
    };
  }
  return result;
}

export function getErrorInfo(
  e,
  opName,
  errorMessage,
  authErrorMessage,
  status = 'critical',
) {
  const message = e?.response?.status === 403 ? authErrorMessage : errorMessage;
  return {
    title: opName,
    message,
    status,
  };
}

export function getCardTitle(baseline) {
  switch (baseline?.type) {
    case 'base':
      return i18n.t('common:api_helpers.fw_base_card_title', {
        baseName: baseline.shortName,
        packageName: 'SPP',
      });
    case 'hotfix':
      return i18n.t('common:api_helpers.fw_hotfix_card_title', {
        baseName: baseline.shortName,
      });
    case 'patch':
      return i18n.t('common:api_helpers.fw_patch_card_title', {
        baseName: baseline.shortName,
      });
    default:
      return '';
  }
}

export const collateServersByGroup = (groupIdMap, selectedDevices) => {
  // keyed by the group ID, but also includes the group details
  const groupMap = {};

  selectedDevices.forEach(server => {
    // get the group from the local map, if it doesn't exist create one
    const group = groupMap[server.group_?.id] || {
      group: groupIdMap[server.group_?.id],
      selectedDevices: [],
    };

    // ensure it's initialized into the local map if it's not present
    if (!groupMap[server.group_?.id]) {
      groupMap[server.group_?.id] = group;
    }

    // add the current server to the group
    group.selectedDevices.push(server);
  });

  // return an array of objects containing the group and selectedservers in that group
  return Object.values(groupMap);
};

export const collateAppliancesByGroup = (groupIdMap, selectedAppliances) => {
  // keyed by the group ID, but also includes the group details
  const groupMap = {};

  selectedAppliances.forEach(appliance => {
    // get the group from the local map, if it doesn't exist create one
    const group = groupMap[appliance.group_?.id] || {
      group: groupIdMap[appliance.group_?.id],
      selectedAppliances: [],
    };

    // ensure it's initialized into the local map if it's not present
    if (!groupMap[appliance.group_?.id]) {
      groupMap[appliance.group_?.id] = group;
    }

    // add the current server to the group
    group.selectedAppliances.push(appliance);
  });

  // return an array of objects containing the group and selectedservers in that group
  return Object.values(groupMap);
};

export const hasRestError = resp =>
  !resp || resp instanceof Error || !resp.data;

export function getHardwareStatusStats(statusCountsObj) {
  const hardwareStatusStats = {
    OK: 0,
    Warning: 0,
    Critical: 0,
    Unknown: 0,
    total: 0,
  };

  if (statusCountsObj && statusCountsObj.counts) {
    const statusCounts = statusCountsObj.counts;
    if (statusCounts.OK) {
      hardwareStatusStats.OK = statusCounts.OK;
    }
    if (statusCounts.Warning) {
      hardwareStatusStats.Warning = statusCounts.Warning;
    }
    if (statusCounts.Critical) {
      hardwareStatusStats.Critical = statusCounts.Critical;
    }
    if (statusCounts.Unknown) {
      hardwareStatusStats.Unknown = statusCounts.Unknown;
    }
    hardwareStatusStats.total = getDeviceTotal(statusCountsObj);
  }

  return hardwareStatusStats;
}

export function getJobId(response) {
  let jobId;
  if (response && response.status >= 200 && response.status <= 299) {
    jobId = response.data.id;
  }
  return jobId;
}

export function getApplianceNameWithId(appliance) {
  if (appliance?.name) {
    const endOfId =
      appliance.id.length >= 6
        ? appliance.id.substring(appliance.id.length - 6)
        : appliance.id;
    return i18n.t('common:api_helpers.appl_name_with_id', {
      name: appliance.name,
      endOfId,
    });
  }
  return APPLIANCE_NAME_NOT_SET;
}

// Returns true if this is a OneView connected server
export function isOvServer(server) {
  if (server?.connectionType_) {
    return (
      server?.connectionType_ === OV_MANAGED_SERVERS &&
      server?.oneview?.applianceUri
    );
  }
  return server?.oneview?.applianceUri;
}

// Returns true if this is a OneView appliance
export function isOvAppliance(appliance) {
  return appliance?.productId === 'oneview';
}

// Returns internationalized string for V2 power state
export function getPowerStateDisplayStr(power) {
  if (power === POWER_ON_STATE) return i18n.t('common:api_helpers.power_on');
  if (power === POWER_OFF_STATE) return i18n.t('common:api_helpers.power_off');
  if (power === POWER_POWERING_OFF_STATE)
    return i18n.t('common:api_helpers.power_powering_off');
  if (power === POWER_POWERING_ON_STATE)
    return i18n.t('common:api_helpers.power_powering_on');
  if (power === POWER_RESET_STATE)
    return i18n.t('common:api_helpers.power_reset');
  if (power === POWER_UNKNOWN_STATE)
    return i18n.t('common:api_helpers.power_unknown');
  return NOT_SET;
}

// Returns internationalized string for V2 indicator led
export function getIndicatorLedDisplayStr(indicator) {
  if (indicator === LED_LIT) return i18n.t('common:api_helpers.led_lit');
  if (indicator === LED_BLINKING)
    return i18n.t('common:api_helpers.led_blinking');
  if (indicator === LED_OFF) return i18n.t('common:api_helpers.led_off');
  return NOT_SET;
}

// Returns internationalized string for V2 health summary
export function getHealthSummaryDisplayStr(health) {
  if (health === HEALTH_OK) return i18n.t('common:api_helpers.health_ok');
  if (health === HEALTH_WARNING)
    return i18n.t('common:api_helpers.health_warning');
  if (health === HEALTH_CRITICAL)
    return i18n.t('common:api_helpers.health_critical');
  if (health === HEALTH_READY) return i18n.t('common:api_helpers.health_ready');
  if (health === HEALTH_NOT_PRESENT)
    return i18n.t('common:api_helpers.health_not_present');
  if (health === HEALTH_DISABLED)
    return i18n.t('common:api_helpers.health_disabled');
  return i18n.t('common:api_helpers.health_unknown');
}

// Returns internationalized string for V2 OneView server state
export function getOvServerStateDisplayStr(state) {
  if (state === OV_STATE_UNKNOWN)
    return i18n.t('common:api_helpers.ov_state_unknown');
  if (state === OV_STATE_ADDING)
    return i18n.t('common:api_helpers.ov_state_adding');
  if (state === OV_STATE_NO_PROFILE_APPLIED)
    return i18n.t('common:api_helpers.ov_state_no_profile_applied');
  if (state === OV_STATE_MONITORED)
    return i18n.t('common:api_helpers.ov_state_monitored');
  if (state === OV_STATE_UNMANAGED)
    return i18n.t('common:api_helpers.ov_state_unmanaged');
  if (state === OV_STATE_REMOVING)
    return i18n.t('common:api_helpers.ov_state_removing');
  if (state === OV_STATE_REMOVE_FAILED)
    return i18n.t('common:api_helpers.ov_state_remove_failed');
  if (state === OV_STATE_REMOVED)
    return i18n.t('common:api_helpers.ov_state_removed');
  if (state === OV_STATE_APPLYING_PROFILE)
    return i18n.t('common:api_helpers.ov_state_applying_profile');
  if (state === OV_STATE_PROFILE_APPLIED)
    return i18n.t('common:api_helpers.ov_state_profile_applied');
  if (state === OV_STATE_REMOVING_PROFILE)
    return i18n.t('common:api_helpers.ov_state_removing_profile');
  if (state === OV_STATE_PROFILE_ERROR)
    return i18n.t('common:api_helpers.ov_state_profile_error');
  if (state === OV_STATE_UNSUPPORTED)
    return i18n.t('common:api_helpers.ov_state_unsupported');
  if (state === OV_STATE_UPDATING_FIRMWARE)
    return i18n.t('common:api_helpers.ov_state_updating_firmware');
  return state;
}

export async function getAllPages(getPageFunc, maxPerPage = 100) {
  const items = [];
  let fetches = [];
  let total = 0;
  let count = 0;

  const firstFetch = await getPageFunc(0).catch(e => {
    throw e;
  });

  if (firstFetch?.data?.total) {
    total = firstFetch.data.total;
  }
  if (firstFetch?.data?.items?.length) {
    count = firstFetch.data.items.length;
  }

  if (total > count) {
    const promiseArray = [];
    const numFetches = Math.ceil(total / maxPerPage);
    for (let i = 1; i < numFetches; i++) {
      promiseArray.push(getPageFunc(i * maxPerPage));
    }

    fetches = await Promise.all(
      promiseArray.map(p =>
        p.catch(e => {
          throw e;
        }),
      ),
    ).then(values => values);
  }

  fetches.unshift(firstFetch);

  fetches.forEach(fetch => {
    if (fetch?.data?.items) {
      fetch.data.items.forEach(rsc => {
        items.push(rsc);
      });
    }
  });

  return items;
}

export function getIdFromUri(uri) {
  return uri?.slice(uri.lastIndexOf('/') + 1);
}

export function showDeviceDisabledModal(device) {
  return (
    device?.healthSummary_ === 'DISABLED' &&
    (device?.serverState_ === 'Not activated' ||
      device?.serverState_ === 'Not assigned' ||
      device?.state?.subscriptionState === SUBSCRIPTION_STATES.REQUIRED ||
      device?.state?.subscriptionState === SUBSCRIPTION_STATES.EXPIRED)
  );
}

export const needWelcomeMsg = (deviceCount, deviceStates) =>
  deviceCount === 0 ||
  (deviceCount > 0 && deviceStates?.not_activated === deviceCount);

export const getUniqueApplianceNames = appliances => {
  const allAppliances = [];
  const uniqueNames = [];
  appliances.forEach(item => {
    allAppliances.push(item.name);
    if (uniqueNames.includes(item.name)) {
      // duplicate name found
      // update the name for matching entry
      const index = allAppliances.indexOf(item.name);
      const applianceId = appliances[index].id;
      const last6Chars = applianceId.substring(applianceId.length - 6);
      uniqueNames[index] =
        `${uniqueNames[index]} (ID ending with ${last6Chars})`;
      // update the new entry
      const last6CharOfId = item.id.substring(item.id.length - 6);
      uniqueNames.push(`${item.name} (ID ending with ${last6CharOfId})`);
    } else {
      // no duplicate entry
      uniqueNames.push(item.name);
    }
  });
  return uniqueNames;
};

export const getAvailableServerLocations = locationsObject => {
  const availableLocations = {};
  if ('items' in locationsObject) {
    locationsObject.items.forEach(
      item => (availableLocations[item.name] = item.id),
    );
  }
  return availableLocations;
};

export const getAvailableSecureGateway = secureGatewayObject => {
  const availableAppliances = {};
  if ('items' in secureGatewayObject) {
    secureGatewayObject.items.forEach(
      item => (availableAppliances[item.name] = item.id),
    );
  }
  return availableAppliances;
};

// expireTime in hours
export function getExpireTimeDate(expireTime) {
  const result = new Date(
    new Date().getTime() + expireTime * 24 * 60 * 60 * 1000,
  );
  return `(${result.toLocaleString(getLocale(), {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })})`;
}

export function getExpireDateTimeFromHours(numHours) {
  // Create a new Date object based on the calculated milliseconds
  const currTime = new Date();
  const result = new Date(currTime.getTime() + numHours * 60 * 60 * 1000);

  // Format date and time using toLocaleDateString and toLocaleTimeString
  const formattedDate = result.toLocaleDateString(getLocale());
  const formattedTime = result.toLocaleTimeString(getLocale(), {
    hour: 'numeric',
    minute: '2-digit',
  });

  return {
    date: formattedDate,
    time: formattedTime,
  };
}

// get all server types
export const fetchServerTypes = servers => {
  const devices = servers[0];
  if (devices instanceof Error) {
    throw devices;
  }
  const accessList = servers[1];

  const directConnectServers = devices.filter(
    device => device.connectionType_ === DIRECT_CONNECTED_SERVERS,
  );
  const secureGatewayConnectServers = devices.filter(
    device => device.connectionType_ === SECURE_GATEWAY,
  );
  const noPermissionServers = devices.filter(
    server => !(server.id in accessList) || !accessList[server.id],
  );
  const allowedServers = devices.filter(
    server => server.id in accessList && accessList[server.id],
  );
  const ovAllowedServers = allowedServers.filter(
    device => device.connectionType_ === OV_MANAGED_SERVERS,
  );
  return {
    directConnect: directConnectServers,
    secureGateway: secureGatewayConnectServers,
    noPermission: noPermissionServers,
    ovServers: ovAllowedServers,
  };
};

export const hasVolume = device =>
  Object.values(device?.storageInventory_ || {}).some(
    storage => storage?.hasVolume,
  );

// Adjust the server table columns based on the current
// subscription state and the subscription state stored
// in the browser local storage when the columns were
// last customized.
//
// If the user hasn't customized the columns, adjust the
// default columns based on the current subscription state.
//
// Note that hasOneViewSubscription is true if the account
// has either an active or expired subscription. The
// hasOneViewSubscription variable can only be false if the
// account doesn't have any active or expired OneView
// subscriptions.  Once the account has a OneView subscription,
// the subscription can't be removed.
//
// Parameters
// view - An object with these properties
//   columns - An array with an ordered list of selected columns
//   hasOneViewSubscription - The value of hasOneViewSubscription
//     when the columns were last customized (stored) or the
//     current value if the columns haven't been customized and
//     they are set to the defaults
// hasOneViewSubscription - Current value of hasOneViewSubscription
const adjustServerTableColumns = (view, hasOneViewSubscription) => {
  const { columns } = view;
  if (hasOneViewSubscription) {
    if (columns.includes('tagsCount')) {
      // Remove tags column
      const tagsIndex = columns.findIndex(col => col === 'tagsCount');
      columns.splice(tagsIndex, 1);
    }
  } else if (columns.includes('oneview/name')) {
    // Remove the OneView name column
    // from the uncustomized, default, columns
    // if there isn't a OneView subscription
    const oneviewNameIndex = columns.findIndex(col => col === 'oneview/name');
    columns.splice(oneviewNameIndex, 1);
  }
};

const getDefaultServerTableColumnsView = hasOneViewSubscription => {
  const defaultView = { ...DEFAULT_SERVER_TABLE_COLUMNS_VIEW };
  defaultView.columns = [...defaultView.columns];
  defaultView.hasOneViewSubscription = hasOneViewSubscription;
  adjustServerTableColumns(defaultView, hasOneViewSubscription);
  return defaultView;
};

const parseServerTableColumnsView = (
  jsonString,
  hasOneViewSubscription,
  advSubscriptionFlag,
) => {
  try {
    const result = JSON.parse(jsonString);
    if (!advSubscriptionFlag && result.columns.includes('subscriptionTier')) {
      result.columns.remove('subscriptionTier');
    }
    return {
      columns: result.columns,
      properties: result.properties,
      hasOneViewSubscription: result.hasOneViewSubscription,
    };
  } catch (error) {
    return getDefaultServerTableColumnsView(hasOneViewSubscription);
  }
};

export const storeServerTableColumnsView = (view, hasOneViewSubscription) => {
  const storedView = { ...view, hasOneViewSubscription, version: '1.0' };
  localStorage.setItem(
    SERVER_TABLE_COLUMNS_STORAGE_KEY,
    JSON.stringify(storedView),
  );
};

export const getServerTableColumnsView = (
  hasOneViewSubscription,
  advSubscriptionFlag,
) => {
  let view;
  const storedView = localStorage.getItem(SERVER_TABLE_COLUMNS_STORAGE_KEY);
  if (storedView) {
    view = parseServerTableColumnsView(
      storedView,
      hasOneViewSubscription,
      advSubscriptionFlag,
    );
    adjustServerTableColumns(view, hasOneViewSubscription);
    storeServerTableColumnsView(view, hasOneViewSubscription);
  } else {
    view = getDefaultServerTableColumnsView(hasOneViewSubscription);
  }
  // Only return the view properties expected by the grommet Data component
  return {
    columns: view.columns,
    properties: view.properties,
  };
};

export const canStringifyTableColumnsView = view => {
  try {
    JSON.stringify(view);
    return true;
  } catch (error) {
    return false;
  }
};
