// Copyright 2025 - Hewlett Packard Enterprise Company
import React from 'react';
import { Box } from 'grommet';

const MessageLoader = () => (
  <Box
    width="small"
    height="xxsmall"
    margin={{ bottom: 'xsmall' }}
    direction="row"
    gap="small"
    pad={{ horizontal: 'small', vertical: 'xsmall' }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-testid="chat-message-loader-svg"
    >
      <circle cx="6" cy="6" r="6" fill="#01A982">
        <animate
          attributeName="cy"
          from="15"
          to="5"
          dur="1.4s"
          begin="0s"
          values="15;5;15;15"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="0.5"
          to="1"
          dur="1.4s"
          begin="0s"
          values="0.2;1;0.2;0.2"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="22" cy="6" r="6" fill="#01A982">
        <animate
          attributeName="cy"
          from="15"
          to="5"
          dur="1.4s"
          begin="-1.1s"
          values="15;5;15;15"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="0.5"
          to="1"
          dur="1.4s"
          begin="-1.1s"
          values="0.5;1;0.5;0.5"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="38" cy="6" r="6" fill="#01A982">
        <animate
          attributeName="cy"
          from="15"
          to="5"
          dur="1.4s"
          begin="-0.9s"
          values="15;5;15;15"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="0.5"
          to="1"
          dur="1.4s"
          begin="-0.9s"
          values="0.5;1;0.5;0.5"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  </Box>
);

export default MessageLoader;
