// Copyright 2022-2025 - Hewlett Packard Enterprise Company
export const CONTENT_IDS = {
  ADD_DEVICES: 'cc_add_devices',
  ANNOUNCEMENTS: 'cc_announcements',
  AUDIT_LOG: 'cc_activity_audit',
  ACTIVATE_DEVICES: 'cc_activate_devices',
  FIRMWARE_BASELINES: 'cc_baselines', // f/w baseline concepts
  FIRMWARE_HOTFIXES: 'cc_hotfixes', // f/w hotfixes overview
  KNOWN_ISSUES: 'cc_issues_limitations',
  RESOLVED_ISSUES: 'com_resolved_issues',
  SERVER_ACTIONS: 'cc_server_actions',
  SERVER_GROUP: 'cc_server_group',
  SET_FIRMWARE_BASELINES: 'cc_set_baselines', // set initial baselines
  TROUBLESHOOTING: 'cc_troubleshooting',
  UPDATE_FIRMWARE_BASELINES: 'cc_fw_baselines', // general f/w update
  UPDATE_GROUP_FIRMWARE_BASELINES: 'com_group_baseline',
  SUBSCRIPTIONS: 'cc_device_subscriptions',
  STATUS_VALUES: 'com_status_values',
  UPDATE_GROUP_BIOS_SETTINGS: 'com_group_BIOS',
  BIOS_SETTINGS: 'com_bios_settings',
  SW_DRIVERS: 'com_sw_drivers',
  UPDATE_GROUP_LOCAL_STORAGE_SETTINGS: 'com_server_group_storage',
  OV_SERVER_TEMPLATES: 'com_ov_appliance_server_template_settings',
  UPDATE_APPLIANCE_FIRMWARE: 'com_appliance_firmware',
  AUTO_ADD_SERVERS: 'com_automate_add',
  SERVER_SETTINGS: 'com_server_settings',
  SERVER_SETTING_BASELINE: 'com_serversetting_baseline',
  SERVER_SETTINGS_ACTIONS: 'com_serversetting_action_ref',
  SETTING_APPLIANCE_SOFTWARE: 'com_setting_appliance_software',
  AUTO_ILO_FW_UPDATE_ACTION: 'com_action_auto_ilo_fw',
  EMAIL_NOTIFICATION_ACTION: 'com_action_email_notification',
  GROUP_SETTINGS: 'com_group_settings',
  GROUP_POLICIES: 'com_group_policies',
  SAVED_FILTERS: 'com_saved_filters',
  SBAC_BASED_FILTERS: 'com_saved_filters',
  OS_IMAGE: 'com_serversetting_osimage',
  RUN_CARBON_REPORT: 'com_carbon_report',
  METRICS_DATA_COLLECTION: 'com_metrics_dc',
  RUN_SH_INVENTORY_REPORT: 'com_server_hardware_inv_report',
  // This is temporarily content id and may need to update
  RUN_COMPONENTS_INVENTORY_REPORT: 'com_server_components_report',
  RUN_REPORTS: 'com_reports',
  RESOURCE_RESTRICTION_POLICY: 'com_resource_restriction',
  ONEVIEW_APPLIANCES: 'ov_oneview_appliances',
  APPLIANCE_SETTINGS_TEMPLATES: 'ov_appliance_settings_templates',
  MONITORING_SERVERS: 'com_monitoring_servers',
  RENEW_OV_SUBSCRIPTION: 'ov_service_subscription_renew',
  CANCEL_FW_UPDATE: 'com_cancel_fwu',
  SERVICE_NOW: 'com_servicenow',
  SERVICE_NOW_CONFIGURE: 'com_servicenow_configure',
  ARUBA_CENTRAL: 'com_aruba_integration',
  ARUBA_CENTRAL_CONFIGURE: 'com_config_aruba',
  DSCC: 'com_dscc',
  DSCC_CONFIGURE: 'com_dscc_configure',
  AUTOMATIC_SUPPORT_CASE_CREATION: 'com_auto_case',
  SERVER_POWER_OFF: 'com_inventory_power_off',
  DOWNGRADE_COMPONENT: 'com_fw_compliance',
  ILO_SETTING_COMPONENT: 'com_iLO_compliance',
  EMAIL_NOTIFICATION_POLICY: 'com_policy_email_notification',
  RUN_SFW_COMPLIANCE_REPORT: 'com_sfw_compliance_report',
  COM4VC_INFORMATION: 'com4vc_information',
  SUSTAINABILITY_REPORTS: 'com_dc_issue',
  COM_APPLY_ILO_SETTINGS: 'com_apply_ilo_settings',
  OV_APPLIANCE_SETTINGS: 'com_setting_ov_appliance_settings',
  COM_SECURITY_SETTINGS: 'com_security_settings',
  APPLIANCE_UPDATE: 'ov_appliance_update',
  EXTERNAL_STORAGE_SETTINGS: 'com_serversetting_ex_storage',
  COPY_APPLIANCE_SERVER_TEMPLATES: 'com_copy_appliance_server_templates',
  APPLIANCE_SETTINGS: 'com_appliance_settings',
  TOKEN_BASED_DIRECT_CONNECTED_SERVER_ONBOARDING: 'com_activate_with_token',
  GENERATE_ACTIVATION_KEY: 'com_activate_with_token',
  COM_ILO_SETTINGS_CONFIG: 'com_group_ilo_settings',
  SERVER_HARDWARE_INVENTORY_ACTION: 'com_collect_hw',
  OS_INSTALLATION_COMPLETION_TIMEOUT: 'com_os_inst_timeout',
  APPLY_OV_APPLIANCE_SETTINGS: 'com_apply_OVA_appliance_settings',
  COM_ASSIGN_OV_LOC: 'com_assign_OV_loc',
  DSCC_INTEGRATION: 'com_data_services_cc_integration',
  SECURE_GATEWAY_ACTIONS: 'com_secure_gateway_actions',
  SECURE_GATEWAY_ABOUT: 'com_sec_gateway_about',
  COM_SG_APP: 'com_sg_app',
  COM_ISUT_CONCEPT: 'com_isut_concept',
  COM_SEC_GATEWAY_OV_APPLIANCES: 'com_sec_gateway_ov_appliances',
  COM_ASSIGN_LOCATIONS: 'com_assign_locations',
  NEW_OV_SUBSCRIPTION: 'ov_service_sub_obtain',
  PIN_BASED_DIRECT_CONNECTED_SERVER_ONBOARDING: 'com_activate_with_pin',
  SUSTAINABILITY_ANALYTICS: 'com_analytics',
  ILO_SSO: 'com_ilo_rc',
  SERVER_UTILIZATION_ANALYTICS: 'com_server_utilization_analytics',
  APPROVAL_POLICIES: 'com_approval_policies',
  SUBSCRIPTION_TIERS: 'com_subscription_tiers',
  VMWARE_VSPHERE_INTEGRATION: '',
  COM_CREDENTIALS_SUPPORTED_SERVERS: 'com_credentials_supported_servers',
  APPROVAL_POLICIES_CREATE: 'com_approval_policies',
  COM_WAIT_FOR_REBOOT: 'com_wait_for_reboot',
};
