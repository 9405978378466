// Copyright 2023-2024 - Hewlett Packard Enterprise Company

import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'grommet';

const TextEmphasis = ({ children, ...rest }) => (
  <Text weight={500} color="text-strong" {...rest}>
    {children}
  </Text>
);

export default TextEmphasis;

TextEmphasis.propTypes = {
  children: PropTypes.node,
};
